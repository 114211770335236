import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MarketsService } from '../../shared/services/markets.service';
import { DialogService } from '../../shared/services/dialog.service';
import { CampaignService } from '../../shared/services/campaign.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Station, Daypart, Target, Market } from '../../shared/models/campaign.model';
import { SelectionModel } from '@angular/cdk/collections';
import { HelperService } from '../../shared/services/helper.service';
import { MarketMetrics } from '../../shared/models/market-information.model';
import { Router, ActivatedRoute  } from '@angular/router';
import { CSVExporter } from 'src/app/shared/classes/file-exporter';
import 'rxjs/add/operator/filter';
import { CampaignSchedule } from 'src/app/shared/classes/campaign-schedule';
import { CampaignDirty } from 'src/app/shared/classes/radio-campaign';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface StationColumn {
  columnDef: string;
  type: string;
  format?: string;
  header: string;
  css: string;
  cell(x: any): any
}

@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']
})
export class StationsComponent implements OnInit {

  @Output() onValidStateChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  columns: StationColumn[] = [
    { columnDef: 'select', header: '', type: 'select', css: 'column-select', cell: (row: any) => `${row.id}` },
    { columnDef: 'name', header: 'Station', type: 'string', css: '', cell: (row: any) => `${row.name}` },
    { columnDef: 'band', header: 'Band', type: 'string', css: '', cell: (row: any) => `${row.band}` },
    { columnDef: 'format', header: 'Format', type: 'string', css: '', cell: (row: any) => `${row.format}` },
    { columnDef: 'network', header: 'Network', type: 'string', css: '', cell: (row: any) => `${row.network}` },
    { columnDef: 'menu', header: '', type: 'string', css: 'column-options', format: '', cell: (row: any) => `` },
  ];

  displayedColumns: string[] = this.columns.map( c=> c.columnDef);

  dataSource: MatTableDataSource<any>;
  localStationData: any[];
  localStationFilteredData: any[]; // used to store the filtered list. 
  initialSortColumn: string;

  // market tabs
  currentMarket: number;
  marketTabs: string[];
  nStationApplyAll: boolean;

  selection: SelectionModel<Station>;

  networkFilterList: string[] = []
  networkFilterSelection: string[] = []

  formatFilterList: string[] = []
  formatFilterSelection: string[] = []

  bandFilterList: string[] = []
  bandFilterSelection: string[] = []

  daypartList: any[] = []
  daypartSelected: number;

  targetList: any[] = []
  targetSelected: number;

  nStationsSelected: number;
  nStations: any[];
  campaignSummary: string[];

  selectedSchedule: CampaignSchedule = null;

  constructor (private marketsService: MarketsService,
              private dialogService: DialogService,
              private campaignService: CampaignService,
              private router: Router,
              private route: ActivatedRoute,
              private helperService: HelperService ) { }

  ngOnInit() {

    // no current markets
    if ( !this.marketsService.getFirst() ) {
       this.router.navigate(['dashboard']);
       return;
    }

    // no targets yet (need a better way to handle this)
    if ( !this.campaignService.run.targets.length ) {
      this.router.navigate(['targets']);
      return;
    }

    // first time defaults
    this.nStationApplyAll = true; // default is to apply top n stations to all markets

    // market tabs init
    this.marketTabs = this.campaignService.run.markets.map( market=> market.marketName);
    this.currentMarket = 0;

    this.loadData();
  }

  loadData() {

    const market = this.getCurrentMarket();
   
    // check for a schedule to apply station selection to
    this.selectedSchedule = this.campaignService.run.schedules[this.campaignService.run.currentSchedule];
    this.campaignSummary = this.campaignService.run.getCampaignSummary( market, null, this.getCurrentSchedule() );
    
    this.networkFilterList = []
    this.formatFilterList = []
    this.bandFilterList = []
    this.networkFilterSelection = []
    this.formatFilterSelection = []
    this.bandFilterSelection = []

    // planning stations subset list
    this.localStationData = Object.assign([], market.planningStations);
    this.localStationData.forEach( stn => {

      let formats = stn.formats.map( element => element.description);
      stn.format = formats.join(',');

      let networks = stn.networks.map( element => element.description);
      stn.network = networks.join(',');

      // set up the network filters chips, removing duplicates
      networks.forEach( net => { if (!this.networkFilterList.includes(net)) this.networkFilterList.push(net) } );
      formats.forEach( fmt => { if (!this.formatFilterList.includes(fmt)) this.formatFilterList.push(fmt) } );

      if (!this.bandFilterList.includes( stn.band)) this.bandFilterList.push(stn.band);
    });

    this.networkFilterList.sort();
    this.formatFilterList.sort();
    this.bandFilterList.sort();

    this.buildDropdowns();

    // fetch the station metrex based on current daypart and target
    this.loadStationSpecificData(market, this.getCurrentDaypart(), this.getCurrentTarget());

    // set up table data
    this.localStationFilteredData = Object.assign([], this.localStationData);
    this.initialSortColumn = "avgRating";
    this.dataSource = new MatTableDataSource<any>(this.localStationData);
    this.dataSource.sort = this.sort;

    // let scheduleStations = null;
    // if (this.getCurrentSchedule()){
    //   const scheduleMarket = this.getCurrentSchedule().market(this.getCurrentMarket().marketFilename);
    //   scheduleStations = scheduleMarket.stations;
    // }

    const scheduleMarket = this.getCurrentSchedule().market(this.getCurrentMarket().marketFilename);

    // get selected either from the schedule or the main campaign list
    this.selection = new SelectionModel<Station>(true, scheduleMarket.stations);
    //this.selection = new SelectionModel<Station>(true, scheduleStations || market.stations);
    // build N station selector
    this.nStations = new Array(21).fill(0).map( (x, i) => {return { value: i, text: `Select ${i} `  }});
    this.nStationsSelected =  this.selection.selected.length; // if all is well, this should equal N stations from user prefs
  }

  buildDropdowns(){
    // selectable dayparts
    this.daypartList = this.campaignService.run.dayparts.map( dp => { return {id: dp.id, description: dp.description} } );
    this.daypartSelected = this.daypartList.length ? this.daypartList[0].id : null;

    // selectable targets
    this.targetList = this.campaignService.run.targets.map( (tgt, index) => { return {id: index, description: tgt.name} } );
    this.targetSelected = this.targetList.length ? this.targetList[0].id : null;

    //selectable schedules
//    this.scheduleList = this.campaignService.run.schedules.map( (sch, index) => { return {id: index, description: sch.name} } );
//    this.scheduleSelected = this.scheduleList.length ? this.scheduleList[0].id : null;
  }

  // market tab change
  onCurrentMarketChange( index: number ) {
    this.currentMarket = index;
    this.loadData();
  }

  getCurrentMarket(): Market {
    return this.campaignService.run.markets[this.currentMarket];
  }

  getCurrentSchedule(){
    return this.selectedSchedule;
  }
  // apply to all markets checkbox for nStations
  onNStationApplyAll( e: MatCheckboxChange ){
    this.nStationApplyAll = e.checked;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Station): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }  

  loadStationSpecificData(market: Market, daypart: Daypart, target: Target ) {

    // Avg Aud = qtr rating * universe
    // Cum Aud = cum rating * universe
    // share = (stn aud / total stn aud) * 100
    // turnover = cume / audience
    // time spent (expressed in h:mm) =  duration (mins) * avg rating / cum rating
    
    const totStn = market.totalStation;
    let results = this.marketsService.getStationsData(market.marketFilename, this.localStationData, [1,2], 1, daypart, target); // array of demoIds per target, but not yet
    let totResults = this.marketsService.getStationsData(market.marketFilename, [totStn], [1], 1, daypart, target);

    const wholeFmt = this.campaignService.run.prefs.user.UI.wholeFormat;
    const ratingsFmt = this.campaignService.run.prefs.user.UI.ratingsFormat;
  
    // build all the value columns required
    const columns = [
      { name: 'Avg Rtg', columnDef: 'avgRating', type: 'value', format: ratingsFmt },
      { name: 'Cum Rtg', columnDef: 'cumRating', type: 'value', format: ratingsFmt },
      { name: 'Avg Aud [00]', columnDef: 'avgAudience', type: 'value', format: wholeFmt },
      { name: 'Cum Aud [00]', columnDef: 'cumAudience', type: 'value', format: wholeFmt },
      { name: 'Share', columnDef: 'share', type: 'value', format: ratingsFmt },
      { name: 'Turn Over', columnDef: 'turnOver', type: 'value', format: ratingsFmt },
      { name: 'Time Spent', columnDef: 'timeSpent', type: 'string' }
    ]
    this.addColumns(columns);

    // for each station calculate the required value and add to station array
    this.localStationData.forEach( stn => {

      stn.avgRating = target.universe ? (results[stn.id]['measure1'] / target.universe) * 100 : 0;
      stn.cumRating = target.universe ? (results[stn.id]['measure2'] / target.universe) * 100 : 0;
      stn.avgAudience = results[stn.id]['measure1'];
      stn.cumAudience = results[stn.id]['measure2'];
      stn.share = totResults[totStn.id]['measure1'] ? (stn.avgAudience / totResults[totStn.id]['measure1']) * 100 : 0;
      stn.turnOver = stn.avgAudience ? stn.cumAudience / stn.avgAudience : 0;

      // calculate timespent
      const duration = stn.cumRating ? (daypart.numQhrs * 15) * (stn.avgRating / stn.cumRating) : 0;
      const hours = Math.floor(duration / 60);
      const minutes = Math.trunc(duration % 60);
      stn.timeSpent = `${hours}:${minutes}`;
    });
  }

  // station checkbox on/off
  toggle(row){
    this.selection.toggle(row);
    this.saveSelection(this.getCurrentMarket(), this.selection.selected);
  }

  // not yet wired up
  //onSelect(e: any) {
  //  this.saveSelection(this.getCurrentMarket());
  //}

  // n stations change
  onNStationsChange() {

    //need to iterate through all the markets' stations and create a station list in the current schedule

    // work with one or all markets
    const markets: Market[] = this.nStationApplyAll ? this.campaignService.run.markets : [this.getCurrentMarket()];

    markets.forEach( market => {

      const nStationList = this.getTopNStations(this.nStationsSelected, this.getCurrentDaypart(),
                           this.getCurrentTarget(), this.getCurrentSchedule(), market);

      this.saveSelection(market, nStationList);
    })

    // re-select the current view with the new n stations
    this.selection.clear();
    let sortedData = this.dataSource.sortData(this.localStationFilteredData, this.dataSource.sort);

    for (let n = 0 ; n < Math.min(this.nStationsSelected, sortedData.length); n++) {
      this.selection.select(sortedData[n]);
    }
  }

  getTopNStations(nSelect: number, daypart: Daypart, target: Target, schedule: CampaignSchedule, market: Market): Station[] {

    let stns = [];
    let returnStations: Station[] = [];
    const scheduleMarket = schedule.market(market); // those selected for this run (e.g top n) I think
    const campaignMarket = this.campaignService.run.market(market); // all stations for this market
      
    // get the avg rating for each station.
    let results = this.marketsService.getStationsData(market.marketFilename, campaignMarket.planningStations, [1,2], 1, daypart, target); // array of demoIds per target, but not yet

    // create an array of all the avg ratings
    campaignMarket.planningStations.forEach( stn => {
      stns.push({
        id: stn.id,
        avgRating: target.universe ? (results[stn.id]['measure1'] / target.universe) * 100 : 0,
      })
    });

    // sort into descending order
    stns.sort( (x,y) => y.avgRating - x.avgRating)

    // copy the top n to the schedule stations list
    returnStations = [];
    for(let i=0; i < nSelect; i++){
      returnStations.push( campaignMarket.planningStations.find( stn=> stn.id == stns[i].id) );
    }

    return returnStations;
  }

  addColumn(name: string, columnDef: string, type: string = 'value', format?: string){

    this.columns = this.columns.filter( col => col.columnDef != columnDef); // remove if already exists
    this.columns.push( { columnDef: columnDef, header: name, type: type, css: 'column-value', format: format, cell: (row: any) => row[columnDef] } );

    // menu to the end
    const index = this.columns.findIndex( col => col.columnDef=='menu');
    if (index !== -1) this.columns.push( this.columns.splice(index, 1)[0] );

    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  addColumns( columns: any[] ){
    columns.forEach( col => {
      this.addColumn(col.name, col.columnDef, col.type, col.format);
    });
  }

  onClick(){
    this.columns[this.columns.length-1].header = ""+Date.now();
  }

  onBandFilterChange( selection: string[]) {
    this.bandFilterSelection = selection;
    this.applyFilters();
  }  

  onNetworkFilterChange( selection: string[] ) {
    this.networkFilterSelection = selection;
    this.applyFilters();
  }

  onFormatFilterChange( selection: string[] ) {
    this.formatFilterSelection = selection;
    this.applyFilters();
  }

  // daypart selected for showing qtr hours
  onDaypartChange() {
    this.loadStationSpecificData(this.getCurrentMarket(), this.getCurrentDaypart(), this.getCurrentTarget());
  }

  onTargetChange() {

    this.loadStationSpecificData(this.getCurrentMarket(), this.getCurrentDaypart(), this.getCurrentTarget());
    this.campaignSummary = this.campaignService.run.getCampaignSummary(this.getCurrentMarket(), this.getCurrentTarget());

    // reapply sort after data changed
    const sortState: Sort = {active: this.sort.active, direction: this.sort.direction};
    this.sort.sortChange.emit(sortState);
  }

  // preferred daypart selection.  Results written to campaignService.run.dayparts
  onPreferredDaypartSelection() {
    this.campaignService.selectPreferredDayparts().subscribe( ans => {
      if (ans) {
        this.buildDropdowns();
        this.onDaypartChange();
      }
    })
  }

  // preferred station selection
  onPreferredStationSelection() {
    this.campaignService.selectPreferredStations().subscribe( ans => {
      if (ans) {
        this.loadData();
      }
    })
  }  

  onExportTable() {

    let mkt = this.marketsService.getFirst();
    const target = this.getCurrentTarget();
    const daypart = this.getCurrentDaypart();

     let csv = new CSVExporter();
     csv.addLine( "Telmar Audio Rank Report" );
     csv.addLine( ["Report Name", mkt.market.marketName] );

     csv.addLine( ["Market", `${mkt.market.marketName} (${mkt.market.reportType} ${mkt.market.geography}, ${mkt.market.periodNameLong})`,
                   "Market Population", this.helperService.formatFloat(mkt.market.universe)] );

     csv.addLine( ["Target Audience", target.name,
                   "Target Population", this.helperService.formatFloat(target.universe)] );

     csv.addLine( ["Sweep", `${mkt.market.reportType}, ${mkt.market.geography}, ${mkt.market.periodNameLong}`, "Daypart Selection", daypart.description ] );
     csv.addBlankLine(2);

     csv.addLine( "Station Ranker" );

     let sortedData = this.dataSource.sortData(this.localStationFilteredData, this.dataSource.sort);
     csv.addTable(this.columns, sortedData);

     csv.addBlankLine();
     csv.addLine( "(c) Telmar 2020" );
     csv.addLine( `${mkt.market.copyright}, ${mkt.market.marketName}, ${mkt.market.periodNameLong}` );
     
     csv.saveAs("Station Rank.csv");
  }

  // called when station selection has been changed. Save selection back to schedule
  saveSelection(market: Market, stations: Station[]) {

    const curr = this.getCurrentSchedule();

    if ( curr ) { 

      const scheduleMarket = curr.market(market);
      scheduleMarket.stations = stations;
      scheduleMarket.planningStations = []; //empty planningStations so the array is reinitilised on the planning screen
      this.campaignService.run.addDirty( CampaignDirty.stations );
    }
    else market.stations = stations;

    this.campaignSummary = this.campaignService.run.getCampaignSummary( market, null, curr );
    this.onValidStateChanged.emit(stations); // back up to parent tabs
  }

  applyFilters() {
    const noFilter = !this.networkFilterSelection.length && !this.formatFilterSelection.length && !this.bandFilterSelection.length;
    if ( noFilter ) { 
      this.localStationFilteredData = Object.assign([], this.localStationData);
      this.dataSource.data = this.localStationFilteredData;
      return;
    }

    // a big filter to include all options
    this.localStationFilteredData = this.localStationData.filter( stn => {

      let netGood: boolean = true;
      let fmtGood: boolean = true;
      let bandGood: boolean = this.bandFilterSelection.length == 0 || this.bandFilterSelection.includes(stn.band);

      // return true if any network filter is matched (OR match)
      if (this.networkFilterSelection.length) {
        let nets = stn.network.split(',');
        netGood = !!this.networkFilterSelection.find( n=> nets.includes(n) );
      }

      // return true if any format filter is matched (OR match)
      if (this.formatFilterSelection.length) {

        let fmts = stn.format.split(',');
        fmtGood = !!this.formatFilterSelection.find( n=> fmts.includes(n) );
      }

      return netGood && fmtGood && bandGood;
    });

    this.dataSource.data = this.localStationFilteredData;
  }

  getCurrentTarget(): Target {
    return this.campaignService.run.targets[this.targetSelected];
  }

  getCurrentDaypart(): Daypart {
    const mkt = this.marketsService.getFirst();
    return mkt.allPlanningDayparts.find( dp => dp.id == this.daypartSelected );
  }




}
