import { Component, OnInit } from '@angular/core';

import { TupAuthService } from '@telmar-global/tup-auth';
import { TupUserMessageService } from '@telmar-global/tup-user-message';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  oldPassword: string;
  newPassword: string;
  errorMessage: string;

  constructor(
    private authService: TupAuthService,
    private userMessage: TupUserMessageService,
  ) { }

  ngOnInit(): void {
  }

  onChangePassword(): void {

    this.errorMessage = '';

    if( ! (this.newPassword.match(/(?=.{6,25})(?=.*[1-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[(!@#$%^&*()_+|~\- =\`{}[\]:”;'<>?,.\/, )])(?!.*(.)\1{2,}).+/) && this.newPassword.length >= 6 ) ){
      this.errorMessage = "Password must contain at least 6 characters including 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character";
      this.userMessage.openDialog( this.errorMessage, 'Invalid Password Pattern', { confirmOnly: true} );
      return
    }

    var progressDialog = this.userMessage.showProgressModal('Changing password', '');

    this.authService.changePassword( this.oldPassword, this.newPassword )
    .then( _ => {
      this.userMessage.openDialog('Success!', 'Password change', { confirmOnly: true });
    })
    .catch( err => {
      console.error(err)
      const errorTitle = "Error while trying to change password";
      if( err.code ) {
        if( err.code == "InvalidParameterException" ) {
          this.userMessage.openDialog(err.message, errorTitle, { confirmOnly: true });
        } else if( err.code == "NotAuthorizedException" ) {
          this.userMessage.openDialog("Incorrect current password", errorTitle, { confirmOnly: true });
        }
      } else {
        this.userMessage.openDialog(err, errorTitle, { confirmOnly: true });
      }
    })
    .finally( () => {
      this.oldPassword = null;
      this.newPassword = null;
      progressDialog.close();
    })
  }

}
