import {
  Component,
  OnInit,
  Inject,
  SystemJsNgModuleLoader,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SummaryValues } from "../../campaign-summary/campaign-summary.component";
import { HelperService } from "src/app/shared/services/helper.service";
import { UserPreferences } from "src/app/shared/classes/user-preferences";
import { DistributionSet } from "../freq-dist-dialog/freq-dist-dialog.component";

export class NTilesDialogModel {
  constructor(
    public title: string,
    public startIndex: number,
    public distributionSet: DistributionSet[],
    public nTiles: any[]
  ) {}
}

@Component({
  selector: "n-tiles-dialog",
  templateUrl: "./n-tiles-dialog.component.html",
  styleUrls: ["./n-tiles-dialog.component.scss"],
})
export class NTilesDialogComponent implements OnInit {
  title: string = "N-Tiles";
  summary: SummaryValues;
  nTiles: any[];
  currentNTiles: any;
  currentMarket: number = 0;
  currentNTileIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<NTilesDialogComponent>,
    private helperService: HelperService,
    private userPrefs: UserPreferences,
    @Inject(MAT_DIALOG_DATA) public data: NTilesDialogModel
  ) {}

  ngOnInit(): void {
    this.currentMarket = this.data.startIndex;
    this.currentNTileIndex = this.data.startIndex;
    // Get nTiles for each single market
    this.nTiles = this.data.nTiles;
    // Load data
    this.loadData();
  }

  onClose() {
    this.dialogRef.close();
  }

  // UI onscreen
  getTitle(): string {
    return this.data.title;
  }

  getCurrentMarket(): string {
    return this.data.distributionSet[this.currentMarket].title;
  }

  onSelectCurrentMarket(direction: number) {
    const x = this.currentMarket + direction;
    this.currentMarket =
      x < 0
        ? this.data.distributionSet.length - 1
        : x > this.data.distributionSet.length - 1
        ? 0
        : x;
    const y = this.currentNTileIndex + direction;
    this.currentNTileIndex =
      y < 0 ? this.nTiles.length - 1 : y > this.nTiles.length - 1 ? 0 : y;

    this.loadData();
  }

  loadData() {
    const decimal = this.userPrefs.user.UI.floatDecimals;
    const data = this.data.distributionSet[this.currentMarket];

    // summary panel
    this.summary = {
      sideList: [],
      title: "",
      featured: [
        {
          title: "GRPs",
          value: this.helperService.formatFloat(data.GRPs, decimal),
        },
        {
          title: "Total Reach %",
          value: this.helperService.formatFloat(data.reach, decimal),
        },
        {
          title: "Population [00]",
          value: this.helperService.formatFloat(data.universe),
        },
      ],
    };

    this.currentNTiles = this.nTiles[this.currentNTileIndex].Tiles;
    console.log(
      "current displayed market ",
      this.currentNTileIndex,
      this.currentNTiles
    );
  }
}
