<client-area areatitle="Documentation" subtitle="Documentation and help resources" icon="school">

<tab-navigation header current="documentation"></tab-navigation>

<div class="content glossary-parent" fxFlex fxLayout="column" fxLayoutAlign="start center">
    <div class="inner-wrapper termsearch" fxLayout="column" fxFlex>
        <div class="mat-typography summary-top-section">
            <span fxFlex fxLayoutAlign="center">&nbsp;</span>
            <!-- <a class="download"
                href="https://drive.google.com/file/d/1UpFG4OsDTqN5re8vMShWdMWEdPkveXY_/view?usp=sharing"
                target="_blank">
                <button mat-menu-item>
                    <mat-icon>description</mat-icon>
                    <span>Download Quick Guide</span>
                </button>
            </a> -->
        </div>
        <div class="tab-header-grey white" fxFlex fxLayout="column">
            <mat-tab-group fxFlex class="content-tab">
                <mat-tab label="Term Glossary" class="glossary">
                    <div class="mat-card-parent">
                        <mat-form-field style="width: 100%">
                            <input matInput [(ngModel)]="searchText" placeholder="Search Terms">
                        </mat-form-field>
                        <ul>
                            <li class="results" *ngFor="let t of termsJSON | filterTerms : searchText">
                                <h2>{{t['Data Label']}}</h2>
                                <p><span class="description">Description</span>: {{t['Definitions']}}</p>
                            </li>
                        </ul>
                    </div>
                </mat-tab>
                <mat-tab label="Quick Guide" class="quick-guide">
                    <div class="mat-card-parent">
                        <iframe class="iframe-manual"
                            src="https://docs.google.com/document/d/1lYkaXCbA1s0vh4HCRZ3XrYsZW8MnA0iBPePcjPmL3wY/preview?embedded=true">
                        </iframe> 
                    </div>
            </mat-tab>
                <mat-tab label="Videos" class="videos">
                    <div class="mat-card-parent">
                        <iframe class="iframe-video"
                            src="https://drive.google.com/file/d/1x5UykMALtklDuPcfWfJfAqZ3cwZ8xX1k/preview?embedded=true"
                            frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true"
                            webkitallowfullscreen="true">
                        </iframe>
                    </div>
            </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

</client-area>