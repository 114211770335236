import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CampaignService } from '../../shared/services/campaign.service';
import { MarketsService } from '../../shared/services/markets.service';
import { TargetGridComponent } from './target-grid/target-grid.component';
import { Target } from '../../shared/models/campaign.model';
import { DialogService } from '../../shared/services/dialog.service';
import { Router } from '@angular/router';
import { UserTargetDocument } from 'src/app/shared/models/usertarget-document.model';
import { Observable } from 'rxjs';
import { UserTargetService } from 'src/app/shared/services/user-target.service';
import { TupAnalyticsService, EventCategory } from '@telmar-global/tup-analytics';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss']
})
export class TargetsComponent implements OnInit {

  @Output() onValidStateChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('targetGrid', { static: true} ) targetGrid: TargetGridComponent;

  processing: boolean = false;
  targets: Target[];  // all targets from user an campaign
  selectedTargets: Target[]; // selected targets

  campaignSummary: string[]; //breadcrumbs

  constructor( private campaignService: CampaignService, 
               private marketsService: MarketsService,
               private router: Router,
               private dialogService: DialogService,
               private as: TupAnalyticsService,
               private userTargetService: UserTargetService ) { }

  ngOnInit() {

    // no markets have been selected yet
    if (!this.campaignService.run.markets || !this.campaignService.run.markets.length) this.router.navigate(['dashboard'])
    else {

      this.loadData();
    }
  }

  // called from ngOnInit and waits for data to be ready before notifying components
  loadData() {

    this.campaignSummary = this.campaignService.run.getCampaignSummary( this.campaignService.run.markets[0] );

    this.loadUserTargets().subscribe( targets => {

      this.targets = Object.assign([], targets); // user targets and camp tgts merged (eventually)
      this.selectedTargets = Object.assign([], this.campaignService.run.targets); // camp targets start selected

      // add from camp targets if not already in the user target list
      this.campaignService.run.targets.forEach( tgt => {
        if ( !this.targets.find( t=> t.coding == tgt.coding )) this.targets.push( tgt );
      })

      this.targetGrid.loadData(this.targets, this.selectedTargets);

      this.processing = false;
      if (this.targets.length == 0) this.onCreateTarget(); // open the dialog automatically        
    })
  }

  onCreateTarget(){

    const filenames = this.campaignService.run.markets.map( mkts=> mkts.marketFilename); 
    this.dialogService.targetCreationDialog( filenames ).afterClosed().subscribe( result => {
      if (result) {

        this.as.e(EventCategory.Navigation,"new_target", result.target.name);
        this.saveTarget(result.target);
      }
    })
  }

  // save button clicked.  Get universe and sample from the unSavedTarget definitions
  saveTarget(unSavedTarget: Target) {

    let exists = this.targets.find( tgt => tgt.coding === unSavedTarget.coding);
    if (exists) {
      this.dialogService.message("This target has already been added", "Targets");
      return;
    }

    // prepare unSavedTarget
    let mkt = this.marketsService.getFirst();
    let res = this.marketsService.getTargetUniverse(mkt.market.marketFilename, unSavedTarget);
    unSavedTarget.universe = res.universe;
    unSavedTarget.sample = res.sample;
    unSavedTarget.demographicIds = res.demographicIds;

    // save to elastic then configure campaign and screen
    this.saveUserTarget( unSavedTarget ).subscribe( success => {

      if (success) {
        this.addTarget( unSavedTarget );
        this.targets.push( unSavedTarget ); // add to our list
   
        this.onValidStateChanged.emit(this.targets);
        this.targetGrid.loadData(this.targets, this.selectedTargets);
      }
        
    })
  }

  onTargetRename(target: Target) {
    let tgt = this.targets.find(t=> t.coding == target.coding);

    tgt.name = target.name;
    this.userTargetService.createOrUpdateUserTarget( this.campaignService.toUserTargetDocument(target) ).subscribe();  // resave when renamed
    this.campaignService.run.renameTarget(target);

    this.onValidStateChanged.emit(this.targets);

  }

  onTargetDelete(target: Target) {

    this.dialogService.question(`Remove '${target.name}' from your list?`, 'Remove').afterClosed().subscribe( ans => {

      if (ans.data == 'OK') {

        if (target.id) this.userTargetService.deleteUserTarget( target.id ).subscribe();  // delete from user target service

        // remove from seletced and campaign
        this.removeTarget(target);

        // remove form target array
        const idx = this.targets.findIndex( tgt=> tgt.coding == target.coding);
        if (idx !== -1) this.targets.splice(idx, 1);  // delete from our array
    
        this.targetGrid.loadData(this.targets, this.selectedTargets);  //redraw targets grid
        this.onValidStateChanged.emit(this.targets);
      }

    })
  }

  // check box select/unselect
  onTargetSelect( sel: Target[] ): void {

    if (!sel) return;

    let addTargets: Target[] = []
    let removeTargets: Target[] = []

    sel.forEach( selTgt => {
       if (! this.selectedTargets.find( tgt=> tgt.coding == selTgt.coding)) addTargets.push( selTgt )
    })

    this.selectedTargets.forEach( tgt => {
      if (! sel.find( selTgt => selTgt.coding == tgt.coding)) removeTargets.push( tgt )
    })

    // consolidate 
    addTargets.forEach( target => { this.addTarget(target) } )
    removeTargets.forEach( target => { this.removeTarget(target) } )
  }

  // add to both selected target array and campaign target array
  addTarget (target: Target) {

    if ( !this.selectedTargets.find( tgt=> tgt.coding == target.coding) ) this.selectedTargets.push(target); 
    this.campaignService.run.addTarget(target) 

    // target is added to the campaign object.  perhaps here it should have all the universes added for all the current markets

    // Now there is a target, configure N stations, etc
    if ( this.selectedTargets.length ) {
      this.campaignService.configureAfterTargetSelect(this.marketsService.markets, this.selectedTargets[0]);
      this.campaignService.buildTargetUniverses();
    }
  }

  // remove from both own select target array and campaign array
  removeTarget(target: Target) {

    const idx = this.selectedTargets.findIndex( tgt=> tgt.coding == target.coding);
    if (idx !== -1) {
      this.selectedTargets.splice(idx, 1);  // delete from our array
    }

    this.campaignService.run.deleteTarget(target);  // delete from campaign service array and schedules
  }

  // fetch user targets from elastic and make real Target[] from them
  loadUserTargets(): Observable< Target[] > {

    return new Observable( observable => {
      this.processing = true;
      this.userTargetService.getUserTargets().subscribe( data => {

        let targets: Target[] = []
        let mkt = this.marketsService.getFirst();

        // make real targets from the DocumentTarget
        data.userTargets.forEach( docTarget => {
          targets.push( this.campaignService.toTarget(docTarget.target, mkt, docTarget.id) );
        })

        this.processing = false;
        observable.next(targets);
        observable.complete();
      })
    })

  }

  saveUserTarget( target: Target ): Observable<boolean> {

    return new Observable( observable => {

      let userTarget: UserTargetDocument = this.campaignService.toUserTargetDocument( target );
      this.userTargetService.createOrUpdateUserTarget( userTarget ).subscribe( data => {

        target.id = data.id; // insert the doc id
        observable.next( data.success );
        observable.complete();
      })
    })
  }

}
