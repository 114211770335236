import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignSchedule } from 'src/app/shared/classes/campaign-schedule';
import { Market, MultiResultLine, ResultLine, Target } from 'src/app/shared/models/campaign.model';
import { CampaignService } from 'src/app/shared/services/campaign.service';


@Component({
  selector: 'totals-multi-market-grid',
  templateUrl: './totals-multi-market.component.html',
  styleUrls: ['./totals-multi-market.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class TotalsMultiMarketComponent implements OnInit {

  private floatFmt = this.campaignService.run.prefs.user.UI.floatFormat;
  private wholeFmt = this.campaignService.run.prefs.user.UI.wholeFormat;
  private costFmt = this.campaignService.run.prefs.user.UI.costFormat;

  marketTotals: MultiResultLine[];
  dataSource: MatTableDataSource<MultiResultLine>;
  columns = [ 
    { field: 'title', format: '', type: 'string', header: 'Market/Station'},
    { field: 'spots', format: this.wholeFmt, type: 'value', header: 'Spots'},
    { field: 'grps', format: this.floatFmt, type: 'value', header: 'GRPs'},
    { field: 'reachPct', format: this.floatFmt, type: 'value', header: 'Reach %'},
    { field: 'avgFreq', format: this.floatFmt, type: 'value', header: 'Frequency'},
    { field: 'reach00', format: this.wholeFmt, type: 'value', header: 'Net Reach [00]'},
    { field: 'impacts', format: this.wholeFmt, type: 'value', header: 'Impressions [00]'},
    { field: 'effReachPct', format: this.floatFmt, type: 'value', header: 'Eff Rch %'},
    { field: 'cps', format: this.costFmt, type: 'value', header: 'CPS'},
    { field: 'cpp', format: this.costFmt, type: 'value', header: 'CPP'},
    { field: 'cpm', format: this.costFmt, type: 'value', header: 'CPM'},
    { field: 'totalCost', format: this.costFmt, type: 'value', header: 'Cost $'},
    { field: 'costRchPnt', format: this.costFmt, type: 'value', header: 'Cosr Per Rch Pnt'},
  ]

  displayedColumns = this.columns.map( col=> col.field )

  expandedRow: MultiResultLine | null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  constructor( private campaignService: CampaignService ) {  }

  ngOnInit(): void {
  }

  loadData(target: Target, schedule: CampaignSchedule) {
    if (!schedule) return;

    this.marketTotals = schedule.getCombinedMarketTotal( target );

    // fill in the long titles for the markets
    this.marketTotals.forEach( marketTotal => {
      let market = this.campaignService.run.market(marketTotal.title); // title holds the marketFilname

      if (market) {
        marketTotal.longTitle = `${market.marketName} (${market.reportType}, ${market.geography}, ${market.periodNameLong})`;
        marketTotal.title = market.marketName;
      }

    })

    // build datasource and configure
    if (!this.dataSource) this.dataSource = new MatTableDataSource<MultiResultLine>([]);
    this.dataSource.data = this.marketTotals;

    this.expandedRow = this.marketTotals.length ==2 ? this.marketTotals[1] : null;
    this.dataSource.sort = this.sort;
  }

}
