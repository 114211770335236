<div fxFlex fxLayout="column" style="overflow-y: auto;">
    <h2 *ngIf="values.title" class="main-title">{{values.title}}</h2>
    <div fxLayout="row wrap" style="width: 100%;" fxLayoutAlign="{{ values.sideList?.length ? 'space-between' : 'space-around' }} center">

        <div class="side-list" *ngIf="values.sideList?.length">
            <div *ngFor = "let item of values.sideList" class="side-pair">
                <h3 class="side-title" >{{item.title}}</h3>
                <p class="side-value" *ngIf="item.value !== null">{{item.value}}</p>
            </div>
        </div>
    
        <number-widget *ngFor="let item of values.featured"  [title]="item.title" [value]="item.value"></number-widget>
    </div>
</div>

