<div style="display:flex; flex-direction: row; justify-content: center;">

  <mat-card style="height: 90px; width: 90px; margin: 10px; text-align: center">
    <mat-card-content >
      <button (click)="onNewDocument()" class="new-document" mat-fab color="primary"><mat-icon>add_circle_outline</mat-icon></button>
      <p>Start a NEW Campaign</p>
    </mat-card-content>
  </mat-card>
  
</div>

<mat-progress-bar value="0" [mode]="processing? 'indeterminate' : 'determinate'"></mat-progress-bar>

 
<!--Filter textbox-->
<!-- <button mat-icon-button (click)="onRefresh()" ><mat-icon color="primary">loop</mat-icon></button> -->
<mat-form-field style="width: 100%">
  <mat-label>Filter</mat-label>
  <button mat-icon-button matPrefix (click)="onResetFilter()" matTooltip="Reset"><mat-icon>remove_circle_outline</mat-icon></button>
  <input type="text" [(ngModel)]="filterText" matInput (keyup)="onApplyFilter($event)" placeholder="">
</mat-form-field>    

<mat-spinner class="centered" *ngIf="processingLoadDoc"></mat-spinner>

<!--document Table-->
  <div>
    <mat-table [dataSource]="dataSource" matSort dense matSortActive="modified" matSortDirection="desc" matSortDisableClear>
  
  <!-- Generic column definition -->
  <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
    <mat-header-cell [ngClass]="column.css" mat-sort-header disableClear *cdkHeaderCellDef>{{ column.header }} </mat-header-cell>

    <!-- Actions Column -->
    <div *ngIf="column.columnType=='actions'">
        <mat-cell [ngClass]="column.css" *matCellDef="let row">
       <!--     <button mat-icon-button (click)="onOpenDocument(row)" ><mat-icon color="primary">play_circle_outline</mat-icon></button> -->
       <!--     <button mat-icon-button (click)="onDocumentInfo(row)" ><mat-icon color="primary">info</mat-icon></button> -->
       <button mat-icon-button (click)="onDeleteDocument(row)" ><mat-icon style="color:red;">remove_circle_outline</mat-icon></button>
        </mat-cell>
      </div>
      
    <!-- Editable Column -->
    <div *ngIf="column.columnType=='editable'">
      <mat-cell [ngClass]="column.css" *matCellDef="let row; let i = index">
          <input matInput
            id="edit_{{i}}"
            (click)="$event.target.select()"
            value="{{column.cell(row)}}"
            (blur)="onSaveTitle(column.columnDef, $event.target.value, row)">
          <button mat-icon-button (click)= "onEditTitle(i)"><mat-icon matPrefix>mode_edit</mat-icon></button>
        </mat-cell>
    </div>
  
    <!-- string Column -->
    <div *ngIf="column.columnType=='string'">
      <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
    </div>

    <!-- link Column -->
    <div *ngIf="column.columnType=='link'">
      <mat-cell [ngClass]="[column.css, 'clickable']" (click)="onOpenDocument(row)" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
    </div>

    <!-- date Column -->
    <div *ngIf="column.columnType=='datetime'">
      <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) | date: 'medium' }}</mat-cell>
    </div>

  </ng-container>
  
  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
  

