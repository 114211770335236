import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'summary-breadcrumbs',
  templateUrl: './summary-breadcrumbs.component.html',
  styleUrls: ['./summary-breadcrumbs.component.scss']
})
export class SummaryBreadcrumbsComponent implements OnInit {

  @Input('values') values: string[];

  constructor() { }

  ngOnInit() {
  }

}
