<p><strong>Current view: </strong> {{ getCurrentGridView() }}</p>
<div class="table-container">
  <mat-table [dataSource]="dataSource" matSort dense>

<!-- Generic column definition -->
<ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
  <mat-header-cell [ngClass]="column.css" [mat-sort-header]="column.columnDef == 'menu' ? null : column.columnDef" [disabled]= "column.columnDef == 'menu' " disableClear *cdkHeaderCellDef>{{ column.header }} 

    <!--conditional context menu options for the grid (Export, column edit, etc)-->
    <button *ngIf="column.columnDef=='menu'" mat-icon-button [matMenuTriggerFor]="gridOptionsMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #gridOptionsMenu="matMenu">
      <button (click)="onExportTable()" mat-menu-item><mat-icon>description</mat-icon>Export Table as CSV</button>
    </mat-menu>    
  </mat-header-cell>
    
  <!-- Editable Column -->
  <div *ngIf="column.columnType=='editable'">
    <mat-cell [ngClass]="column.css" *matCellDef="let row">
            <input matInput
            autocomplete="off"
            id = "{{column.columnDef}}_{{row.daypartId}}"
            (click) = "$event.target.select()"
            value = "{{column.cell(row) | number: column.format }}"
            (keyup) = "editDirty = true"
            (blur) = "onSaveInput(column.columnDef, $event.target.value, row)"
            [disabled] = "processing" >
    </mat-cell>
  </div>

  <!-- string Column -->
  <div *ngIf="column.columnType=='string'">
    <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
  </div>

  <!-- value Column -->
  <div *ngIf="column.columnType=='value'">
    <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) | number: column.format }}</mat-cell>
  </div>

</ng-container>

<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
</div>
