<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Target Creation
  </h1>
  
  <div mat-dialog-content class="mat-app-background" style="overflow: unset">

    <!-- rotator market selection -->
    <div class="market-selector">
      <button mat-icon-button color="primary" aria-label="Previous Market" (click)="onSelectCurrentMarket(-1)">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>      
      {{ getCurrentMarket() }}
      <button mat-icon-button color="primary" aria-label="Next Market" (click)="onSelectCurrentMarket(1)">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>

    </div>
    <campaign-summary [values] = "campaignSummaryData"></campaign-summary>

    <mat-card>
      
      <!--Gender-->
      <mat-radio-group class="container" aria-label="Select Gender" (change)="onGenderChange()" [(ngModel)]="selectedGender" color="primary">
          <mat-radio-button color="primary" 
          class="radio-button" 
          *ngFor="let gender of genders" 
          [value]="gender">
          &nbsp;{{gender}} </mat-radio-button>
      </mat-radio-group>
  
      <!-- Age Range-->
      <div class="range">
        <label class="filter-title">Age range {{ getAgeRange() }}</label>
      <ng5-slider [(value)]="sliderMinValue"
              [(highValue)]="sliderMaxValue"
              [options]="sliderOptions"
              (userChange)="onUserChange($event)"
              [manualRefresh]="sliderRefresh">
      </ng5-slider>
      </div>

      <!-- Socio filter-->
      <label class="filter-title">Socio and economic filter</label>
      <div class="socio-group">
      <mat-radio-group
        class="radio-group"
        [(ngModel)]="selectedSocioGroup">
        <mat-radio-button color="primary" 
                          class="radio-button" 
                          *ngFor="let group of socioGroups" 
                          [value]="group" 
                          (change)="onSocioGroupChange($event)">
          {{group}}
        </mat-radio-button>
      </mat-radio-group>


      <!--Socio options-->
      <ul style="list-style-type: none;">
        <li *ngFor="let item of socioItems">
          <mat-checkbox [(ngModel)]="item.checked"
                        color="primary"
                        (ngModelChange)="onSocioItemChange()">
            {{item.name}}
          </mat-checkbox>
        </li>
      </ul>
    </div>

      <!-- target title editor-->
      <div class="target-title">
        <mat-form-field style="width:100%">
          <input #editTitle matInput [(ngModel)] = "unSavedTarget.name" />
          <button mat-icon-button matSuffix (click)= "editTitle.select()"><mat-icon matPrefix>mode_edit</mat-icon></button>
        </mat-form-field>
      </div>

    </mat-card>

  </div>

  <mat-dialog-actions>
    <button (click)="onSubmit()" mat-raised-button color="primary" type="button" class="button">Create</button>
    <button (click)="onCancel()" mat-raised-button color="primary" type="button" class="button">Cancel</button>
  </mat-dialog-actions>
  
  