<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    N-Tiles for {{ getTitle() }}
  </h1>
  
  <div mat-dialog-content style="overflow: hidden">
  
    <!-- rotator market selection -->
    <div class="market-selector">
      <button mat-icon-button color="primary" aria-label="Previous Market" (click)="onSelectCurrentMarket(-1)">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>      
        {{ getCurrentMarket() }}
      <button mat-icon-button color="primary" aria-label="Next Market" (click)="onSelectCurrentMarket(1)">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  
    <div>
      <campaign-summary [values]="summary"></campaign-summary>
      <n-tiles [data]="currentNTiles"></n-tiles>
    </div>
  </div>
  
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">Ok</button>
  </div>