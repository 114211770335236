<!--   cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle -->
<h1 mat-dialog-title>{{title}}</h1>
  
  <form [formGroup]="optimisationOptionsGroup" (ngSubmit)="onSubmit()" class="form">
  
    <div mat-dialog-content class="controls-container">

      <mat-tab-group style="height: 100%">
        <mat-tab label="Criteria">

          <div class="top-spacing">

            <!-- targets dropdown -->
            <div class="item">
                <mat-form-field class="item-spacing">
                  <mat-label>Primary Target</mat-label>
                  <mat-select formControlName="targetIndex">
                    <mat-option *ngFor="let tgt of targets" value="{{tgt.id}}">{{tgt.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

              <!-- Rank Stations By -->
              <mat-form-field>
                <mat-label>Rank Station By</mat-label>
                <mat-select formControlName="rankStationBy">
                  <mat-option *ngFor="let stn of rankStations" value="{{stn.id}}">{{stn.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>        

            <!-- Station Buying Goal-->
            <div class="item">
              <mat-form-field class="item-spacing">
              <mat-label>Station Buying Goal</mat-label>
              <mat-select formControlName="stationBuyingGoal" >
                <mat-option *ngFor="let goal of stationBuyingGoals" value="{{goal.id}}">{{goal.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input matInput (click) = "$event.target.select()"
                    type="number" min="0" max="99999" step="0.1"
                    formControlName="stationBuyingGoalValue" autocomplete="off">
                    <mat-error *ngIf="optimisationOptionsGroup.controls['stationBuyingGoalValue'].errors">
                      Buying goal value is required
                    </mat-error>
            </mat-form-field>
          </div>

          <!-- Market Goal-->
          <div class="item">
            <mat-form-field class="item-spacing">
              <mat-label>Market Goal</mat-label>
              <mat-select formControlName="marketGoal">
                <mat-option *ngFor="let goal of marketGoals" value="{{goal.id}}">{{goal.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input matInput (click) = "$event.target.select()"
                    type="number" min="0" max="99999" step="0.1" 
                    formControlName="marketGoalValue" autocomplete="off">
                    <mat-error *ngIf="optimisationOptionsGroup.controls['marketGoalValue'].errors">
                     Market goal value is required
                    </mat-error>
            </mat-form-field>
          </div>

          <!-- Number of weeks -->
          <div class="item">
            <mat-form-field>
              <mat-label>Week Count</mat-label>
              <input matInput (click) = "$event.target.select()"
                    type="number" min="1" max="52" step="1"
                    formControlName="numWeeks" autocomplete="off">
                    <mat-error *ngIf="optimisationOptionsGroup.controls['numWeeks'].errors">
                      Select number of weeks to optimise to
                    </mat-error>
            </mat-form-field>
          </div>

          <!-- Use Market Goal2 -->
          <div class="item">
            <mat-radio-group aria-label="Market Goal 2" formControlName="goalCombination" (change)="onMarketGoal2Change($event)">
              <mat-radio-button class="item-spacing" value="off">Off</mat-radio-button>
              <mat-radio-button class="item-spacing" value="and">And</mat-radio-button>
              <mat-radio-button class="item-spacing" value="or">Or</mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- Market Goal2-->
          <div class="item">
            <mat-form-field class="item-spacing">
              <mat-label>Second Market Goal</mat-label>
              <mat-select formControlName="marketGoal2" [attr.disabled]="true">
                <mat-option *ngFor="let goal of marketGoals" value="{{goal.id}}">{{goal.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input matInput (click) = "$event.target.select()"
                    type="number" min="0" max="99999" step="0.1" 
                    formControlName="marketGoalValue2" autocomplete="off" [attr.disabled]="true">
                    <mat-error *ngIf="optimisationOptionsGroup.controls['marketGoalValue2'].errors">
                      Market goal value is required
                     </mat-error>
 
            </mat-form-field>
          </div>
          </div>
      </mat-tab>

      <mat-tab label="Dayparts &amp; Costs">

        <!-- Right hand side-->
        <div class="top-spacing">

          <!--  DAYPART TABLE DEFINITIONS -->
          <div class="item">
            <mat-table [dataSource]="dataSource" matSort dense>
      
            <!-- Generic column definition -->
            <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
              <mat-header-cell [ngClass]="column.css" [mat-sort-header]="column.columnDef == 'menu' ? null : column.columnDef" [disabled]= "column.columnDef == 'menu' " disableClear *cdkHeaderCellDef>{{ column.header }} 
            
                <mat-checkbox *ngIf="column.columnDef=='select'" (change)="$event ? masterCheckBoxToggle() : null"
                [checked]="checkBoxHasValue() && isAllSelected()"
                [indeterminate]="checkBoxHasValue() && !isAllSelected()"
                [aria-label]="'Select All'">
                </mat-checkbox>

                <!--conditional context menu options for the grid (Export, column edit, etc)-->
                <button *ngIf="column.columnDef=='menu'" mat-icon-button [matMenuTriggerFor]="gridOptionsMenu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #gridOptionsMenu="matMenu">
                  <button mat-menu-item><mat-icon>description</mat-icon>Item 1</button>
                  <button mat-menu-item><mat-icon>description</mat-icon>Item 2</button>
                </mat-menu>    
              </mat-header-cell>
                
                <!-- Checkbox Column -->
                <div *ngIf="column.type=='select'">
                  <mat-cell [ngClass]="column.css" *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? checkboxToggle(row) : null"
                                  [checked]="checkboxIsSelected(row)"
                                  [aria-label]="'Daypart selection'">
                    </mat-checkbox>
                  </mat-cell>
                </div>

              <!-- Editable Column -->
              <div *ngIf="column.columnType=='editable'">
                <mat-cell [ngClass]="column.css" *matCellDef="let row">
                        <input matInput
                        autocomplete="off"
                        id = "{{column.columnDef}}_{{row.id}}"
                        (click) = "$event.target.select()"
                        value = "{{column.cell(row) | number: column.format }}"
                        (keyup) = "editDirty = true"
                        (blur) = "onSaveInput(column.columnDef, $event.target.value, row)"
                        [disabled] = "processing" >
                </mat-cell>
              </div>
            
              <!-- string Column -->
              <div *ngIf="column.columnType=='string'">
                <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
              </div>
            
              <!-- value Column -->
              <div *ngIf="column.columnType=='value'">
                <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) | number: column.format }}</mat-cell>
              </div>
            
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            
            </mat-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    </div> <!---dialog-content -->
 
    <mat-dialog-actions>

      <!-- schedules dropdown -->
      <div style="flex-grow: 1;">
        <h4 style="margin: 0px">Results to go into schedule</h4>
        <mat-form-field>
          <mat-select formControlName="scheduleIndex">
            <mat-option *ngFor="let sch of schedules" value="{{sch.id}}">{{sch.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button [disabled]="optimisationOptionsGroup.invalid" mat-raised-button color="primary" type="submit" class="button">Run</button>
      <button (click)="onCancel()" mat-raised-button color="primary" type="button" class="button">Cancel</button>
      <mat-error style="margin-left: 5px" *ngIf="!!validationMessage">{{validationMessage}}</mat-error>
    </mat-dialog-actions>
  
  </form>
  