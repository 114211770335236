import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MarketsService } from '../../shared/services/markets.service';
import { CampaignService } from '../../shared/services/campaign.service';
import { Market, Survey } from '../../shared/models/campaign.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Observable } from 'rxjs';
import { MarketColumn, MarketTableData } from './surveys.models';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  processing: boolean = false;

  // filter controls
  reportTypesShort: string[];   // PPM
  reportTypeShortSelected: string;
  reportTypesLong: string[];  // PPM Standard
  reportTypeLongSelected: string;
  surveySources: Survey[]; // currently displayed surveys (for dropdown)
  surveySourceSelected: string;
  marketNameFilter: string = "";

  //data
  localMarketData: MarketTableData[] = []  // table structure
  campaignSummary: string[]

  columns: MarketColumn[] = [
    { columnDef: 'select', header: '', type: 'select', css: 'column-select', cell: (row: any) => `${row.id}` },
    { columnDef: 'marketName', header: 'Market Name', type: 'string', css: 'column-string', cell: (row: any) => `${row.marketName}` },
    { columnDef: 'geography', header: 'Geography', type: 'string', css: 'column-string', cell: (row: any) => `${row.geography}` },
    { columnDef: 'sample', header: 'Sample', type: 'value', format: '1.0-0', css: 'column-value', cell: (row: any) => `${row.sample}` },
    { columnDef: 'population', header: 'Population [00]', type: 'value', format: '1.0-0', css: 'column-value', cell: (row: any) => `${row.population}` },
    { columnDef: 'datePeriod', header: 'Date Period', type: 'string', css: 'column-string', cell: (row: any) => `${row.datePeriod}` },
  ]

  displayedColumns: string[] = this.columns.map( c=> c.columnDef);
  initialSortColumn:string = 'marketName';

  // populated as a staring point if campaign already running
  startMarket: Market = null;  // contains marketsSelection[0] if needed
  marketsSelection: MarketTableData[] = [];     // total selected across all sweeps
  dataSource: MatTableDataSource<MarketTableData>;  // table data

  constructor (private marketsService: MarketsService,
              private campaignService: CampaignService,
              private dialogService: DialogService,
              private router: Router) { }
 
  ngOnInit() {

    // not ready
    if (!this.campaignService.run.documentStarted) this.router.navigate(['dashboard'])

    // UI
    this.campaignSummary = this.campaignService.run.getCampaignSummary();

    // set up datasource
    this.dataSource = new MatTableDataSource<MarketTableData>([]);
    this.dataSource.sort = this.sort;

    // work out were any selected markets are and start with them
    this.localMarketData = this.getMarketData( this.marketsService.allMarkets );

    // existing selected list
    this.marketsSelection = this.getMarketData(this.campaignService.run.markets);
    this.startMarket = this.campaignService.run.markets.length? this.campaignService.run.markets[0] : null;

    // fetch all surveys for the dropdown (markets will be requested on dropdown selection)
    this.processing = true;
    this.marketsService.getSurveys().subscribe( surveys => {

      this.reportTypesShort = []

      // build short report types
      surveys.forEach( survey => {
        
        if (this.reportTypesShort.indexOf(survey.reportTypeShort) == -1) this.reportTypesShort.push(survey.reportTypeShort);
      })

      this.reportTypeShortSelected = this.reportTypesShort.length? this.reportTypesShort[1] : "";
      if (this.startMarket) this.reportTypeShortSelected = this.startMarket.reportTypeShort;

      // build each dropdown in turn to the filtered data, either defaulting to first element or to startMarket
      this.applyFilter( this.startMarket );  

      this.processing = false;
    })

  }

  applyFilter( startMarket: Market ) {

    // base all dropdowns from left to right, starting at reportTypeShort
    this.loadReportTypesLong( this.reportTypeShortSelected, startMarket );
  }
  
  // set up long report types dropdown based on the current short types selection
    loadReportTypesLong( reportTypeShortSelected: string, startMarket: Market = null ) {
    this.reportTypesLong = []
    this.marketsService.allSurveys.forEach( survey=> {
      if (survey.reportTypeShort == this.reportTypeShortSelected && 
          this.reportTypesLong.indexOf(survey.reportType) ==-1 ) this.reportTypesLong.push( survey.reportType );
    })

    
    if ( startMarket ) this.reportTypeLongSelected = startMarket.reportType
      else this.reportTypeLongSelected = this.reportTypesLong.length ? this.reportTypesLong[0] : "";

    this.loadSurveySources( this.reportTypeLongSelected, startMarket );
  }

  // set up surveys sources dropdown based on long report type selection
  loadSurveySources( reportTypeLongSelected: string, startMarket: Market = null ) {
    this.surveySources = []

    // get the report type longs in this filter
    this.marketsService.allSurveys.forEach( survey=> {
      if (survey.reportType == reportTypeLongSelected) this.surveySources.push( survey );
    })

    // date time order
    this.surveySources.sort( (a,b) => { 
      const t1 = new Date(a.reportPeriodEnd).getTime();
      const t2 = new Date(b.reportPeriodEnd).getTime();
      return t2-t1; // descending
    } )

    // seek out the predefined startMarket if exists, else select first item
    if ( startMarket ) this.surveySourceSelected = this.surveySources.find( survey => survey.periodNameLong == startMarket.periodNameLong).id;
      else this.surveySourceSelected = this.surveySources.length ? this.surveySources[0].id : "";

    this.loadMarkets( this.surveySourceSelected );  // load the markets from the given survey
  }

  onReportTypeShortChange(e: MatSelectChange){
    this.loadReportTypesLong( this.reportTypeShortSelected );
    this.loadMarkets( this.surveySourceSelected );
  }

  onReportTypeLongChange(e: MatSelectChange){
    this.loadSurveySources( this.reportTypeLongSelected );
    this.loadMarkets( this.surveySourceSelected );
  }

  // main dropdown changed, load the associated markets
  onSurveySourceChange(e: MatSelectChange) {
    this.loadMarkets( this.surveySourceSelected );
  }
  
  // load markets from endpoint using the given survey
  loadMarkets( svy: string ) {

    let survey = this.marketsService.allSurveys.find( s => s.id == svy);
    if (!survey) return;

    // load and add to array
    if (!survey.loaded) {
      this.processing = true;
      this.marketsService.getMarketsBySurvey( survey ).subscribe( markets => {

        this.addMarkets(this.marketsService.allMarkets, markets );  // add to loaded markets collection
        this.localMarketData = this.getMarketData( this.marketsService.allMarkets );

        survey.loaded = true;

        // apply a filter to only show the selected survey
        this.prepareData();
        this.processing = false;
      })
  
    }
    // already loaded so display markets
    else {
        this.prepareData();
    }
  }

  // add any filters to the data before doing the dataSource
  prepareData() {

    let data = this.localMarketData.filter( market => market.surveyId == this.surveySourceSelected );
  
    // additional market name text filter
    if ( this.marketNameFilter ) {
      const text = this.marketNameFilter.toUpperCase();
      data = data.filter( market => market.marketName.toUpperCase().includes( text ) );
    }

    this.dataSource.data = data;
  }

  // convert to MarketTableData array, less fields and a combined date field
  getMarketData( markets: Market[] ): MarketTableData[] {
    return markets.map( m=> {
        return {
          surveyId: m.surveyId,
          geography: m.geography,
          periodNameLong: m.periodNameLong,
          periodNameShort: m.periodNameShort,
          marketName: m.marketName,
          reportPeriod: m.reportPeriod,
          reportPeriodId: m.reportPeriodId,
          reportType: m.reportType,
          datePeriod: new Date(m.startDate).toLocaleDateString() + " to " + new Date(m.endDate).toLocaleDateString(),
          marketFilename: m.marketFilename,
          population: m.universe,
          sample: m.sample,
        }
    })
  }

  getMarketInfo( markets: MarketTableData[]): Market[] {
    let filenames = markets.map( m => m.marketFilename);
    return this.marketsService.allMarkets.filter ( mk => filenames.includes(mk.marketFilename));
  }

 // add to loaded markets collection if not already there
 addMarkets(allMarkets: Market[], newMarkets: Market[] ){

    newMarkets.forEach( market => {

      if (! allMarkets.find( mkt=> mkt.marketFilename === market.marketFilename )) {
        allMarkets.push(market)
      }
    })

 } 

  onMarketNameChange( e ) {
    this.marketNameFilter = (e.target as HTMLInputElement).value;
    this.prepareData();
  }

  // a tab on the header has been clicked.  Verify the markets and perform MarketsService.MarketInfo
  onTab = (next: string) => {

    return new Observable( observe => {

      // allow ourselves to be displayed
      if (next =="markets" || next == "dashboard") {
        observe.next(true);
        observe.complete();
      }
      else {

        //nothing yet selected
        if (!this.marketsSelection.length) {
          this.dialogService.message("Please select your market", "Market"); 
          observe.next(false);
          observe.complete();
          return;
        }

        // there is no current plan so initialise the selected market(s) and continue to the next screen
        if ( !this.campaignService.run.hasPlan() ) { 

          this.initialiseMarketInfo().subscribe( data=> {
            observe.next(true);
            observe.complete();
          })
        }

        // there is already a plan so make sure the user is happy to change
        else {
          const title = "Market has been changed";
          const msg = `You've changed your market list, this may affect your current schedules.  Are you sure?`
          const buttons = [
            { caption: "Yes", data: "YES" },
            { caption: "No", data: "NO" },
            { caption: "Cancel", data: "CANCEL" }
          ]

          this.dialogService.question(msg, title, { buttons: buttons }).afterClosed().subscribe( ans => {

            // load market info and allow tab change
            if (ans.data == "YES") {
              this.initialiseMarketInfo().subscribe( data=> {
                observe.next(true);
                observe.complete();
              })
            }

            // Allow tab change but dont change markets
            if (ans.data == "NO") {
              observe.next(true);
              observe.complete();
            }

            // Dont change tabs, stay on markets
            if (ans.data == "CANCEL") {
              observe.next(false);
              observe.complete();
            }
  
          })
        }
      }
    })
  }

  // UI isSelected using marketsSelection (for UI)
  isSelected(row: MarketTableData): boolean {
    return !!this.marketsSelection.find( m=> m.marketFilename === row.marketFilename);
  }

  // toggle selected / not selected using the marketsSelection
  toggle(row: MarketTableData):void {
      const index = this.marketsSelection.findIndex( m=> m.marketFilename === row.marketFilename);
      (index ==-1) ? this.marketsSelection.push(row) : this.marketsSelection.splice(index, 1);
  }

  removeChip(market: MarketTableData): void {
    this.toggle(market);
  }


  // call getMarketInfo and initialise all thats needed before continuing
  initialiseMarketInfo(): Observable< boolean > {

    return new Observable( observe => {

      // load any new markets and remove unwanted markets from the run.markets array and marketsService array
      let selectedMarkets = this.marketsSelection.map( market => market.marketFilename);
      let curMarkets = this.campaignService.run.markets.map( market => market.marketFilename);

      //let toAdd = selectedMarkets.filter( m=> !curMarkets.includes(m));
      let toRemove = curMarkets.filter( m=> !selectedMarkets.includes(m));

      // remove everything from the current campaign and market service as required
      toRemove.forEach( filename => {
        this.campaignService.run.deleteMarket(filename);
        this.marketsService.deleteMarket(filename);
      })

      this.processing = true;
      this.campaignService.run.markets = this.getMarketInfo(this.marketsSelection);

      // API call 
      this.marketsService.getMarketsInfo( selectedMarkets, false ).subscribe( added => {  

        this.campaignService.configureAfterLoadMarkets(this.marketsService.markets, added.added);

        observe.next( true );
        observe.complete();
        this.processing = false;
      })
    })
  }

}
