<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{title}}
</h1>
   
  <div mat-dialog-content>
    <p>{{message}}</p>

    <h3>Selected: {{selection.selected.length}}</h3>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <button mat-icon-button matPrefix (click)="onReset()" matTooltip="Reset"><mat-icon>remove_circle_outline</mat-icon></button>
      <input type="text" [(ngModel)]="filterText" matInput (keyup)="onApplyFilter($event)" placeholder="">
    </mat-form-field>    

    <div class="table-container">
        <mat-table [dataSource]="dataSource" matSort dense >
    
      <!-- Generic column definition -->
      <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>{{ column.header }}
            <mat-checkbox *ngIf="column.columnDef == 'select'" (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </mat-header-cell>
    
        <!-- Checkbox Column -->
        <div *ngIf="column.type=='select'">
          <mat-cell *matCellDef="let row" style="width: 50px">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </mat-cell>
        </div>
    
        <!-- text Column -->
        <div *ngIf="column.type=='string'">
          <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
        </div>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggle(row)" class="table-row"></mat-row>
    
      </mat-table>
      </div>

  </div>
   
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">{{confirmButtonText}}</button>
    <button mat-raised-button color="primary" (click)="onDismiss()">{{cancelButtonText}}</button>
  </div>
  