import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { TargetsComponent } from './pages/targets/targets.component';
import { StationsComponent } from './pages/stations/stations.component';
import { PlanningComponent } from './pages/planning/planning.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { BugReporterComponent } from './pages/bug-reporter/bug-reporter.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component'

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'markets', component: SurveysComponent },
  { path: 'targets', component: TargetsComponent },
  { path: 'stations', component: StationsComponent },
  { path: 'planning', component: PlanningComponent },
  { path: 'documentation', component: DocumentationComponent },
  // Redirecting any Bug Reporter navigation to the home dashboard until Bug Reporter is re-written to utilize Salesforce API
  // { path: "bugreporter", component: BugReporterComponent },
  { path: 'bugreporter', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule]
})
export class AppRoutingModule { }
