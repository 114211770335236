<client-area areatitle="Stations" subtitle="Station rankings" icon="radio">

  <tab-navigation header current="stations"></tab-navigation>
  <summary-breadcrumbs breadcrumbs [values]="campaignSummary"></summary-breadcrumbs>

  <div menu>
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button (click)="onPreferredDaypartSelection()" mat-menu-item>Preferred dayparts</button>
      <button (click)="onPreferredStationSelection()" mat-menu-item>Preferred stations</button>
      <mat-divider></mat-divider>
      <button mat-menu-item><mat-icon>help_outline</mat-icon>Help</button>
    </mat-menu>
  </div>

  <!--Controls-->

  <!-- build market tabs -->
  <div class="Tabs-container">
    <mat-tab-group [selectedIndex]="currentMarket"
                   (selectedIndexChange)="onCurrentMarketChange($event)">
      <mat-tab *ngFor="let tab of marketTabs" [label]="tab"></mat-tab>
    </mat-tab-group>    
  </div>

    <!--N Station Selection-->
    <mat-form-field>
      <mat-label>Select Top n Stations</mat-label>
      <mat-select [(ngModel)]="nStationsSelected"
                  (ngModelChange)="onNStationsChange()">
        <mat-option *ngFor="let n of nStations" [value]="n.value">
          {{n.text}} 
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- apply all checkbox -->
    <mat-checkbox (change)="onNStationApplyAll($event)"
      [checked]="nStationApplyAll"
      aria-label="Apply to all markets">
      Apply to all Markets
    </mat-checkbox>

  <h4 color="accent-color" >{{selection?.selected.length}} Station{{selection?.selected.length != 1 ? 's' : ''}} Selected
    <span *ngIf="selectedSchedule"> (Station selection for Schedule <strong> '{{selectedSchedule.name}}' ) </strong></span>
  </h4>

  <!-- table filter options -->
  <div class="filter-container">
    <!--Daypart Selection-->
    <mat-form-field>
      <mat-label>Daypart Selection</mat-label>
      <mat-select [(ngModel)]="daypartSelected"
                  (ngModelChange)="onDaypartChange()">
        <mat-option *ngFor="let daypart of daypartList" [value]="daypart.id">
          {{daypart.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--Target Selection-->
    <mat-form-field>
      <mat-label>Target Selection</mat-label>
      <mat-select [(ngModel)]="targetSelected"
                  (ngModelChange)="onTargetChange()">
        <mat-option *ngFor="let tgt of targetList" [value]="tgt.id">
          {{tgt.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <chip-selection class="chip-container" title="Band" [data]="bandFilterList" (change)="onBandFilterChange($event)"></chip-selection>
    <chip-selection class="chip-container" title="Format" [data]="formatFilterList" (change)="onFormatFilterChange($event)"></chip-selection>
    <chip-selection class="chip-container" title="Network" [data]="networkFilterList" (change)="onNetworkFilterChange($event)"></chip-selection>
  </div>

  <!-- actual table -->
  <div class="table-container">
    <mat-table [dataSource]="dataSource" dense matSort matSortActive="{{initialSortColumn}}" matSortDirection="desc" matSortDisableClear>

  <!-- Generic column definition -->
  <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
    <mat-header-cell [ngClass]="column.css" [mat-sort-header]="column.columnDef == 'menu' ? null : column.columnDef" [disabled]= "column.columnDef == 'menu' " disableClear *cdkHeaderCellDef>{{ column.header }} 

    <!--conditional context menu options for the grid (Export, column edit, etc)-->
    <button *ngIf="column.columnDef=='menu'" mat-icon-button [matMenuTriggerFor]="gridOptionsMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #gridOptionsMenu="matMenu">
      <button (click)="onExportTable()" mat-menu-item><mat-icon>description</mat-icon>Export Table as CSV</button>
    </mat-menu>    
  </mat-header-cell>

    <!-- Checkbox Column -->
    <div *ngIf="column.type=='select'">
      <mat-cell [ngClass]="column.css" *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </mat-cell>
    </div>

    <!-- station Column -->
    <div *ngIf="column.type=='string'">
      <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
    </div>

    <!-- number Column -->
    <div *ngIf="column.type=='value'">
      <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) | number: column.format }}</mat-cell>
    </div>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggle(row)"></mat-row>

  </mat-table>
  </div>
</client-area>
