import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ScheduleExportEvent, ScheduleChangedEvent, SchedulesComponent } from "./schedules/schedules.component";
import { MarketsService } from "../../shared/services/markets.service";
import { DialogService } from "../../shared/services/dialog.service";
import { CampaignService } from "../../shared/services/campaign.service";
import { Target, Station, OptimisationEvaluation, Market } from "../../shared/models/campaign.model";
import { PlanningService } from "../../shared/services/planning.service";
import { SummaryValues } from "../../shared/components/campaign-summary/campaign-summary.component";
import { TotalsGraphingComponent } from "./totals-graphing/totals-graphing.component";
import { HelperService } from "../../shared/services/helper.service";
import { DistributionSet, FreqDistDialogModel } from "../../shared/components/dialogs/freq-dist-dialog/freq-dist-dialog.component";
import { CampaignSchedule } from "../../shared/classes/campaign-schedule";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { MatSelectChange } from "@angular/material/select";
import { DocumentService } from "../../shared/services/document.service";
import { PlanningMethod, PlanningPeriod } from "src/app/shared/classes/campaign-schedule-plan";
import { CampaignDirty } from "src/app/shared/classes/radio-campaign";
import { OptimisationDialogModel } from "src/app/shared/components/dialogs/optimisation-dialog/optimisation-dialog.component";
import { CSVExporter } from "src/app/shared/classes/file-exporter";
import { TupAnalyticsService, EventCategory } from "@telmar-global/tup-analytics";
import { TotalsMultiMarketComponent } from "./totals-multi-market/totals-multi-market.component";
import { CampaignMarketSchedule } from "src/app/shared/classes/campaign-market-schedule";
import { forkJoin } from "rxjs";
import { MixMarketService } from "src/app/shared/services/mix-market.service";
import { NTilesDialogModel } from "src/app/shared/components/dialogs/n-tiles-dialog/n-tiles-dialog.component";
import lodash from "lodash";
import sanitize from "sanitize-filename";

export interface UISelection {
  id: string;
  name: string;
}

@Component({
  selector: "app-planning",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./planning.component.html",
  styleUrls: ["./planning.component.scss"],
})
export class PlanningComponent implements OnInit {
  @ViewChild("scheduleGrid", { static: true }) scheduleGrid: SchedulesComponent;
  @ViewChild("totalsGridMultiMarket", { static: true }) totalsGridMultiMarket: TotalsMultiMarketComponent;
  @ViewChild("totalsGraphing", { static: true }) totalsGraphing: TotalsGraphingComponent;
  @ViewChild("planningMethod", { static: true }) planningMethod: MatButtonToggleGroup;
  @ViewChild("planningWeekPeriod", { static: true }) planningWeekPeriod: MatButtonToggleGroup;

  campaignSummary: string[];
  campaignSummaryCombined: boolean = false;
  mainTitle: string; // top title

  campaignSummaryData: SummaryValues = {
    title: "",
    featured: [],
    sideList: [],
  };

  scheduleTabs: UISelection[];
  private _currentSchedule: number;
  get currentSchedule(): number {
    return this._currentSchedule;
  }
  set currentSchedule(value: number) {
    this._currentSchedule = value;
    this.campaignService.run.currentSchedule = value;
  }

  planningTargets: UISelection[];
  currentTarget: string;

  planningStations: UISelection[];
  currentStation: string;

  nStationsSelected: number;
  nStations: any[];

  // market tabs
  marketTabs: UISelection[];
  currentMarket: number;

  numWeekCounts: any[];
  numWeekSelected: number;

  optimisationMessages: string[];

  planAcrossMarkets: boolean;
  processing: boolean = false;

  constructor(
    private marketsService: MarketsService,
    private dialogService: DialogService,
    private campaignService: CampaignService,
    private router: Router,
    private as: TupAnalyticsService,
    private planningService: PlanningService,
    private documentService: DocumentService,
    private helperService: HelperService,
    private mixMarketService: MixMarketService
  ) {}

  ngOnInit() {
    if (!this.campaignReady()) return;

    this.currentSchedule = this.campaignService.run.currentSchedule || 0;

    // UI market tabs init
    this.marketTabs = this.campaignService.run.markets.map((market, index) => {
      return { name: market.marketName, id: index + "" };
    });
    this.currentMarket = 0;

    // UI planning stations
    const scheduleMarket = this.getCurrentScheduleMarket();

    // set up screen defaults and call schedule grid LoadData
    this.planningTargets = this.campaignService.run.targets.map((tgt) => {
      return { id: tgt.coding, name: tgt.name };
    });
    this.currentTarget = this.planningTargets.length
      ? this.planningTargets[0].id
      : null;
    this.populateScheduleTabs();

    // build N station selector
    this.nStations = new Array(21).fill(0).map((x, i) => {
      return { value: i + 1, text: `Select ${i + 1}` };
    });
    //this.nStationsSelected = this.campaignService.run.stations.length;
    this.nStationsSelected = scheduleMarket.stations.length;

    // build weekcount selector
    this.numWeekCounts = new Array(49).fill(0).map((x, i) => {
      return { value: i + 1, text: `${i + 1} Week` + (i > 0 ? "s" : "") };
    });
    this.numWeekSelected = scheduleMarket.numWeeks;

    this.populateCampaignSummary();

    // check if campaign is dirty and force a recalc rather than just show current results
    if (
      this.campaignService.run.isDirty() &&
      this.campaignService.run.hasPlan()
    ) {
      this.campaignService.run.removeDirty();
      this.calculate(true);
    } else {
      this.loadData(true, false);
    }
  }

  // navigation arrows on main market/schedule title
  onNavigateMainTitle(direction: number) {
    this.campaignSummaryCombined = !this.campaignSummaryCombined;
    this.populateCampaignSummary(
      this.getCurrentTarget(),
      this.getCurrentSchedule()
    );
  }

  // schedule grid R&F edit call back
  onScheduleGridChange(e: ScheduleChangedEvent) {
    this.populateCampaignSummary(e.target, e.schedule);
    //this.totalsGrid.loadData(e.market, e.target, e.schedule);
    this.totalsGridMultiMarket.loadData(e.target, e.schedule);
    this.totalsGraphing.loadData(
      e.market,
      e.target,
      e.schedule,
      e.reachChanged
    );
  }

  // schedule grid is calculating ( or not)
  onCalculating(value: boolean) {
    this.processing = value;
  }

  onScheduleExport(e: ScheduleExportEvent) {
    this.exportTable(e.columns, e.data);
  }

  // build campaign summary panel
  populateCampaignSummary(
    target: Target = null,
    schedule: CampaignSchedule = null
  ) {
    target = target || this.getCurrentTarget();
    schedule = schedule || this.getCurrentSchedule();
    const market = this.getCurrentMarket();
    const scheduleMarket = schedule.market(market);

    this.campaignSummary = this.campaignService.run.getCampaignSummary(
      market,
      target,
      schedule
    ); // breadcrumbs

    const totals = this.campaignSummaryCombined
      ? schedule.getCombinedMarketTotalLine(target)
      : scheduleMarket.getScheduleTotals(target);
    const dec = this.campaignService.run.prefs.user.UI.floatDecimals;

    const name = this.campaignSummaryCombined
      ? `(Markets Combined)`
      : `(${market.marketName})`;
    this.mainTitle = `${schedule.name} ${name}`;

    this.campaignSummaryData = {
      // top schedule panel
      title: "",
      featured: [
        { title: "Spots", value: this.helperService.formatFloat(totals.spots) },
        {
          title: "GRPs",
          value: this.helperService.formatFloat(totals.grps, dec),
        },
        {
          title: "Total Reach %",
          value: this.helperService.formatFloat(totals.reachPct, dec),
        },
        {
          title: "Frequency",
          value: this.helperService.formatFloat(totals.avgFreq, dec),
        },
        {
          title: "Total Reach [00]",
          value: this.helperService.formatFloat(totals.reach00),
        },
        {
          title: "Impressions [00]",
          value: this.helperService.formatFloat(totals.impacts),
        },
        {
          title: "Cost $",
          value: this.helperService.formatFloat(totals.totalCost, -1),
        }, // -1 for cost formatting (1.2-2)
      ],
      sideList: [],
    };
  }

  onScheduleAdd() {
    const copyFrom = this.getCurrentSchedule();
    const marketFilename = this.getCurrentMarket().marketFilename;

    // copy all settings except the actual spots/costs
    this.campaignService.run.addSchedule( marketFilename, "Schedule " + (this.campaignService.run.schedules.length + 1), copyFrom );
    this.populateScheduleTabs();

    this.currentSchedule = this.campaignService.run.schedules.length - 1;
    const currentScheduleObject = this.getCurrentSchedule();

    currentScheduleObject.market(marketFilename).plan.clear();

    // add all other markets
    this.campaignService.run.markets.forEach(market => {

      // don't re-add the current one
      if (market.marketFilename !== marketFilename) {
        const scheduleMarket = currentScheduleObject.addMarket(market.marketFilename);

        scheduleMarket.plan.copyFrom(copyFrom.market(market.marketFilename).plan);
        scheduleMarket.stations = Object.assign([], copyFrom.market(market.marketFilename).stations);
      }

    });

    this.campaignService.run.addDirty(CampaignDirty.planning);
  }

  onScheduleDuplicate(index: string) {
    let sch = this.campaignService.run.schedules[parseInt(index)];
    this.campaignService.run.addSchedule(
      this.getCurrentMarket().marketFilename,
      sch.name + " (Copy)",
      sch
    );
    this.populateScheduleTabs();
    this.campaignService.run.addDirty(CampaignDirty.planning);
  }

  onScheduleRename(index: string) {
    let name = this.campaignService.run.schedules[parseInt(index)].name;
    this.dialogService
      .openSimpleInputDialog(
        "schedule name",
        "Current Schedule",
        "Enter new name for your schedule.",
        name
      )
      .afterClosed()
      .subscribe((ans) => {
        if (ans) {
          this.campaignService.run.schedules[parseInt(index)].name =
            ans.returnValue;
          this.populateScheduleTabs();
          this.campaignService.run.addDirty(CampaignDirty.planning);
          this.populateCampaignSummary();
        }
      });
  }

  onScheduleDelete(index: string) {
    const name = this.campaignService.run.schedules[parseInt(index)].name;
    if (this.campaignService.run.schedules.length == 1) return; // cant delete last schedule

    if (this.campaignService.run.schedules[parseInt(index)].hasResults()) {
      // schedule has results so ask if you're sure
      this.dialogService
        .question(
          `Remove schedule '${name}' from your campaign?`,
          "Delete Schedule"
        )
        .afterClosed()
        .subscribe((ans) => {
          if (ans.data == "OK") {
            this.campaignService.run.deleteSchedule(parseInt(index));
            this.currentSchedule = Math.min(
              this.currentSchedule,
              this.campaignService.run.schedules.length - 1
            );
            this.populateScheduleTabs();
            this.onScheduleChange(this.currentSchedule);
          }
        });
    }
    // remove without quesiton
    else {
      this.campaignService.run.deleteSchedule(parseInt(index));
      this.currentSchedule = Math.min(
        this.currentSchedule,
        this.campaignService.run.schedules.length - 1
      );
      this.populateScheduleTabs();
      this.onScheduleChange(this.currentSchedule);
    }
  }

  onScheduleChange(tabIndex: number) {
    this.currentSchedule = tabIndex;
    this.loadData(true, false);
  }

  // used by the view so not all schedules can be removed
  getScheduleCount() {
    return this.campaignService.run.schedules.length;
  }

  onDAUExport() {

    const schedule = this.getCurrentSchedule();
    if (!schedule.hasResults()) {
      this.dialogService.message("Please start your plan first", "Media Mix Export");
      return;
    }

    this.mixMarketService.exportDAU(this.campaignService.run.targets, schedule).subscribe((dauData) => {

        if (dauData) {
          let dauFile = new CSVExporter();

          dauData = dauData.replace(/\n/g, "\r\n");  // windows needs crlf added
          dauFile.add(dauData);

          dauFile.saveAs( sanitize(`MediaMix_${schedule.name}.dau`) );
        }
      });
  }

  onSaveDocument(newFilename: boolean) {

    if (newFilename || !this.campaignService.run.documentId) {
      this.dialogService.clientInfo(this.campaignService.run.clientInfo).afterClosed().subscribe((info) => {
          if (info) {
            this.campaignService.run.clientInfo = Object.assign([], info);

            let doc = this.campaignService.getCampaignDocument();
            doc.id = ""; // force create
            this.documentService
              .createOrUpdateDocument(doc)
              .subscribe((res) => {
                if (res.success) {
                  this.campaignService.run.documentId = res.id;
                  //this.campaignService.run.dirty = false;
                  this.campaignService.run.removeDirty();
                }
              });
          }
        });
    } else {
      this.documentService.createOrUpdateDocument(this.campaignService.getCampaignDocument()).subscribe((res) => {
          if (res.success) {
            // this.campaignService.run.dirty = false;
            this.campaignService.run.removeDirty();
          }
        });
    }
  }

  onNewDocument() {
    this.campaignService.checkDocumentDirty().subscribe((ans) => {
      if (ans) {
        this.campaignService.clearAll();
        this.campaignService.run.startNewDocument();
        this.router.navigate(["markets"]);
      }
    });
  }

  populateScheduleTabs() {
    this.scheduleTabs = this.campaignService.run.schedules.map((sch, index) => {
      return { name: sch.name, id: "" + index };
    });
  }

  getCurrentSchedule(): CampaignSchedule {
    return this.campaignService.run.schedules[this.currentSchedule];
  }

  getCurrentScheduleMarket(): CampaignMarketSchedule {
    return this.campaignService.run.schedules[this.currentSchedule]
      ? this.campaignService.run.schedules[this.currentSchedule].market(
          this.getCurrentMarket()
        )
      : null;
  }

  getCurrentTarget(): Target {
    return this.campaignService.run.targets.find(
      (tgt) => tgt.coding == this.currentTarget
    );
  }

  getCurrentMarket(): Market {
    return this.campaignService.run.markets[this.currentMarket];
  }

  getCurrentStation(): Station {
    // written out in full so it's clearer
    // 'generic' return null as no specific station needed, else return Station object
    const schedule = this.getCurrentSchedule();
    const mkt = this.getCurrentMarket();
    const scheduleMarket = schedule.market(mkt.marketFilename);

    const stations = scheduleMarket.stations || mkt.stations;

    switch (schedule.planningMethod) {
      case PlanningMethod.Generic:
        return null;
      case PlanningMethod.ByStation:
        return stations.find((s) => s.id == parseInt(this.currentStation));
    }
  }

  onFrequencyDistribution() {
    // calc frewdist for each market in the current schedule
    // calc freqdist for combined markets
    // build array of DistributionSet[]
    // call dialogservice
    const scheduleMarket = this.getCurrentScheduleMarket();
    if (!scheduleMarket || !scheduleMarket.hasResults()) {
      this.dialogService.message(
        "Please start your plan first",
        "Frequency Distribution"
      );
      return;
    }

    const schedule = this.getCurrentSchedule();
    const target = this.getCurrentTarget();

    this.processing = true;
    // call for combined and each market
    const reqs = []; // calc combined
    this.campaignService.run.markets.forEach((market) => {
      reqs.push(
        this.planningService.frequencyDistribution(market, schedule, target)
      ); // each market
    });
    reqs.push(
      this.planningService.frequencyDistributionCombined(schedule, target)
    );

    // all finished
    forkJoin(reqs).subscribe((data) => {

      const distributionSets = this.getDisterbutionSets(schedule, target, true);

      const startIndex = distributionSets.findIndex(
        (s) => s.marketFilename === scheduleMarket.marketFilename
      );
      let info = new FreqDistDialogModel(
        `${schedule.name}, ${target.name}`,
        startIndex,
        distributionSets
      );

      this.processing = false;
      this.dialogService.frequencyDistribution(info);
    }); //fork
  }

  onNTiles() {
    const scheduleMarket = this.getCurrentScheduleMarket();
    if (!scheduleMarket || !scheduleMarket.hasResults()) {
      this.dialogService.message("Please start your plan first", "N-Tiles");
      return;
    }

    const schedule = this.getCurrentSchedule();
    const target = this.getCurrentTarget();

    this.processing = true;
    // call for combined and each market
    const reqs = [];
    this.campaignService.run.markets.forEach((market) => {
      reqs.push(this.planningService.nTiles(market, schedule, target)); // each market
    });

    reqs.push(this.planningService.nTilesCombined(schedule, target)); // each market

    forkJoin(reqs).subscribe((data) => {
      const distributionSets = this.getDisterbutionSets(schedule, target, false);
      const startIndex = distributionSets.findIndex( (s) => s.marketFilename === scheduleMarket.marketFilename );

      console.log("onTiles data", distributionSets);
      let info = new NTilesDialogModel( `${schedule.name}, ${target.name}`, startIndex, distributionSets, data);

      this.processing = false;
      this.dialogService.nTiles(info);
    });
  }

  getDisterbutionSets( schedule: CampaignSchedule, target: Target, frequencyDistribution: boolean ): DistributionSet[] {

    const distributionSets: DistributionSet[] = [];

    // extract results for each market
    schedule.markets.forEach( (scheduleMaket) => {
      const mkt = this.campaignService.run.market( scheduleMaket.marketFilename );
      const res = scheduleMaket.results.find( (tgt) => tgt.target.coding == target.coding );

      distributionSets.push({
        title: `${mkt.marketName}`,
        marketFilename: mkt.marketFilename,
        reach: (res.total.totalReach / res.target.universe) * 100, // reach
        GRPs: (res.total.totalImpacts / res.target.universe) * 100, // GRPs
        universe: res.target.universe, // universe
        distribution: frequencyDistribution ? res.total.frequencyDistribution : null, // freq array
      })
    })

    // add combined distributions
    const combined = schedule.getCombinedMarketTotalLine(target);
    distributionSets.push({
      title: `Markets Combined`,
      reach: combined.reachPct, // reach
      GRPs: combined.grps, // GRPs
      universe: combined.universe, // universe
      distribution: frequencyDistribution ? schedule.getCombinedFreqDistResult(target).frequencyDistribution : null, // freq array
    })
    return distributionSets

  }

  onFrequencyDistribution___OLD() {
    const scheduleMarket = this.getCurrentScheduleMarket();

    if (!scheduleMarket || !scheduleMarket.hasResults()) {
      this.dialogService.message(
        "Please start your plan first",
        "Frequency Distribution"
      );
      return;
    }

    const schedule = this.getCurrentSchedule();
    const target = this.getCurrentTarget();
    const market = this.getCurrentMarket();
    const schResults = scheduleMarket.results.find(
      (tgt) => tgt.target.coding == target.coding
    );

    this.planningService
      .frequencyDistribution(market, schedule, target)
      .subscribe((freq) => {
        schResults.total.frequencyDistribution = freq;

        const distributionSet: DistributionSet = {
          title: `${market.marketName}, ${schedule.name}, ${target.name} `,
          reach:
            (schResults.total.totalReach / schResults.target.universe) * 100, // reach
          GRPs:
            (schResults.total.totalImpacts / schResults.target.universe) * 100, // GRPs
          universe: schResults.target.universe, // universe
          distribution: schResults.total.frequencyDistribution, // freq array
        };

        let info = new FreqDistDialogModel(
          `${market.marketName}, ${schedule.name}, ${target.name} `,
          0,
          [distributionSet]
        );
        this.dialogService.frequencyDistribution(info);
      });
  }

  hasOptimisation(): boolean {
    return !!this.getCurrentScheduleMarket()?.optimisation;
  }

  getOptimisationSettings(): string[] {
    const schedule = this.getCurrentSchedule();
    const scheduleMarket = schedule.market(this.getCurrentMarket());

    if (scheduleMarket.optimisation) {
      let data: string[] = [
        `Buying Goal:\t ${scheduleMarket.optimisation.stationBuyingGoal}, ${scheduleMarket.optimisation.stationBuyingGoalValue}`,
        `Market Goal:\t ${scheduleMarket.optimisation.marketGoal}, ${scheduleMarket.optimisation.marketGoalValue}`,
      ];
      return data;
    }
    return [];
  }
  // optimise click from the view button or button group
  onOptimisationClick() {
    // default optimise options (note N Stations)
    //const schedule = this.getCurrentSchedule();
    const scheduleMarket = this.getCurrentScheduleMarket();

    let opt = new OptimisationDialogModel(
      "avgQtrHr",
      "minFreq",
      0,
      "rch",
      0,
      "off",
      "topNStations",
      scheduleMarket.stations.length,
      scheduleMarket.numWeeks
    );

    if (scheduleMarket.optimisation) {
      opt.copyFrom(scheduleMarket); // copy optimisation settings and daypart costs
      opt.scheduleIndex = "" + this.currentSchedule; //  '-1' by default
    }

    opt.market = this.getCurrentMarket();
    opt.targets = this.campaignService.run.targets;
    opt.schedules = this.campaignService.run.schedules;
    opt.dayparts = this.campaignService.run.dayparts;

    // dialog with current schedule/market costs + criteria
    this.dialogService
      .optimisationOptions(opt)
      .afterClosed()
      .subscribe((options) => {
        if (options) {
          // get schedule or create a new one
          const index = parseInt(options.scheduleIndex);
          let schedule: CampaignSchedule =
            index !== -1
              ? this.campaignService.run.schedules[index]
              : this.campaignService.run.addSchedule(
                  this.getCurrentMarket().marketFilename,
                  "Optimized Schedule"
                );

          options.saveSettings(this.getCurrentMarket(), schedule); // save settings for next time
          schedule.numWeeks = options.numWeeks;

          const primaryTarget = this.campaignService.run.targets[
            parseInt(options.targetIndex)
          ];
          const optimiseOptions: OptimisationEvaluation = {
            criteria: options,
          };

          //Make this schedule an optimised one and switch UI
          this.processing = true;
          this.campaignService.run.addDirty(CampaignDirty.planning);

          this.as.e(EventCategory.Navigation, "optimization", "");

          let reqs = [];
          // generic schedules produce optimisation for every market, else just optimise within the currently selected market
          const markets =
            schedule.planningMethod == PlanningMethod.Generic
              ? this.campaignService.run.markets
              : [this.getCurrentMarket()];

          // run optimisation for each market
          markets.forEach((market) => {
            options.saveSettings(market, schedule); // copy the options to seach scheudleMarket
            let optionsCopy = lodash.cloneDeep(optimiseOptions);

            reqs.push(
              this.planningService.optimisation(
                market,
                market.stations,
                this.campaignService.run.dayparts,
                this.campaignService.run.targets,
                primaryTarget,
                schedule,
                optionsCopy
              )
            );
          });

          // wait for requests to finish
          forkJoin(reqs).subscribe((data) => {
            // new schedule has been created
            this.populateScheduleTabs();
            this.currentSchedule =
              index == -1
                ? this.campaignService.run.schedules.length - 1
                : index;

            this.loadData(true, true);
            this.processing = false;
          });
        }
      });
  }

  // preferred daypart selection.  Results written to campaignService.run.dayparts
  onPreferredDaypartSelection() {
    this.campaignService.selectPreferredDayparts().subscribe((data) => {
      if (data) this.loadData(false, false);
    });
  }

  // preferred station selection
  onPreferredStationSelection() {
    this.campaignService.selectPreferredStations().subscribe((data) => {
      if (data) this.loadData(false, false);
    });
  }

  // effective reach menu item
  onEffectiveReachLevelChange() {
    let eff = "" + this.campaignService.run.effectiveReachLevel;
    this.dialogService
      .openSimpleInputDialog(
        "Effective Reach Level",
        "Effective Reach Level",
        `Change your campaign effective reach level`,
        eff
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          let count = parseInt(res.returnValue) || 0;
          if (count < 2) {
            this.dialogService.message(
              "Effective reach levels must be greater than 1",
              "Effective Reach Level"
            );
          } else {
            this.campaignService.run.effectiveReachLevel = count;
            this.calculateAllSchedules(true); // all schedules need recalculating
          }
        }
      });
  }

  // weekc count menu item
  onWeekCountChange() {
    const schedule = this.getCurrentSchedule();
    let numWeeks: string =
      (schedule.planAcrossMarkets
        ? schedule.numWeeks
        : this.getCurrentScheduleMarket().numWeeks) + "";
    const message = schedule.planAcrossMarkets
      ? "For all markets in this schedule"
      : "For the current market";

    this.dialogService
      .openSimpleInputDialog(
        "Week count",
        "Change Week count",
        `Change your week count (${message})`,
        numWeeks
      )
      .afterClosed()
      .subscribe((res) => {
        let count = parseInt(res.returnValue) || 0;
        if (count < 1 || count > 52) {
          this.dialogService.message(
            "Week counts must be between 1 and 52 weeks",
            "Week count"
          );
        } else {
          if (schedule.planAcrossMarkets) schedule.numWeeks = count;
          else this.getCurrentScheduleMarket().numWeeks = count;

          this.calculateAllSchedules(true); // all schedules need recalculating
        }
      });
  }

  // generic / by station planning method change
  onPlanningMethodChange() {
    const planMethod =
      this.planningMethod.value == "generic"
        ? PlanningMethod.Generic
        : PlanningMethod.ByStation;
    const schedule = this.getCurrentSchedule();

    if (schedule.planAcrossMarkets) schedule.planningMethod = planMethod;
    else this.getCurrentScheduleMarket().planningMethod = planMethod;

    this.loadData(false, false);

    // // switching planning modes from 'bystation' to 'generic'
    // if( planMethod == PlanningMethod.Generic && schedule.planningMethod == PlanningMethod.ByStation ) {

    //   if ( !scheduleMarket.hasResults() ) {  // if there's no results then just swap and loadData

    //     scheduleMarket.planningMethod = planMethod;
    //     this.loadData( false, false );
    //     return;
    //   }

    //   this.dialogService.question("Switching to generic planning will redistribute all your spots evenly", "Generic planning").afterClosed().subscribe( ans => {

    //     if (ans.data != "OK" ) this.planningMethod.value = scheduleMarket.planningMethod // dont do it, switch back
    //     else {
    //       // redistribute and continue as generic
    //       scheduleMarket.planningMethod = planMethod;

    //       // need the ratings for each daypart in case a GRP reallocation is required
    //       const stationDaytpartRatings = this.marketsService.getDaypartAvgQtrHourRatingsList(
    //                                             this.marketsService.getCurrent(),
    //                                             this.getCurrentTarget(),
    //                                             this.campaignService.run.dayparts,
    //                                             scheduleMarket.stations );

    //       scheduleMarket.redistribute( scheduleMarket.stations, this.campaignService.run.dayparts, this.getCurrentTarget(), stationDaytpartRatings );
    //       this.calculate();
    //     }
    //   })
    // }

    // // switching planning modes from 'generic' to 'bystation'
    // if ( planMethod == PlanningMethod.ByStation && schedule.planningMethod == PlanningMethod.Generic ) {

    //   schedule.planningMethod = planMethod;
    //   this.loadData( false, false );
    // }
  }

  // dropdowns showing stations used by the current schedule (view)
  // return the schedule specific stations if using an optimised schedule
  getScheduleStations(): UISelection[] {
    const scheduleMarket = this.getCurrentScheduleMarket();
    if (!scheduleMarket) return [];

    if (scheduleMarket.planningStations.length == 0) {
      scheduleMarket.planningStations = scheduleMarket.stations.map((s) => {
        return { id: "" + s.id, name: s.name };
      });
      scheduleMarket.planningStations.unshift({
        id: 0,
        name: "** Total Stations **",
      });
    }

    return scheduleMarket.planningStations;
  }

  // planning on single week or all weeks at once
  onPlanningWeekPeriodChange() {
    // if total schedule then do as we do now, eg 100 spots in the totals
    // if input by single week then multiply all spots by weekcount, i.e 100 * 4 = 400 in totals

    const planPeriod =
      this.planningWeekPeriod.value == "allweeks"
        ? PlanningPeriod.TotalWeeks
        : PlanningPeriod.SingleWeek;
    const schedule = this.getCurrentSchedule();

    if (schedule.planAcrossMarkets) schedule.planningPeriod = planPeriod;
    else this.getCurrentScheduleMarket().planningPeriod = planPeriod;

    this.calculate();
  }

  // n stations change
  onNStationsChange() {
    const daypart = this.campaignService.run.dayparts[0]; // the first of the preferred dayparts
    const target = this.getCurrentTarget();
    const schedule = this.getCurrentSchedule();

    // get all the markets we need to work with (one or all)
    const marketFilenamesToProcess = schedule.planAcrossMarkets
      ? this.campaignService.run.markets.map((m) => m.marketFilename)
      : [this.getCurrentMarket().marketFilename];

    marketFilenamesToProcess.forEach((marketFilename) => {
      const mkt = this.marketsService.get(marketFilename);
      this.campaignService.selectTopNStations(
        this.nStationsSelected,
        mkt,
        target,
        daypart
      );

      const campaignMarket = this.campaignService.run.market(marketFilename); // where the resulting station list is kept
      const scheduleMarket = schedule.market(marketFilename);
      scheduleMarket.stations = Object.assign([], campaignMarket.stations);

      // need the ratings for each daypart in case a GRP reallocation is required
      const stationDaytpartRatings = this.marketsService.getDaypartAvgQtrHourRatingsList(
        mkt,
        target,
        this.campaignService.run.dayparts,
        scheduleMarket.stations
      );
      scheduleMarket.redistribute(
        scheduleMarket.stations,
        this.campaignService.run.dayparts,
        target,
        stationDaytpartRatings
      );
    });

    this.calculate();
  }

  // numweeks change
  onNumWeeksChange() {
    if (this.planAcrossMarkets)
      this.getCurrentSchedule().numWeeks = this.numWeekSelected;
    else this.getCurrentScheduleMarket().numWeeks = this.numWeekSelected;

    this.calculate();
  }

  calculateAllSchedules(updateUI: boolean = true): void {
    this.campaignService.run.schedules.forEach((schedule, index) => {
      let last = index == this.campaignService.run.schedules.length - 1;
      this.calculate(last && updateUI, schedule);
    });
  }

  // initiate a full R&F using the planning service with the current schedule and target
  calculate(updateUI: boolean = true, schedule: CampaignSchedule = null): void {
    this.campaignService.run.addDirty(CampaignDirty.planning);
    const sch = schedule || this.getCurrentSchedule();

    //sch.effectiveReachLevel = this.campaignService.run.effectiveReachLevel; // campaign level (but stored by schedule)
    this.processing = true;

    let reqs = [];
    this.campaignService.run.markets.forEach((market) => {
      reqs.push(
        this.planningService.processAndEvaluate(
          market,
          this.campaignService.run.targets,
          sch.market(market.marketFilename)
        )
      );
    });

    forkJoin(reqs).subscribe({
      next: (data: any) => {
        this.processing = false;
        if (updateUI) this.loadData(true, true);
      },
    });
  }

  getPlanningMethod(): string {
    return this.getCurrentSchedule()
      ? this.getCurrentSchedule().planningMethod
      : "";
  }

  getPlanningWeekPeriod(): string {
    const scheduleMarket = this.getCurrentScheduleMarket();
    if (!scheduleMarket) return "";
    return scheduleMarket.planningPeriod == PlanningPeriod.SingleWeek
      ? "singleweek"
      : "allweeks";

    //    if (!this.getCurrentSchedule()) return '';
    //    return (this.getCurrentSchedule().planningPeriod == PlanningPeriod.SingleWeek) ? "singleweek" : "allweeks";
  }

  // plan across markets UI switch
  planAcrossMarketsDisabled(): boolean {
    return this.campaignService.run.markets.length == 1;
  }

  onPlanAcrossMarketsChange(checked: boolean) {
    this.planAcrossMarkets = checked;
    const schedule = this.getCurrentSchedule();
    schedule.planAcrossMarkets = checked;
  }

  // station change, update daypart grid
  onStationChange(e: MatSelectChange) {
    this.loadData(false, false);
  }

  // target dropdown used
  onTargetChange(e: MatSelectChange) {
    this.loadData(true, true);
  }

  // market changed - re-draw everything
  onCurrentMarketChange(tabIndex: number) {
    this.currentMarket = tabIndex;
    this.loadData(true, false);
  }

  onNavigate(dest: string[], options?: any) {
    this.router.navigate(dest, options);
  }

  // loaddata function used by everything, uses current UI screen settings and calls the various child comps
  loadData(andTotals: boolean, reachChanged: boolean) {
    const schedule = this.getCurrentSchedule();
    const target = this.getCurrentTarget();
    const market = this.getCurrentMarket();
    const scheduleMarket = schedule.market(market);

    this.scheduleGrid.loadData(
      market.marketFilename,
      this.getCurrentStation(),
      target,
      schedule
    );
    this.populateCampaignSummary(target, schedule); // summary panel

    // station selector
    this.planningStations = market.stations.map((stn) => {
      return { id: "" + stn.id, name: stn.name };
    });
    this.currentStation = this.planningStations.length
      ? this.planningStations[0].id
      : null;

    this.planAcrossMarkets = schedule.planAcrossMarkets;
    this.planningMethod.value = scheduleMarket.planningMethod;
    this.nStationsSelected = scheduleMarket.stations.length;
    this.planningWeekPeriod.value = this.getPlanningWeekPeriod();
    this.numWeekSelected = scheduleMarket.numWeeks;
    this.optimisationMessages = this.hasOptimisation() ? scheduleMarket.optimisation.messages : [];

    if (andTotals) {
      //this.totalsGrid.loadData(market, target, schedule);
      this.totalsGridMultiMarket.loadData(target, schedule);
      this.totalsGraphing.loadData(market, target, schedule, reachChanged);
    }
  }

  exportTable(scheduleColumns: any[], scheduleData: any[]) {
    const market = this.getCurrentMarket();
    const target = this.getCurrentTarget();

    let csv = new CSVExporter();
    csv.addLine("Telmar Audio Schedule Report");
    csv.addLine(["Report Name", market.marketName]);

    csv.addLine([
      "Market",
      `${market.marketName} (${market.reportType} ${market.geography}, ${market.periodNameLong})`,
      "Market Population [00]",
      this.helperService.formatFloat(market.universe),
    ]);
    // "", "National Reach %", this.helperService.formatFloat( (target.universe / mkt.market.universe) * 100 )] );

    csv.addLine([
      "Target Audience",
      target.name,
      "Target Population [00]",
      this.helperService.formatFloat(target.universe),
    ]);
    //"", "National Target Population [00]", this.helperService.formatFloat( mkt.market.universe )] );

    csv.addLine([
      "Sweep",
      `${market.reportType}, ${market.geography}, ${market.periodNameLong}`,
    ]);

    const schedule = this.getCurrentSchedule();

    // schedule grid
    csv.addBlankLine(2);
    csv.addLine(`Plannning for '${schedule.name}'`);
    csv.addBlankLine();
    csv.addTable(scheduleColumns, scheduleData);

    // totals grid
    csv.addBlankLine(2);
    csv.addLine("Schedule Totals");
    csv.addBlankLine();
    //csv.addTable(this.totalsGrid.columns, this.totalsGrid.dataSource.data);
    csv.addTable(
      this.totalsGridMultiMarket.columns,
      this.totalsGridMultiMarket.dataSource.data
    );

    csv.addBlankLine();
    csv.addLine(`(c) Telmar ${new Date().getFullYear()}`);
    csv.addLine(
      `${market.copyright}, ${market.marketName}, ${market.periodNameLong}`
    );

    csv.saveAs( sanitize(`export_${schedule.name}.csv`) );
  }

  private campaignReady(): boolean {
    if (!this.campaignService.run.documentStarted) {
      this.router.navigate(["dashboard"]);
      return false;
    }

    if (!this.campaignService.run.targets.length) {
      this.router.navigate(["targets"]);
      return false;
    }

    return true;
  }
}
