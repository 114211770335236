import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TupAuthService } from '@telmar-global/tup-auth';
import { ApiService } from './api.service';
import { DialogService } from './dialog.service';
import { v1 as uuidv1 } from 'uuid';

export interface SearchElasticResponseModel {
  documents: any;
  responseTime: number;
}

export interface ElasticResponseModel {
  success: boolean;
  result: any;
  id: string;
  index: number;
  version: number;
}

//  https://aws.amazon.com/blogs/database/elasticsearch-tutorial-a-quick-start-guide/

@Injectable({
  providedIn: 'root'
})
export class ElasticService {

  constructor( private apiService: ApiService,
               private authService: TupAuthService,
               private dialogService: DialogService ) { }

    createOrUpdate( doc: any, endPoint: string, successMessage: string ): Observable< ElasticResponseModel > {
      return doc.id ? this.update( doc, endPoint, successMessage ): this.create( doc, endPoint, successMessage );
    }
  
    // create document so make an ID and use PUT
    private create( doc: any, endPoint: String, successMessage: string ): Observable< ElasticResponseModel > {
  
      doc.id = this.generateId(); 
      const url = `${environment.api.elastic.url}${endPoint}/_doc/${doc.id}`;
      return this.apiService.request("PUT", url, "", { body: doc }).map( data=> {
  
        this.dialogService.showSnackBar(`${data.result} ${successMessage}`, "ok");
        return {
          success: data.result == "created",
          result: data.result,
          id: data._id,
          index: data._index,
          version: data._version
        } 
      });
    }
  
    // update document so use existing id with a POST
    private update( doc: any, endPoint: string, successMessage: string ): Observable< ElasticResponseModel > {
  
      const url = `${environment.api.elastic.url}${endPoint}/_doc/${doc.id}`;
      return this.apiService.request("POST", url, "", { body: doc }).map( data => {
  
        this.dialogService.showSnackBar(`${data.result} ${successMessage}`, "ok");
        return {
          success: data.result == "updated",
          result: data.result,
          id: data._id,
          index: data._index,
          version: data._version
        }
      });
    }
  
    delete( id: string, endPoint: string, successMessage: string ): Observable< ElasticResponseModel > {
  
      const url = `${environment.api.elastic.url}${endPoint}/_doc/${id}`;
      return this.apiService.request("DELETE", url, "").map(data => {
  
        this.dialogService.showSnackBar(`${data.result} ${successMessage}`, "ok");
        return {
          success: data.result == "deleted",
          result: data.result,
          id: data._id,
          index: data._index,
          version: data._version
        }
      })
    }
  
    get( id: string, endPoint: string ): Observable< any > {
  
       const url = `${environment.api.elastic.url}${endPoint}/_doc/${id}`;
       return this.apiService.request("GET", url, "").map( data => {})
     }
  
    search( endPoint: string): Observable< SearchElasticResponseModel > {
  
      const url = `${environment.api.elastic.url}${endPoint}/_search`;
  
      let req = {
        query: {
        //match_all: {}  // return everything (replaces bool object)
          bool: {
            must: [
              { "match_phrase": { appName: environment.appName } },
              { "match_phrase": { "header.username": this.authService.user.username } },
            ]
          }
        },
        from: 0,
        size: 9999 // (<10000)
      }    
  
      return this.apiService.request("POST", url, "", { body: req } ).map( data=> {
  
        let docs = []
        if ( data && !data.timed_out && data.hits) {
  
          data.hits.hits.forEach( doc => {
            if (doc._source) {
              docs.push(doc._source);
              docs[docs.length-1].id = doc._id; // rewrite id so can be deleted using actual id in the UI
            }
          });
        }
  
        return {  documents: docs, responseTime: data.took || -1}
      });
    }

    health(): Observable<any> {
      const url = `${environment.api.elastic.url}${environment.api.elastic.endPoint.health}`;
      return this.apiService.request("GET", url, "");
    }

    private generateId(): string {

      const byteToHex = (i:number) => (i + 0x100).toString(16).substr(1);
  
      let uuid = [];
      let uuid_str = [];
      uuidv1(null, uuid, 0);
  
      for(let i = 0; i< uuid.length; i++) {
        uuid_str.push(byteToHex(uuid[i]));
      }
  
      return uuid_str.join('');
    }
  
  

}
