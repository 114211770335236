import { Station } from '../models/campaign.model';

export enum PlanningPeriod {
    SingleWeek,
    TotalWeeks,
}

 export enum PlanningMethod {
     Generic = 'generic',
     //Optimise = 'optimise',
     ByStation = 'bystation',
 }

export interface EntryType {
    field: string;
    value: number;
}

// class holding the plan itself of all stations and dayparts with their spots.
// may need to be extended to hold method of entry (Impacts / GRPs, etc) alomg with the original entry
export class CampaignSchedulePlan {
    plan: any = {}
    lastSpots: any = null;
    lastCosts: any = null;

    planningPeriod: PlanningPeriod;

    get count(): number {
        return Object.keys(this.plan).length;
    }

    constructor() {}

    addSpots( stationId: number, daypartId: number, spots: number, data: EntryType ):void {

        this.prepare(stationId, daypartId);
        this.plan[''+ stationId][''+ daypartId].spots = spots;
        this.plan[''+ stationId][''+ daypartId].spotEntry = data;

        this.lastSpots = this.plan[''+ stationId][''+ daypartId];
    }

    addCosts( stationId: number, daypartId: number, cost: number, data: EntryType ):void {

        this.prepare(stationId, daypartId);
        this.plan[''+ stationId][''+ daypartId].cost = cost;
        this.plan[''+ stationId][''+ daypartId].costEntry = data;

        this.lastCosts = this.plan[''+ stationId][''+ daypartId];
    }

    copyPlan(stations: Station[], copyPlan: any[]) {

        // take the plan passed in and distribute the GRPs and spots amoungst the stations also passed in


        this.clear();
    }

    getPlanByDayparts(): any[] {

        let stns = Object.keys(this.plan);
        let returnPlan = []

        // for each station
        stns.forEach( s=> {

            // for each daypart
            let dps = Object.keys(this.plan[s]);
            dps.forEach( d=> {

                if ( this.plan[s][d].spots ) {

                    let entry = returnPlan.find( p=> p.daypartId === parseInt(d));
                    if (!entry) {
                        entry = { daypartId: parseInt(d), spots: 0, grps: 0}
                        returnPlan.push(entry);
                    }

                    // sum spots (grps) by daypart
                    if (this.plan[s][d].spotEntry) {
                        entry[this.plan[s][d].spotEntry.field] += this.plan[s][d].spotEntry.value;

                    }
                }
            })
        })
        return returnPlan
    }

    getCosts(): any[] {

        let stns = Object.keys(this.plan);
        let lines = []

        // for each station
        stns.forEach( s=> {

            // for each daypart
            let dps = Object.keys(this.plan[s]);
            dps.forEach( d=> {

                if ( this.plan[s][d].spots ) {
                    lines.push({
                        stationId: s,
                        daypartId: d,
                        entryType: this.plan[s][d].costEntry
                    })
                }
            });
        });

        return lines;
    }

    private prepare(stationId: number, daypartId: number) {

        if ( !this.plan[''+ stationId] ) {
            this.plan[''+ stationId] = {}
            this.plan[''+ stationId][''+ daypartId] = {}
        }

        if (!this.plan[''+ stationId][''+ daypartId]) {
            this.plan[''+ stationId][''+ daypartId] = {}
        }
    }

    clear() {
        this.plan = {}
        this.lastSpots = null;
        this.lastCosts = null;
    }

    asJson( planningPeriod: PlanningPeriod, numWeeks: number ) {
        return this.plan;
    }

    // verify that all the stations previously used in the plan are still to be used now.. 
    // reduces the plan object using the station array supplied
    private validateStations(stations: Station[]) {

        const stationIds = stations.map( stn => ''+stn.id);
        let newPlan = []

        let stns = Object.keys(this.plan);
        stns.forEach(stn => {
            if ( stationIds.includes(stn) ) newPlan[stn] = this.plan[stn];
        })

        this.plan = newPlan;
    }

    // returns the plan as an array for the evaluation service
    asSchedulePlan(planningPeriod: PlanningPeriod, numWeeks: number, stations: Station[]): any[] {

        let plan = []
        this.validateStations( stations ); // verify only the stations to use are in the plan object

        let stns = Object.keys(this.plan);
        const divider = (planningPeriod == PlanningPeriod.TotalWeeks) ? numWeeks : 1;

        // for each station
        stns.forEach( s=> {

            // for each daypart
            let dps = Object.keys(this.plan[s]);
            dps.forEach( d=> {

                if ( this.plan[s][d].spots ) {
                    plan.push({
                        stationId: s,
                        daypartId: d,
                        spots: this.plan[s][d].spots / divider,
                    })
                }
            })
        })

        return plan;
    }

    // NOTE: cannot be used across markets as station lists wont match
    copyFrom( from: CampaignSchedulePlan) {
        this.plan = Object.assign([], from);
    }

    // using the supplied stations, distirbute spots evenly
    redistributeBySpots( newStations: Station[] ): void {

        // firstly get all the spots for each daypart
        let currentSpots = {};
        Object.keys(this.plan).forEach( s=> {  // station loop
            Object.keys(this.plan[s]).forEach( d=> { // daypart loop

                currentSpots[d] = currentSpots[d] || { spots: 0 }
                currentSpots[d].spots += this.plan[s][d].spots;
            })
        })

        // now recreate the plan object using the newStations array
        // for each daypart, divide the spots in that daypart between station count
        this.clear();
        newStations.forEach( stn => {
            Object.keys(currentSpots).forEach( daypart => {
                let spots = currentSpots[daypart].spots / newStations.length;
                this.addSpots(stn.id, parseInt(daypart), spots, currentSpots[daypart].data);
            })
        })
    }

    // using the supplied ratings, calculate the spots for even distributed GRPs accross the given stations
    redistributeByGRPs(stations: Station[], daypartGRPs: any[], ratings: any[]) {

        if (!stations.length) return;

        this.clear();
        let stationDaypart = null;
        let grps = 0;
        let spots = 0;

        daypartGRPs.forEach( daypart => {
            grps = daypart.grps / stations.length;

            stations.forEach( station => {

                stationDaypart = ratings.find( r=> r.stationId == station.id && r.daypartId == daypart.daypartId );
                spots = (stationDaypart && stationDaypart.ratings) ? ((grps / 100) / stationDaypart.ratings) : 0;
                
                this.addSpots( station.id, daypart.daypartId, spots, { field: "grps", value: grps } );
            })
        })
    }

}
