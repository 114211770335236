<div class="graph-container">

    <freq-dist style="margin: 0px 5px 0px 5px" [data]="distribution"></freq-dist>

    <div>
        <mat-form-field style="margin-bottom: 40px">
            <mat-label>Select View</mat-label>
            <mat-select [(ngModel)]="chartViewSelected"
                        (ngModelChange)="onChartViewChange()">
                <mat-option *ngFor="let view of chartViewOptions" [value]="view.field">
                {{view.name}} 
                </mat-option>
            </mat-select>
        </mat-form-field>

        <tup-chart type="bar" width="600px" [options]="chartOptions" [data]="chartData" ></tup-chart>
    </div>

    <tup-chart type="pie" width="600px" height="600px" [title]="pieChartTitle" [options]="pieChartOptions" [data]="pieChartData"></tup-chart>

</div>