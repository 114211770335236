import { Component, OnInit, Input } from '@angular/core';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { TupAuthService } from '@telmar-global/tup-auth';
import { CampaignSummaryComponent } from '../campaign-summary/campaign-summary.component';
import { HelperService } from '../../services/helper.service';
import { DialogService } from '../../services/dialog.service';
import { CampaignService } from '../../services/campaign.service';
import { DocumentService } from '../../services/document.service';
import { Router } from '@angular/router';
import { EventCategory, TupAnalyticsService } from '@telmar-global/tup-analytics';

 @Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],

  providers: [
    UserMenuComponent, 
    CampaignSummaryComponent
  ]
})
export class TopBarComponent implements OnInit {

  currentDocumentName: string = "";

  constructor(private campaignService: CampaignService,
              private documentService: DocumentService,
              private router: Router,
              private as: TupAnalyticsService,
              private dialogService: DialogService) { }

  ngOnInit() {
   
  }

  documentStarted(): boolean {
    return this.campaignService.run.documentStarted;
  }

  getDocumentName(){
    return this.campaignService.run.clientInfo.title;
  }

  onEditDocument(){
    this.dialogService.clientInfo(this.campaignService.run.clientInfo).afterClosed().subscribe( info => {

      if (info) {
        this.campaignService.run.clientInfo = Object.assign([], info);

        if ( this.campaignService.run.readyForPlanning() ) {

            this.documentService.createOrUpdateDocument( this.campaignService.getCampaignDocument() ).subscribe( data=> {
              this.campaignService.run.documentId = data.id;
            })
        }
      }
    })
  }

  onSaveDocument() {

    this.documentService.createOrUpdateDocument( this.campaignService.getCampaignDocument() ).subscribe( res => {
      if (res.success) {
        this.campaignService.run.documentId = res.id;
        this.campaignService.run.removeDirty();
      }
    })
  }

  onNavigate( dest: string[] ) {
    this.router.navigate(dest);
  }


}
