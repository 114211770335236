import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { TupAuthService, TupApiServiceBase  } from '@telmar-global/tup-auth';
import { UserMessageService } from './user-message.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends TupApiServiceBase {

  queue: any[] = [];

  constructor( http: HttpClient, private userMessage: UserMessageService) { 
    super (http, environment );
  }

  request(method: string, rootUrl: string, endPoint: string, options? :any): Observable<any> {

    let url = rootUrl + endPoint;
    console.log("starting request: " + url);

    return super.request( method, url, options)
      .pipe( this.handleHttpErrorResponse(method, url, options) );
  }

  handleHttpErrorResponse (method: string, url: string, options?: any) {
    return pipe(
  
      catchError((error: HttpErrorResponse) => {
  
        if (error.error instanceof ErrorEvent) {
    
          // A client-side or network error occurred. Handle it accordingly.
          console.error(`An error occurred: "${error.error.message}"`);
  
        } else { 
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          if( error.status === 401 ) {
            // Unauthorized
            console.error(`An error occurred: "${error.error.message}"`);
          }

        // log developer-friendly error message
        console.error(`There was an error communicating with app server: "${error}" Triggered while trying to reach: ${url} with ${method}. HttpClient options: ${JSON.stringify(options)}`);
        this.userMessage.message( error.error, "API Serverside Error");
      }

        // return an observable with a user-friendly error message
        return throwError('There was an error trying to talk to the application server, please try again later.');

      }) // catchError
    ) //pipe
  }

  addToQueue(endPoint: string, data: any): number {
    let ms = Date.now();
    this.queue.push({
      endPoint: endPoint,
      startTime: ms,
      data: data,
    })
    return ms;
  }

  removeFromQueue(id: number): number {
    let ms = Date.now();
    let index = this.queue.findIndex( q=> q.startTime === id);

    if (index != -1){
      ms = ms - this.queue[index].startTime;
      this.queue.splice(index, 1);
    }
    return ms;
  }

}
