import { CampaignScheduleResults, CampaignScheduleResultsPeriod, Daypart, OptimisationCriteria, ResultLine, Station, Target } from "../models/campaign.model";
import { CampaignSchedulePlan, PlanningMethod, PlanningPeriod } from "./campaign-schedule-plan";

// a schedule has multiple markets.
export class CampaignMarketSchedule {

    marketFilename: string;  // one of these is created for each market in the campaign
    plan: CampaignSchedulePlan;
    results: CampaignScheduleResults[]; // array as results are by target

    optimisation: OptimisationCriteria; // settings last time optimisation was used.  Can by null
    stations: Station[];  // each optimised schedule holds its own list of stations 
    planningStations: any[]; // used to display station list on the planning screen to prevent an infanite loop building on the fly

    numWeeks: number;
    effectiveReachLevel: number;
    planningPeriod: PlanningPeriod; // entries are for a single week or total weeks  SingleWeek, TotalWeeks
    planningMethod: PlanningMethod; //string;  // ('generic', 'bystation', 'optimise')

    clear() {
        this.plan.clear();
        this.results = [];
        this.stations = [];
        this.planningStations = [];
        this.optimisation = null;
    }

    clearResults() {
        this.results = []
    }

    hasOptimisation(): boolean {
        return !! this.optimisation;
    }

    hasResults():boolean {
        return !!(this.results && this.results.length);
    }

    deleteTarget(target: Target) {
        const index = this.results ? this.results.findIndex( res => res.target.coding == target.coding) : -1;
        if (index != -1) this.results.splice(index, 1);
    }

    // will return either the single week results or the total results based on the PlanningPeriod value
    getScheduleLines(station: Station, daypart: Daypart, target: Target, row?: any ): ResultLine {

        // find the results for the correct target
        let result = this.results ? this.results.find( res => res.target.coding == target.coding) : null;

        let resultPeriod: CampaignScheduleResultsPeriod = null;
        if (result) resultPeriod = (this.planningPeriod == PlanningPeriod.TotalWeeks) ? result.total : result.weekly;

        let res;
        if (!station) { // no station so use the daypart WEEKLY results
            res = result ? resultPeriod.dayparts.find( dp => dp.daypartId == daypart.id) : null;
        }
        else {  // search by station and daypart
            res = result ? resultPeriod.stationDayparts.find( stndp => { return stndp.daypartId == daypart.id && stndp.stationId == station.id }) : null;
        }

        const universe = (result && result.target) ? result.target.universe : 0;
        res = res || { impacts: 0, reach: 0, effectiveReach: 0, spots: 0, cost: 0, costRchPnt: 0, stationId: -1, daypartId: -1 };
        res.cost = res.cost || 0;  //undefined if a document load before costs were added

        row = row || {}
        row.daypartId = daypart.id;
        row.title = daypart.description;
        row.spots = res.spots;
        row.reach00 = res.reach;
        row.reachPct = universe ? (res.reach / universe) * 100 : 0;
        row.effReach00 = res.effectiveReach;
        row.effReachPct = universe ? (res.effectiveReach / universe) * 100 : 0;
        row.impacts = res.impacts;
        row.grps = universe ? (res.impacts / universe) * 100 : 0;
        row.avgFreq = res.reach ? res.impacts / res.reach : 0;
        row.cpp = row.grps ? res.cost / (row.grps) : 0; // [00]  
        row.cpm = row.impacts ? (res.cost / row.impacts) * 10 : 0;  // [00] 
        row.cps = row.spots ? res.cost / row.spots : 0;
        row.totalCost = res.cost;
        row.costRchPnt = row.reachPct ? row.totalCost / row.reachPct : 0;
        row.universe = universe;
        
        return row;
    }

    getScheduleTotals(target: Target, station?: Station): ResultLine {

        let result = this.results ? this.results.find( res => res.target.coding == target.coding) : null; // Results for the target
        if (!result) return this.getEmptyResultLine( station? `${station.callLetters} (${station.frequency})` : "" )

        let rch = result.total.totalReach;
        let effRch = result.total.totalEffectiveReach;
        let imps = result.total.totalImpacts;
        let spots = result.total.totalSpots;
        let cost = result.total.totalCost;
        const universe = result.target.universe;

        // station was specified so try find it else return 0's
        if ( station ) {
            const stn = result.total.stations.find( stn => stn.stationId == station.id );
            if (!stn) return this.getEmptyResultLine( station? `${station.callLetters} (${station.frequency})` : "" )
            
            rch = stn.reach;
            effRch = stn.effectiveReach;
            imps = stn.impacts;
            spots = stn.spots;
            cost = stn.cost || 0;
        }

        const res: ResultLine = {
            title: station? `${station.callLetters} (${station.frequency})` : "Total",
            spots: spots,
            reach00: rch,
            effReach00: effRch,
            reachPct: universe ? (rch / universe) * 100 : 0,
            effReachPct: universe ? (effRch / universe) * 100 : 0,
            impacts: imps,
            grps: universe ? (imps / universe) * 100 : 0,
            avgFreq: rch ? imps / rch : 0,
            cpm: 0, cpp: 0, cps: 0, totalCost: 0, costRchPnt: 0,
            universe: universe
        }
        res.cpm = res.impacts ? ( cost / res.impacts) * 10 : 0; 
        res.cpp = res.grps ? cost / res.grps : 0; 
        res.cps = res.spots ? cost / res.spots : 0;
        res.totalCost = cost;
        res.costRchPnt = res.reachPct ? cost / res.reachPct : 0;
        
        return res;
    }

    getEmptyResults(): CampaignScheduleResults {
        return {
            target: null,
            total: { stationDayparts: [], dayparts: [], totalReach: 0, totalEffectiveReach: 0, totalImpacts: 0, totalSpots: 0, totalCost: 0, stations: [] },
            weekly: { stationDayparts: [], dayparts: [], totalReach: 0, totalEffectiveReach : 0, totalImpacts: 0, totalSpots: 0, totalCost: 0, stations: [] }
        }
    }

    getEmptyResultLine(title?: string): ResultLine {
        return { title: title || "", reach00: 0, reachPct: 0, effReach00: 0, effReachPct: 0, impacts: 0,
            grps: 0, avgFreq: 0, spots: 0, totalCost: 0, cpp: 0, cpm: 0, cps: 0, costRchPnt: 0,
            universe: 0, 
        }
    }

    initialiseOptimisation( copyFrom: OptimisationCriteria = null): OptimisationCriteria {

        if (copyFrom) {
            this.optimisation = {
                rankStationBy: copyFrom.rankStationBy,
                stationBuyingGoal: copyFrom.stationBuyingGoal,
                stationBuyingGoalValue: copyFrom.stationBuyingGoalValue,
                marketGoal: copyFrom.marketGoal,
                marketGoalValue: copyFrom.marketGoalValue,
                goalCombination: copyFrom.goalCombination, 
                marketGoal2: copyFrom.marketGoal2,
                marketGoalValue2: copyFrom.marketGoalValue2,
                daypartCosts: copyFrom.daypartCosts,
                numWeeks: copyFrom.numWeeks,
                messages: copyFrom.messages,
                warning: copyFrom.warning,
                error: copyFrom.error
            }
        }
        else {
            this.optimisation = {
                rankStationBy: '',
                stationBuyingGoal: '',
                stationBuyingGoalValue: 0,
                marketGoal: '',
                marketGoalValue: 0,
                goalCombination: '',
                marketGoal2: '',
                marketGoalValue2: 0,
                numWeeks: 0,
                daypartCosts: [],
                messages: [],
                warning: '',
                error: '',
            }
        }
        return this.optimisation;
    }

    buildPlanFrom(currentPlan: any[], stations: Station[], stationDaytpartRatings: any[] ){

        //currentPlan[]: {daypartId: 64, grps: 0, spots: 0}
        //stationDaytpartRatings[]: {stationId: 14446, daypartId: 64, ratings: 0.024514588859416445}

        this.plan.clear();

        currentPlan.forEach( item => {

            // add in straight spots
            if (item.spots) {
                stations.forEach( station => {
                    this.plan.addSpots( station.id, item.daypartId, item.spots, { field: 'spots', value: item.spots });
                })
            }

            // turn grps into spots and add
            if (item.grps) {
                const grps = item.grps / stations.length;
                stations.forEach( station => {
                    const stnRatings = stationDaytpartRatings.find( rat=> rat.daypartId === item.daypartId && rat.stationId === station.id)
                    const spots = stnRatings.ratings ? ((grps / 100)  / stnRatings.ratings) : 0;
                    this.plan.addSpots( station.id, item.daypartId, spots, { field: 'grps', value: grps });
                })
            }

        })
    }


    redistribute(stations: Station[], dayparts: Daypart[], target: Target, ratings: any[]): void { 

        const field = this.plan.lastSpots ? this.plan.lastSpots.spotEntry.field : "spots";

        if (field =="spots") this.redistributeBySpots( stations )
            else this.redistributeByGRPs( stations, dayparts, target, ratings);
    }

    private redistributeBySpots(stations: Station[]): void {
        this.plan.redistributeBySpots( stations );
    }

    private redistributeByGRPs(stations: Station[], dayparts: Daypart[], target: Target, ratings: any[]): void { 

        // get a list of all the dayparts in the plan
        // call getScheduleLines for each one to get all their GRPs
        // turn each GRP value into spots using the passed in ratings

        let daypartGRPs = []
        dayparts.forEach( daypart => {

            const vals = this.getScheduleLines( null, daypart, target );
            daypartGRPs.push({ daypartId: daypart.id, grps: vals.grps, spots: vals.spots });
        })

        this.plan.redistributeByGRPs(stations, daypartGRPs, ratings)
    }

}
