import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'number-widget',
  template: '<p>{{title}}</p><h1>{{value}}</h1>',
  styles: [
	':host { margin: 14px;}',
	':host p {font-size: 12px;margin: 0;text-align: center; }',
	':host h1 {font-weight: 400; margin: 0;margin-top:4px;color: #777;text-align: center; }',
	':host.small { font-size: 0.5em;margin: 2px; }'
	]
})
export class NumberWidgetComponent implements OnInit {
  @Input() title: string = "Title";
  @Input() value: string = "Value";

  constructor() { }

  ngOnInit(): void {
  }

}
