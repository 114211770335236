import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MarketsService } from '../../../shared/services/markets.service';
import { CampaignService } from '../../../shared/services/campaign.service';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PlanningService } from 'src/app/shared/services/planning.service';
import { Router } from '@angular/router';
import { Market, Station, Target } from 'src/app/shared/models/campaign.model';
import { CampaignSchedule } from 'src/app/shared/classes/campaign-schedule';
import { PlanningPeriod } from 'src/app/shared/classes/campaign-schedule-plan';
import { CampaignDirty } from 'src/app/shared/classes/radio-campaign';
import { CampaignMarketSchedule } from 'src/app/shared/classes/campaign-market-schedule';
import { MarketMetrics } from 'src/app/shared/models/market-information.model';
import { forkJoin, observable, Observable } from 'rxjs';
import { HelperService } from 'src/app/shared/services/helper.service';

// column definitions 
export interface PlanningColumn {
  columnType: string;
  columnDef: string;
  header: string;
  css: string;
  format: string;
  cell(x: any): any
}

export interface ScheduleChangedEvent {
  market: Market;
  target: Target;
  schedule: CampaignSchedule;
  station: Station;
  daypartId: number;
  reachChanged: boolean;
  costsChanged: boolean;
}

export interface ScheduleExportEvent {
  fileType: string;
  columns: any[];
  data: any[]
}

export interface RecalcTask {
  reach: boolean;
  costs: boolean;
}

@Component({
  selector: 'schedules-grid',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Output() tableChange: EventEmitter<ScheduleChangedEvent> = new EventEmitter<ScheduleChangedEvent>();
  @Output() calculating: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() export: EventEmitter<ScheduleExportEvent> = new EventEmitter<ScheduleExportEvent>();

  private floatFmt = this.campaignService.run.prefs.user.UI.floatFormat;
  private wholeFmt = this.campaignService.run.prefs.user.UI.wholeFormat;
  private ratingsFmt = this.campaignService.run.prefs.user.UI.ratingsFormat;
  private costFmt = this.campaignService.run.prefs.user.UI.costFormat;

  private editDirty: boolean = false;

  // processing property
  private _processing: boolean = false;
  get processing(): boolean {
    return this._processing;
  }

  set processing(value: boolean) {
    this._processing = value;
    this.calculating.emit(value);
  }
  
  columns: PlanningColumn[] = [
    { columnDef: 'title', columnType: 'string', header: 'Daypart', css: '', format: '', cell: (row: any) => `${row.title}` },
    { columnDef: 'avgRating', columnType: 'value', header: 'Avg.Qtr.Hr Ratings', css: '', format: this.ratingsFmt, cell: (row: any) => `${row.avgRating}` },
    { columnDef: 'spots', columnType: 'editable', header: 'Spots', css: '', format: this.wholeFmt, cell: (row: any) => `${row.spots}` },
    { columnDef: 'grps', columnType: 'editable', header: 'GRPs', css: '', format: this.floatFmt, cell: (row: any) => `${row.grps}` },
    { columnDef: 'reachPct', columnType: 'value', header: 'Reach %', css: '', format: this.floatFmt, cell: (row: any) => `${row.reachPct}` },
    { columnDef: 'avgFreq', columnType: 'value', header: 'Frequency', css: '', format: this.floatFmt, cell: (row: any) => `${row.avgFreq}` },
    { columnDef: 'reach00', columnType: 'value', header: 'Net Reach [00]', css: '', format: this.wholeFmt, cell: (row: any) => `${row.reach00}` },
    { columnDef: 'impacts', columnType: 'value', header: 'Impressions [00]', css: '', format: this.wholeFmt, cell: (row: any) => `${row.impacts}` },
    { columnDef: 'effReachPct', columnType: 'value', header: 'Eff Rch %', css: '', format: this.floatFmt, cell: (row: any) => `${row.effReachPct}` },

    { columnDef: 'cps', columnType: 'editable', header: 'CPS', css: '', format: this.costFmt, cell: (row: any) => `${row.cps}` },
    { columnDef: 'cpp', columnType: 'editable', header: 'CPP $', css: '', format: this.costFmt, cell: (row: any) => `${row.cpp}` },
    { columnDef: 'cpm', columnType: 'editable', header: 'CPM $', css: '', format: this.costFmt, cell: (row: any) => `${row.cpm}` },
    { columnDef: 'totalCost', columnType: 'value', header: 'Cost $', css: '', format: this.costFmt, cell: (row: any) => `${row.totalCost}` },
    { columnDef: 'costRchPnt', columnType: 'value', header: 'Cost Per Rch Pnt', css: '', format: this.costFmt, cell: (row: any) => `${row.costRchPnt}` },
    { columnDef: 'menu', columnType: 'string', header: '', css: 'column-options', format: '', cell: (row: any) => `` },
  ]

  displayedColumns = this.columns.map( c => c.columnDef );
  dataSource: MatTableDataSource< any >;
  planningDayparts: any[];
  planningStations: Station[];
  selectedStations: Station[];

  currentStation: Station;
  currentTarget: Target;
  currentMarket: Market;
  currentSchedule: CampaignSchedule;
  currentScheduleMarket: CampaignMarketSchedule;

  // scheduleTabs: PlanningOption[];
  // currentSchedule: number = 0;
 
  constructor ( private marketsService: MarketsService,
                private campaignService: CampaignService,
                private planningService: PlanningService,
                private helperService: HelperService,
                private router: Router ) { }

  ngOnInit() {

    let mkt = this.marketsService.getFirst();
    if (!mkt) this.router.navigate(['dashboard']);

    this.initScreenControls();
  }

  // loadData called from planning component when viewing data is changed
  loadData(marketFilename: string, station: Station, target: Target, schedule: CampaignSchedule ) {

    this.currentSchedule = schedule;
    this.currentStation = station;
    this.currentScheduleMarket = schedule.market(marketFilename);
    this.currentMarket = this.campaignService.run.market(marketFilename);
    this.currentTarget = this.planningService.prepareTargetWithUniverse(this.currentMarket, target); // copys the target and sets the universe accordingly

    let mkt = this.marketsService.markets.find( m=> m.market.marketFilename === marketFilename);
    //const market = this.campaignService.run.market(mkt.market.marketFilename);

    this.planningStations = [ ...this.currentScheduleMarket.stations] // selected stations

    // build planning dayparts from the campaign subset
    this.planningDayparts = [];

    this.campaignService.run.dayparts.forEach( dp => {
      this.planningDayparts.push( this.currentScheduleMarket.getScheduleLines(station, dp, this.currentTarget ) )  // null station = totals for daypart

      // qtr hr ratings based on selected stations (as decimal)
      let avgRating = this.marketsService.getDaypartAvgQtrHourRatings(mkt, dp, this.currentTarget, station ? [station] : this.planningStations );
      if (!station) avgRating = avgRating / this.planningStations.length; //average if multiple stations

      this.planningDayparts[ this.planningDayparts.length -1 ].avgRating = avgRating * 100;
    });
    
    this.dataSource = new MatTableDataSource<any>(this.planningDayparts); 
    this.dataSource.sort = this.sort;
  }

  // edit made on the grid.  Determine what was edited and call ProcessInput to provide R&F
  onSaveInput(column: string, value: string, row: any) {

    if ( !this.editDirty ) return; // if an edit wasnt made (focus then blur) do nothing
    this.editDirty = false;

    // get the markets we are to work with (planning across all markets or just the current one)
    const campaignMarkets = (this.currentSchedule.planAcrossMarkets) ? this.campaignService.run.markets : [this.currentMarket];

    // and the relevant MarketMetrics[]
    const mkts: MarketMetrics[] = campaignMarkets.map( market=> this.marketsService.get(market.marketFilename))

    let reqs = []
    this.processing = true;
    const target = this.currentTarget;

    // start all processing (then R&F) together
    mkts.forEach( mkt => {

      let scheduleMarket = this.currentSchedule.market(mkt.market.marketFilename);
      reqs.push(this.processInput(mkt, scheduleMarket, target, column, value, row ));
    })

    // wait for all to finish then emit back results
    forkJoin(reqs).subscribe({ next: (data: any[])=> {
      this.processing = false;

      // now all markets R&F are back, find the result for the currently displayed market so the grid row can be built with new values
      const ourMarket = data.find( dat=> dat.marketFilename === this.currentMarket.marketFilename );
      this.helperService.shallowCopy(ourMarket.row, row);

      this.emit( ourMarket.recalc, row);
    }})
  }

  processInput(mkt: MarketMetrics, scheduleMarket: CampaignMarketSchedule, planningTarget: Target, column: string, value: string, row: any): Observable<any> {

    return new Observable( observable => {

      const singleStation = this.currentStation;
      const stations = singleStation ? [singleStation] : scheduleMarket.stations; // single station planning.  note single station is mkt specific only

      const daypart = this.campaignService.run.dayparts.find(dp => dp.id == row.daypartId); // daypart being edited here (need to expand for multi dp's)
      const target = this.planningService.prepareTargetWithUniverse(mkt.market, planningTarget);

      let divider: number = 0;
      const usableRatings: number[] = [];

      // working with cost inputs so dont divide out by stations,
      // else decide by how much to divide by, by checking for zero ratings amoung daypart=>station combinations
      if (['spots', 'grps', 'impacts'].indexOf(column) == -1 ) divider = 1; 
      else {

        // get stations that can be included by checking their ratings against the daypart
        stations.forEach( stn => { 
          usableRatings.push(this.marketsService.getDaypartAvgQtrHourRatings( mkt, daypart, target, [stn] ));
        })
        divider = usableRatings.filter( r=> r > 0).length; // stations.length;
        
      }

      const val = parseFloat(value) / (divider || 1);
  
      let rowSpots: number = 0;
      let plan = scheduleMarket.plan;
  
      // track what needs recalculating
      let recalc: RecalcTask = {
        reach: false,
        costs: false
      }
  
      // could be a single station or all or specific list from optimiser
      stations.forEach( (stn, index) => {
       
        //spots
        if (column === "spots") {
          plan.addSpots( stn.id, daypart.id, val, { field: column, value: val } );
          rowSpots += val;
          recalc.reach = true;
        }
  
        // grps
        if (column === "grps") {
          const ratings = usableRatings[index]; // this.marketsService.getDaypartAvgQtrHourRatings( mkt, daypart, target, [stn] );
          const spots = ratings ? ((val / 100)  / ratings) : 0;
          plan.addSpots( stn.id, daypart.id, spots, { field: column, value: val } );
          rowSpots += spots;
          recalc.reach = true;
        }
  
        // impacts
        if (column === "impacts") {
          const ratings = usableRatings[index]; // this.marketsService.getDaypartAvgQtrHourRatings( mkt, daypart, target, [stn] );
          const spots = ratings ? ((val / target.universe) / ratings) : 0;
          plan.addSpots( stn.id, daypart.id, spots, { field: column, value: val } );
          rowSpots += spots;
          recalc.reach = true;
        }
  
        // CPP Cost per point
        if (column === "cpp") {
          const line = scheduleMarket.getScheduleLines(stn, daypart, target);
          const cost = (val * (line.grps));
          plan.addCosts( stn.id, daypart.id, cost, { field: column, value: val } );
          recalc.costs = true;
        }
  
        // CPS Cost per spot
        if (column === "cps") {
          const line = scheduleMarket.getScheduleLines(stn, daypart, target);
          const cost = val * line.spots;
          plan.addCosts( stn.id, daypart.id, cost, { field: column, value: val } );
          recalc.costs = true;
        }
  
        // CPM Cost per thousand (milli)
        if (column === "cpm") {
          const line = scheduleMarket.getScheduleLines(stn, daypart, target);
          const cost = (val * line.impacts) / 10 ;
          plan.addCosts( stn.id, daypart.id, cost, { field: column, value: val } );
          recalc.costs = true;
        }
       
      })
  
      // RF to do..Build ScheduleEvaluation object, call engine service and get it back with results populated
      if (recalc.reach) {
        row.spots = rowSpots;
        this.campaignService.run.addDirty( CampaignDirty.planning );
                                                          // this.planningStations
        this.planningService.processAndEvaluate(mkt.market, this.campaignService.run.targets, scheduleMarket ).subscribe( data => {
    
            // get newly calculated values for the current row
            const returnRow = scheduleMarket.getScheduleLines(singleStation, daypart, target);
            observable.next({ recalc: recalc, row: returnRow, marketFilename: mkt.market.marketFilename});
            observable.complete();
        });
      }
  
      // Costs to recalc
      if (recalc.costs) {
        this.campaignService.run.addDirty( CampaignDirty.planning );
        this.planningService.evaluateCosts( this.campaignService.run.targets, scheduleMarket );
  
        // get newly calculated values for the current row
        const returnRow = scheduleMarket.getScheduleLines(singleStation, daypart, target);
        observable.next({ recalc: recalc, row: returnRow, marketFilename: mkt.market.marketFilename});
        observable.complete();
      }
  
    })
  }

  applyFilter(filterValue: string) {
  }

  onExportTable() {

    const sortedData = this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    this.export.emit( { fileType: "csv", columns: this.columns, data: sortedData });
  }  
  
  // route click from the view
  onClickNavigate(route: string[]) {
    this.router.navigate(route);
  }

  // called by the view
  getCurrentGridView(): string {

    const view: string[] = [
      this.currentMarket ? this.currentMarket.marketName : "",
      this.currentStation ? this.currentStation.callLetters : "All stations",
      this.currentTarget ? this.currentTarget.name : "",
      this.currentSchedule.planningPeriod == PlanningPeriod.SingleWeek ? "Single Week" : "Total Campaign"
    ]

    return view.join(", ")
  }

  onMarketChange(e: MatSelectChange) {
  }

  initScreenControls() {
    this.currentSchedule =  this.campaignService.run.schedules.length ? this.campaignService.run.schedules[0] : null;
  }

  emit(recalc: RecalcTask, row?: any) {

    let event: ScheduleChangedEvent = {
      market: this.currentMarket,
      station: this.currentStation, // if null then working with N stations
      schedule: this.currentSchedule,
      target: this.currentTarget,
      daypartId: row ? row.daypartId : 0,
      reachChanged: recalc.reach,
      costsChanged: recalc.costs
    }

    this.tableChange.emit(event);
  }

}
