import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MarketsService } from '../../../shared/services/markets.service';
import { CampaignService } from '../../../shared/services/campaign.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Market, Target } from 'src/app/shared/models/campaign.model';
import { CampaignSchedule } from 'src/app/shared/classes/campaign-schedule';

// column definitions 
export interface PlanningColumn {
  columnType: string;
  columnDef: string;
  header: string;
  format: string;
  css: string;
  cell(x: any): any
}

export interface PlanningOption {
  id: string;
  name: string;
}

@Component({
  selector: 'totals-grid',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.scss']
})
export class TotalsComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  private floatFmt = this.campaignService.run.prefs.user.UI.floatFormat;
  private wholeFmt = this.campaignService.run.prefs.user.UI.wholeFormat;
  private costFmt = this.campaignService.run.prefs.user.UI.costFormat;

  columns: PlanningColumn[] = [
    { columnDef: 'title', columnType: 'string', header: 'Station', css: '', format: '', cell: (row: any) => `${row.title}` },
    { columnDef: 'spots', columnType: 'value', header: 'Spots', css: '', format: this.wholeFmt, cell: (row: any) => `${row.spots}` },
    { columnDef: 'grps', columnType: 'value', header: 'GRPs', css: '', format: this.floatFmt, cell: (row: any) => `${row.grps}` },
    { columnDef: 'reachPct', columnType: 'value', header: 'Reach %', css: '', format: this.floatFmt, cell: (row: any) => `${row.reachPct}` },
    { columnDef: 'avgFreq', columnType: 'value', header: 'Frequency', css: '', format: this.floatFmt, cell: (row: any) => `${row.avgFreq}` },
    { columnDef: 'reach00', columnType: 'value', header: 'Net Reach [00]', css: '', format: this.wholeFmt, cell: (row: any) => `${row.reach00}` },
    { columnDef: 'impacts', columnType: 'value', header: 'Impressions [00]', css: '', format: this.wholeFmt, cell: (row: any) => `${row.impacts}` },
    { columnDef: 'effReachPct', columnType: 'value', header: 'Eff Rch %', css: '', format: this.floatFmt, cell: (row: any) => `${row.effReachPct}` },

    { columnDef: 'cps', columnType: 'value', header: 'CPS', css: '', format: this.costFmt, cell: (row: any) => `${row.cps}` },
    { columnDef: 'cpp', columnType: 'value', header: 'CPP $', css: '', format: this.costFmt, cell: (row: any) => `${row.cpp}` },
    { columnDef: 'cpm', columnType: 'value', header: 'CPM $', css: '', format: this.costFmt, cell: (row: any) => `${row.cpm}` },
    { columnDef: 'totalCost', columnType: 'value', header: 'Cost $', css: '', format: this.costFmt, cell: (row: any) => `${row.totalCost}` },
    { columnDef: 'costRchPnt', columnType: 'value', header: 'Cost Per Rch Pnt', css: '', format: this.costFmt, cell: (row: any) => `${row.costRchPnt}` },
  ]

  displayedColumns = this.columns.map(c => c.columnDef);
  dataSource: MatTableDataSource<any>;
  stationTotals: any[];
 
  constructor (private marketsService: MarketsService,
              private campaignService: CampaignService,
              private router: Router) { }

  ngOnInit() {

    if (!this.marketsService.getFirst()){
       this.router.navigate(['dashboard']);
       return;
    }
    this.loadData(this.campaignService.run.markets[0], this.campaignService.run.targets[0], this.campaignService.run.schedules[0] );
  }

  applyFilter(filterValue: string) {
  }

  loadData(market: Market, target: Target, schedule: CampaignSchedule) {

    if (!schedule) return;
    const scheduleMarket = schedule.market(market);

    // use stations from the optimisation or from the camp service (experimental)
    this.stationTotals = [ 
      scheduleMarket.getScheduleTotals(target) 
    ];
    this.stationTotals[0].title = "Campaign Total";
    this.stationTotals[0].isTitle = true;  // title highlighted on view

    scheduleMarket.stations.forEach( stn => {
      this.stationTotals.push( scheduleMarket.getScheduleTotals(target, stn) );
      this.stationTotals[this.stationTotals.length - 1].title = `${stn.name}`;
    });

    if (!this.dataSource) this.dataSource = new MatTableDataSource<any>([]); 

    this.dataSource.data = this.stationTotals; 
    this.dataSource.sort = this.sort;
  }


}
