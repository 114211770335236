  
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort dense >
  
  <!-- Generic column definition -->
  <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
    <mat-header-cell [ngClass]="column.css" mat-sort-header disableClear *cdkHeaderCellDef>{{ column.header }} </mat-header-cell>

    <!-- string Column -->
    <div *ngIf="column.columnType=='string'">
      <mat-cell [ngClass]="column.css" *cdkCellDef="let row;">{{ column.cell(row) }}</mat-cell>
    </div>
  
    <!-- value Column -->
    <div *ngIf="column.columnType=='value'">
      <mat-cell [ngClass]="column.css" *cdkCellDef="let row;">{{ column.cell(row) | number: column.format }}</mat-cell>
    </div>
    
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; let rowIndex = index; columns: displayedColumns;" [ngClass]="{'highlight-total': row.isTitle }"></mat-row>
  
  </mat-table>
  </div>
  