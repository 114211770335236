import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTerms'
})
export class GlossaryFilterPipe implements PipeTransform {

  transform( items: any[], searchText: string ): any[] {

    if( ! items ) return [];
    if( ! searchText ) return items;

    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return (
        it['API Name'].toLowerCase().includes(searchText) ||
        it['Data Label'].toLowerCase().includes(searchText) ||
        it['Definitions'].toLowerCase().includes(searchText)
      );
    });
  }
}
