<client-area areatitle="Change Password" subtitle="Change your current password" icon="vpn_key">

<form fxFlex class="login-form" fxLayout="column">
	<mat-form-field>
		<input matInput placeholder="Current Password" id="oldPassword" type="password" class="form-control"
			[(ngModel)]="oldPassword" [ngModelOptions]="{standalone: true}" required/>
	</mat-form-field>
	<mat-form-field>
		<input matInput  placeholder="New Password" id="newPassword" type="password" minlength="6" maxlength="25" class="form-control"
			[(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}" required>
	</mat-form-field>
	<button (click)="onChangePassword()" id="signupSubmit" type="button" mat-raised-button
		color="primary">Change Password </button>
	<span class="passwordReq">*Password must contain at least 6 characters including 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character</span>
</form>
