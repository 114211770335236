<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{title}}
</h1>

<form [formGroup]="clientInfoGroup" (ngSubmit)="onSubmit()" class="form">

  <div mat-dialog-content class="flex-container">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="title">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Client" formControlName="client">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Author</mat-label>
        <input matInput placeholder="Author" formControlName="author">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Brand</mat-label>
        <input matInput placeholder="Brand" formControlName="brand">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Project Number</mat-label>
        <input matInput placeholder="Project Number" formControlName="projectNumber">
      </mat-form-field>

      <mat-form-field >
        <mat-label>Version</mat-label>
        <input matInput placeholder="Version" formControlName="version">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5" formControlName="notes"></textarea>
      </mat-form-field>

  </div>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit" class="button">Ok</button>
    <button (click)="onCancel()" mat-raised-button color="primary" type="button" class="button">Cancel</button>
  </mat-dialog-actions>

</form>
