import { Injectable } from '@angular/core';
import lodash from 'lodash';
import { Observable } from 'rxjs';
import { CampaignSchedule } from '../classes/campaign-schedule';
import { Daypart, ScheduleEvaluation, Station, Target } from '../models/campaign.model';
import { EvaluationService } from './evaluation.service';
import { MarketsService } from './markets.service';

@Injectable({
  providedIn: 'root'
})
export class MixMarketService {

  constructor(private evaluationService: EvaluationService,
              private marketsService: MarketsService) {}

  exportDAU(targets: Target[], schedule: CampaignSchedule): Observable<string> {

    return new Observable( observable => {

      // header of request
      const request = {
        datasource: this.marketsService.get(schedule.markets[0].marketFilename).market.marketName,
        campaign: "My Campaign",
        targetRequests: []
      }

      // for each target 
      targets.forEach( tgt=> {
  
        const targetRequest = {
          TargetName: tgt.name,
          marketRequests: []
        }

        // for each market with in the target
        schedule.markets.forEach( scheduleMarket => {

          // get the target universe and sample
          const res = this.marketsService.getTargetUniverse(scheduleMarket.marketFilename, tgt);
          const target = lodash.cloneDeep(tgt);
          target.universe = res.universe;
          target.sample = res.sample;

          // get market service data
          const mkt = this.marketsService.get(scheduleMarket.marketFilename);

          // build schedule request object ready to get cumes, etc
          const EvalSch: ScheduleEvaluation = {
            effectiveReachLevel: scheduleMarket.effectiveReachLevel,
            marketFilename: scheduleMarket.marketFilename,
            numWeeks: scheduleMarket.numWeeks,
            universe: target.universe,
            target: target,
            plan: scheduleMarket.plan.asSchedulePlan( scheduleMarket.planningPeriod, scheduleMarket.numWeeks, scheduleMarket.stations ), 
            planningPeriod: scheduleMarket.planningPeriod,
          }
          
          const marketRequest = {
            marketName: mkt.market.marketName,
            request: this.evaluationService.getEvaluationRequest(mkt, mkt.allStations, mkt.allPlanningDayparts, EvalSch ),
            StationNames: this.getNames(mkt.allStations, 'id', 'name'),
            DaypartNames: this.getNames(mkt.allPlanningDayparts, 'id', 'description'),
            CostPerInsertion: this.getCostPerInsertions( mkt.allStations, mkt.allPlanningDayparts),
          }

          // add in this marketRequest
          targetRequest.marketRequests.push(marketRequest);
        })

        // add in the complete target
        request.targetRequests.push(targetRequest);
      })

      this.evaluationService.exportDAU(request).subscribe( data => { //DAU_DATA
        observable.next( data.DAUFile);
      })
  
    })
    
  }

  // build object as per required format using the input array and key/value pair
  private getNames( array: any[], key: string, value: string): any {
    const data = {}
    array.forEach( arr => {
      data[arr[key]] = arr[value];
    } )
    return data
  }

  // cost per insertions currently passed as zeros
  private getCostPerInsertions(stations: Station[], dayparts: Daypart[]): any {
    
    const data = {}
    const stns = stations.map( station => station.id + '' );
    const dps = dayparts.map( daypart => daypart.id + '' );

    stns.forEach( st=> {
      data[st] = {}
      dps.forEach( dp=> {
        data[st][dp] = 0;
      })
    })

    return data;
  }

}
