<mat-toolbar class="white-toolbar mat-elevation-z4 fixed">
    
        <a routerLink="/dashboard">
            <img class="app-logo" src="assets/images/telmar logo-blue.svg" />
        </a>
        <h2>Audio
            <span class="telmar-title">Telmar &copy;2020</span></h2>
        <div class="vertical-line">
        </div>

        <div fxFlex="grow" fxLayoutAlign="left">
            <span *ngIf="documentStarted()">{{getDocumentName()}}
                <button (click)="onEditDocument()" mat-icon-button><mat-icon>edit</mat-icon></button>
                <button (click)="onSaveDocument()" mat-icon-button><mat-icon>save</mat-icon></button>
            </span>
        </div>

    <div flex>
        <button mat-icon-button (click)="onNavigate(['documentation'])" ><mat-icon>school</mat-icon></button>
        <user-menu></user-menu>
    </div>
</mat-toolbar>
