import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientInfo } from '../models/campaign.model';
import { ConfirmDialogComponent, ConfirmDialogModel, QuestionDialogModelButtonOptions } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import { SimpleInputDialogComponent, SimpleInputDialogModel } from '../components/dialogs/simple-input-dialog/simple-input-dialog.component';
import { ClientInfoDialogComponent, ClientInfoDialogModel } from '../components/dialogs/client-info-dialog/client-info-dialog.component';
import { DistributionSet, FreqDistDialogComponent, FreqDistDialogModel } from '../components/dialogs/freq-dist-dialog/freq-dist-dialog.component';
import { OptimisationDialogComponent, OptimisationDialogModel } from '../components/dialogs/optimisation-dialog/optimisation-dialog.component';
import { SelectionDialogComponent, SelectionDialogModel } from '../components/dialogs/selection-dialog/selection-dialog.component';
import { TargetCreationDialogComponent, TargetCreationDialogModel } from '../components/dialogs/target-creation-dialog/target-creation-dialog.component';
import { NTilesDialogModel, NTilesDialogComponent } from '../components/dialogs/n-tiles-dialog/n-tiles-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  message( message: string | string[], title: string, options?: any ): MatDialogRef<ConfirmDialogComponent, QuestionDialogModelButtonOptions> {

    options = options || {};

    if (!options.buttons) { 
      options.buttons = [ {caption: "Ok", data: "OK"} ]
    }

    const messageArray = (typeof message === "string") ? [message] : message;
    const dialogData = new ConfirmDialogModel(title, messageArray, options);

    options = Object.assign( options, {
      data: dialogData
    });

    return this.dialog.open(ConfirmDialogComponent, options);
  }

  question( message: string | string[], title: string, options?: any ): MatDialogRef<ConfirmDialogComponent, QuestionDialogModelButtonOptions> {

    options = options || {};

    if ( !options.buttons ){
      options.buttons = [
        { caption: "Ok", data: "OK" },
        { caption: "Cancel", data: "CANCEL" }
      ]
    }

    let messageArray = (typeof message === "string") ? [message] : message;
    const dialogData = new ConfirmDialogModel(title, messageArray, options);
    
    options = Object.assign( options, {
      data: dialogData
    });

    return this.dialog.open(ConfirmDialogComponent, options);
  }

  openSimpleInputDialog( valueName: string, title: string, message: string, initialValue?: string, options?: object ): MatDialogRef<SimpleInputDialogComponent, SimpleInputDialogModel> {

    const dialogData = new SimpleInputDialogModel(title, message, valueName, initialValue, options);

    options = options || {};
    options = Object.assign( options, {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
    });

    return this.dialog.open(SimpleInputDialogComponent, options);
  }

  openSelectionDialog(title: string, message: string, list: string[], selection: string[], options?: any): MatDialogRef<SelectionDialogComponent, SelectionDialogModel> {

    const dialogData = new SelectionDialogModel(title, message, list, selection);
    dialogData.multiSelect = options.multiSelect || false;

    options = options || {};
    options = Object.assign( options, {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
      width: '450px',
    });

    return this.dialog.open(SelectionDialogComponent, options);
  }

  showSnackBar( message: string, action: string, duration: number = 3000 ) {
    return this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  clientInfo(clientInfo: ClientInfo) : MatDialogRef<ClientInfoDialogComponent, ClientInfoDialogModel> {

    const dialogData = new ClientInfoDialogModel(clientInfo.title, clientInfo.client, clientInfo.author, clientInfo.brand, 
                                                 clientInfo.projectNumber, clientInfo.version, clientInfo.notes);
    let options = {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
    };

    return this.dialog.open(ClientInfoDialogComponent, options);
  }

  // FreqDistDialogModel is also the return value, but nothing really needs returning,
  frequencyDistribution(info: FreqDistDialogModel): MatDialogRef<FreqDistDialogComponent, FreqDistDialogModel> {

    const dialogData = new FreqDistDialogModel(info.title, info.startIndex, info.distributionSet );
    let options = {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
      // width: '800px',
      // height: '100%',
    };

    return this.dialog.open(FreqDistDialogComponent, options);
  }

  nTiles(info: NTilesDialogModel): MatDialogRef<NTilesDialogComponent, NTilesDialogModel>{

    const dialogData = new NTilesDialogModel(info.title, info.startIndex, info.distributionSet, info.nTiles);
    let options = {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
    };

    return this.dialog.open(NTilesDialogComponent, options);
  }

  // show the optimisation dialog (currently based on clientInfo)
  optimisationOptions(opt: OptimisationDialogModel): MatDialogRef< OptimisationDialogComponent, OptimisationDialogModel > {

    const dialogData = new OptimisationDialogModel(opt.rankStationBy, opt.stationBuyingGoal, opt.stationBuyingGoalValue,
                              opt.marketGoal,opt.marketGoalValue, opt.goalCombination, opt.marketGoal2, opt.marketGoalValue2, opt.numWeeks);
                    
    dialogData.market = opt.market;
    dialogData.targets = opt.targets;
    dialogData.targetIndex = opt.targetIndex;
    dialogData.schedules = opt.schedules;
    dialogData.scheduleIndex = opt.scheduleIndex;
    dialogData.dayparts = opt.dayparts;
    dialogData.daypartCosts = opt.daypartCosts;

    let options = {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
      width: '550px',
    };

    return this.dialog.open(OptimisationDialogComponent, options);
  }

  targetCreationDialog(marketFilenames: string[]): MatDialogRef<TargetCreationDialogComponent, TargetCreationDialogModel> {

    const dialogData = new TargetCreationDialogModel( marketFilenames );
    let options = {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
      width: '600px',
    };

    return this.dialog.open(TargetCreationDialogComponent, options);
  }
}
