<top-bar></top-bar>

<!--  Top card with slight gradient 
<mat-card class="top-card">
    <mat-card-header class="top-card-header">
    </mat-card-header>
</mat-card>
-->

<!--  main contents card with subtitle, containing right aligned menus and breadcrumbs centered  -->
<div class="content">
    <mat-card class="client-card">
        <mat-card-header class="header-container">
            
            <mat-card-title [ngClass] = "{'title': true}">
                <div><mat-icon *ngIf="icon" [ngClass] = "{'icon' : true}" mat-card-avatar>{{icon}}</mat-icon>
                <span style="vertical-align: super;">{{areatitle}}</span>
                <mat-card-subtitle [ngClass] = "{'subtitle': true}">{{subtitle}}</mat-card-subtitle> 
            </div>
            </mat-card-title>

            <ng-content [ngClass]="{'centre-content' : true}" select="[breadcrumbs]"></ng-content>
            <ng-content class="menu-content" select="[menu]"></ng-content>
        
        </mat-card-header>
        <ng-content select="[header]"></ng-content> 
        
        <mat-card-content class="mat-app-background client-area-content">
            <ng-content></ng-content>
        </mat-card-content>
    </mat-card>
</div>
