
<div *ngIf ="userDetails && userDetails.username" style="display:contents">
  <button mat-button [matMenuTriggerFor]="userMenu">Welcome {{ userDetails?.username }} <mat-icon>person</mat-icon></button>
  <mat-menu #userMenu="matMenu" yPosition="below">
    <button (click)="account()" mat-menu-item>Account</button>
    <!-- <button routerLink="/bugreporter" mat-menu-item>Feedback</button> -->
    <button routerLink="/change-password" mat-menu-item>Change Password</button>
    <button (click)="onDocumentation()" mat-menu-item>Documentation</button>
    <button (click)="logout()" mat-menu-item>Logout</button>
    <mat-divider></mat-divider>
    <span class="app-version" mat-menu-item>{{ getAppVersion() }}</span>
  </mat-menu>
</div>

