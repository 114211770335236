import { Component, OnInit, Input } from '@angular/core';
import { Market, Target } from 'src/app/shared/models/campaign.model';
import { CampaignSchedule } from 'src/app/shared/classes/campaign-schedule';
import { HelperService } from 'src/app/shared/services/helper.service';
import { UserPreferences } from 'src/app/shared/classes/user-preferences';
import { MatTableDataSource } from '@angular/material/table';
import { TupChartData, TupChartOptions } from 'src/app/shared/components/ng-chart/ng-chart.component';

// view input
export interface FreqDistValues {
  universe: number;
  distribution: number[];
}

// column definitions 
export interface DistributionColumn {
  columnType: string;
  columnDef: string;
  header: string;
  format: string;
  css: string;
  cell(x: any): any
}

@Component({
  selector: 'freq-dist',
  templateUrl: './freq-dist.component.html',
  styleUrls: ['./freq-dist.component.scss']
})
export class FreqDistComponent implements OnInit {

  title: string = "Frequency Distribution";

  @Input() 
  set data(values: FreqDistValues) {
    this.showDistribution( values.universe, values.distribution);
  }

  private floatFmt = '1.1-1';
  private wholeFmt = '1.0-0';
  
  columns: DistributionColumn[] = [
    { columnDef: 'level', columnType: 'string', header: 'Level', css: '', format: '', cell: (row: any) => `${row.level}` },
    { columnDef: 'exposedPct', columnType: 'value', header: 'Exposed %', css: '', format: this.floatFmt, cell: (row: any) => `${row.exposedPct}` },
    { columnDef: 'exposed', columnType: 'value', header: 'Exposed [00]', css: '', format: this.wholeFmt, cell: (row: any) => `${row.exposed}` },
    { columnDef: 'exposedAtLeastPct', columnType: 'value', header: 'Exposed at least %', css: '', format: this.floatFmt, cell: (row: any) => `${row.exposedAtLeastPct}` },
    { columnDef: 'exposedAtLeast', columnType: 'value', header: 'Exposed at least [00]', css: '', format: this.wholeFmt, cell: (row: any) => `${row.exposedAtLeast}` },
  ];

  displayedColumns = this.columns.map(c => c.columnDef);
  dataTable: MatTableDataSource<any>;

  chartType: string;
  chartOptions;
  chartData;
  
  constructor( private helperService: HelperService,
               private userPrefs: UserPreferences) { }  // the data if used in a dialog


  ngOnInit(): void {

    if (this.data) 
      this.showDistribution(this.data.universe, this.data.distribution);
  }

  onGraphSelect( e ): void {}

  // pass a standard schedule and target to graph from there
  loadData( market: Market, target: Target, schedule: CampaignSchedule ) {

    const tgtResults = schedule.market(market).results.find( tgt => tgt.target.coding == target.coding);
    if (tgtResults) this.showDistribution(target.universe, tgtResults.total.frequencyDistribution || []);
  }

  // pass through just the rquired values (also called when [values] is used in the view)
  showDistribution( universe: number, dist: number[])  {
    this.buildChart(universe, dist);
    this.buildTable(universe, dist);
  }

  // build table of dist results
  buildTable( universe: number, dist: number[])  {

    this.dataTable = this.dataTable || new MatTableDataSource<any[]>([]); 

    // exit if there's no distribution data
    if ( !dist.length ) {
      this.dataTable.data = []
      return
    }

    let table: any[] = [{
      level: 0,
      exposedPct: (( universe - dist[0] * universe) / universe) * 100,
      exposed: universe - (dist[0] * universe),
      exposedAtLeastPct: 100,
      exposedAtLeast: universe
    }]

    const plusLevel = 20; // hardcoded to 20 at Jeff's request
    let plusUsed = false;
    let plusTable = {
      level: `${plusLevel}+`,
      exposed: 0,
      exposedPct: 0,
      exposedAtLeastPct: 0,
      exposedAtLeast: 0,
    }

    let len = dist.length;
    dist.forEach( (value, index) => {
      if ( value > 0.0001 ) {

        // table
        if ( index < plusLevel-1 ) {
          table.push({
            level: `${(index + 1)}`,
            exposedPct: (index < len-1) ? (value - dist[index + 1]) * 100 : 0,
            exposed: (index < len-1) ? (value - dist[index + 1]) * universe : 0,
            exposedAtLeastPct: value * 100,
            exposedAtLeast: value * universe
          })
        }
        else {
          plusUsed = true;
          plusTable.exposed += ((index < len-1) ? (value - dist[index + 1]) * universe : 0);
          plusTable.exposedPct += ((index < len-1) ? (value - dist[index + 1]) * 100 : 0);
          plusTable.exposedAtLeastPct += (value * 100);
          plusTable.exposedAtLeast += (value * universe);
        }
      }

    })

    if ( plusUsed ) table.push( plusTable );
    this.dataTable.data = table; 
  }

  // populate chart for freq
  buildChart(universe: number, dist: number[]) {

    this.chartType = "line";
    this.chartData = {
      labels: [],
      datasets: [{
        backgroundColor: 'rgba(0, 84, 255, 0.6)', 
        fill: 'origin',
        label: 'Reach %',
        borderWidth: 2,
        data: []
      }]
    }

    this.chartOptions = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => {
            return `Level ${tooltipItem[0].xLabel}+`;
          },
          label: (tooltipItem, data) => {

            const label = data.datasets[tooltipItem.datasetIndex].label || '';
            const value = (tooltipItem.yLabel).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 } );
            return label + `: ${value}`;
          }
        },
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Level'
          },
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Reach %'
          },
        }]
      }
    }

    dist.forEach( (value, index) => {
        if ( value * 100 > 0.1 && this.chartData.datasets[0].data.length < 21 ) {
          this.chartData.labels.push(`${index + 1}`);
          this.chartData.datasets[0].data.push(value*100);
        }
    })

  }
}