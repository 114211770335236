import { Component, OnInit, Input } from '@angular/core';
import { CampaignService } from '../../../shared/services/campaign.service';

export interface SummaryValues {
  title: string;
  featured: SummaryValueItem[],
  sideList?: SummaryValueItem[]
}

export interface SummaryValueItem {
  title: string,
  value: string,
}

@Component({
  selector: 'campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.scss']
})
export class CampaignSummaryComponent implements OnInit {

  @Input() values: SummaryValues;
  constructor(private campaignService: CampaignService) { }

  ngOnInit() {
  }

}
