import { Component, OnInit } from '@angular/core';
import { TupAuthService, UserDetails, NULL_USER_DETAILS, AuthEvent } from '@telmar-global/tup-auth';
import { Router } from "@angular/router";
import { DialogService } from '../../../services/dialog.service';
import { CampaignService } from '../../../../shared/services/campaign.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  userDetails: UserDetails = NULL_USER_DETAILS;

  constructor(private authService: TupAuthService, 
              private dialogService: DialogService,
              private campaignService: CampaignService,
              private helperService: HelperService,
              public router: Router,
              private as: TupAnalyticsService) { }

  ngOnInit() {

    this.authService.authEvents.subscribe( e => {
      if( AuthEvent.AuthEventUserDataChanged == e ) {
        this.userDetails = this.authService.user;
      }
    })

  }

  logout(): void {
    this.authService.logout(true);
    this.as.logout();

    this.campaignService.clearAll();
    this.router.navigate(["/login"]);
  }

  account() {

    let message: string[]  = [`${this.userDetails.username} - ${this.userDetails.customer}`];
    message.push( `Email Address - ${this.userDetails.attributes.email || ""}`);

    this.dialogService.message(message, "User Info");
  }

  getAppVersion(): string {
    return this.helperService.getAppVersion()
  }

  onDocumentation() {
    this.router.navigate(["/documentation"]);
  }


}
