
<client-area areatitle="Targets" subtitle="Create your targets from demographics" icon="people">

  <tab-navigation header current="targets"></tab-navigation>
  <summary-breadcrumbs breadcrumbs [values]="campaignSummary"></summary-breadcrumbs>

  <div menu>
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item><mat-icon>help_outline</mat-icon>Help</button>
    </mat-menu>
  </div>

  <mat-spinner class="centered" *ngIf="processing"></mat-spinner>


  <mat-card style="height: 90px; width: 90px; margin: 10px; text-align: center">
    <mat-card-content >
      <button (click)="onCreateTarget()" class="new-document" mat-fab color="primary"><mat-icon>add_circle_outline</mat-icon></button>
      <p>Create new target</p>
    </mat-card-content>
  </mat-card>

  <target-grid #targetGrid (rename)="onTargetRename($event)" (delete)="onTargetDelete($event)" (selected)="onTargetSelect($event)"  ></target-grid>

</client-area>
  