import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { DistributionColumn } from "../freq-dist/freq-dist.component";

export interface NTilesValues {
  NTile: number;
  impressions: number;
  impressionPercent: number;
  GRP: number;
  Freq: number;
}

@Component({
  selector: "n-tiles",
  templateUrl: "./n-tiles.component.html",
  styleUrls: ["./n-tiles.component.scss"],
})
export class NTilesComponent implements OnInit {
  title: string = "N-Tiles";

  @Input()
  set data(values: NTilesValues[]) {
    this.buildTable(values);
  }

  private floatFmt = "1.1-1";
  private wholeFmt = "1.0-0";

  columns: DistributionColumn[] = [
    {
      columnDef: "nTile",
      columnType: "value",
      header: "N-Tile",
      css: "",
      format: "",
      cell: (row: any) => `${row.nTile}`,
    },
    {
      columnDef: "impressions",
      columnType: "value",
      header: "Impressions [00]",
      css: "",
      format: this.wholeFmt,
      cell: (row: any) => `${row.impressions}`,
    },
    {
      columnDef: "impressionPercent",
      columnType: "value",
      header: "Impressions %",
      css: "",
      format: this.floatFmt,
      cell: (row: any) => `${row.impressionPercent}`,
    },
    {
      columnDef: "grp",
      columnType: "value",
      header: "GRP",
      css: "",
      format: this.floatFmt,
      cell: (row: any) => `${row.grp}`,
    },
    {
      columnDef: "freq",
      columnType: "value",
      header: "Freq",
      css: "",
      format: this.wholeFmt,
      cell: (row: any) => `${row.freq}`,
    },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);
  dataTable: MatTableDataSource<any>;

  chartType: string;
  chartOptions;
  chartData;

  constructor() {}

  ngOnInit(): void {
    if (this.data) {
      this.buildTable(this.data);
    }
  }

  buildTable(nTiles: NTilesValues[]) {
    // exit if there's no nTiles
    if (!nTiles.length) {
      this.dataTable.data = [];
      return;
    }

    this.dataTable = this.dataTable || new MatTableDataSource<any[]>([]);

    let table = [];
    for (let x in nTiles) {
      table[x] = {
        nTile: nTiles[x].NTile,
        impressions: nTiles[x].impressions,
        impressionPercent: nTiles[x].impressionPercent,
        grp: nTiles[x].GRP,
        freq: nTiles[x].Freq,
      };
    }
    this.dataTable.data = table;
  }
}
