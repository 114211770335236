import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SimpleInputDialogModelOptions {
  confirmText?: string;
  dismissText?: string;
}

export class SimpleInputDialogModel {

  valueName: string = 'Enter Value';
  message: string = '';
  initialValue : string = '';
  confirmText : string = 'Ok';
  dismissText : string = 'Cancel';

  returnValue: string;
  returnButtonOk: boolean;

  constructor(public title: string, message: string, valueName: string, initialValue?: string, options?: SimpleInputDialogModelOptions) {

    this.valueName = valueName || this.valueName;
    this.initialValue = initialValue || this.initialValue;
    this.message = message || this.message;

    if( options ) {
      this.confirmText = options.confirmText || this.confirmText;
      this.dismissText = options.dismissText || this.dismissText;
    }
  }
}


@Component({
  selector: 'app-simple-input-dialog',
  templateUrl: './simple-input-dialog.component.html',
  styleUrls: ['./simple-input-dialog.component.scss']
})
export class SimpleInputDialogComponent implements OnInit {
  title: string;
  message: string;
  valueName: string;
  inputValue: string;
  confirmButtonText: string;
  dismissButtonText: string;

  constructor (
    public dialogRef: MatDialogRef<SimpleInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpleInputDialogModel ) {
      
    this.title = data.title;
    this.message = data.message;
    this.valueName = data.valueName;
    this.inputValue = data.initialValue;
    this.confirmButtonText = data.confirmText;
    this.dismissButtonText = data.dismissText;
  }

  ngOnInit() { }

    // Confirm pressed, close the dialog
  onConfirm(): void {
    let ret = new SimpleInputDialogModel(this.title, this.message, this.valueName, this.inputValue);
    ret.returnValue = this.inputValue;

    this.dialogRef.close(ret);
  }

    // Dismiss pressed, close the dialog
  onDismiss() {
    this.dialogRef.close();
  }
}

