import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

//common
export interface TupChartOptions {
  type: string; // 'bar-vertical-2d' 'pie-chart' 'area-chart' 'advanced-pie-chart'
  title?: string;
  gradient: boolean;
  showLegend: boolean;
  animations: boolean;
  legendTitle?: string;
  legendPosition?: string;
  colorScheme?: {}
  view?: number[];
  decimals: number;
  axis?: TupChartAxisOptions;
  pie?: TupChartPieOptions;
}

//axis
export interface TupChartAxisOptions {
  showXAxis?: boolean;
  showYAxis?: boolean;
  showXAxisLabel?: boolean;
  xAxisLabel?: string;
  showYAxisLabel?: boolean;
  yAxisLabel?: string;
  yScaleMax?: number;
  yScaleMin?: number;
}

//pie
export interface TupChartPieOptions {
  explodeSlices?: boolean;
  showLabels?: boolean;
  trimLabels?: boolean;
  doughnut?: boolean;
}

export interface TupChartData {
  name: string;
  series: TupChartDataSeries[]
}

export interface TupChartDataSeries {
  name: string, value: number
}

@Component({
  selector: 'ng-chart',
  templateUrl: './ng-chart.component.html',
  styleUrls: ['./ng-chart.component.scss']
})
export class TupChartComponent implements OnInit {

  @Input() options: TupChartOptions;
  @Input() data: any[];
  @Output() select: EventEmitter<any> = new EventEmitter<any>();
  @Output() activate: EventEmitter<any> = new EventEmitter<any>();
  @Output() deactivate: EventEmitter<any> = new EventEmitter<any>();

  // telmar coloure
  colorScheme = {
    domain: ['#276EF1', '#275DF1', '#274CF1', '#273CF1', '#27A1F1', '#2790F1', '#277FF1']
  }

  // default width x height
  view: number[] = [700, 400]

  constructor() { 
  }

  ngOnInit(): void {
  }

  getLabelFormatting( c ) {
    return `${c}`;
  }

  onSelect( e ): void {
    this.select.emit( e );
  }

  onActivate( e ): void {
    this.activate.emit( e )
  }

  onDeactivate( e ): void {
    this.deactivate.emit( e );
  }

  stringify(model: any): String {
    return JSON.stringify(model);
  }

  formatFloat(value: number, decimals: number = 0): string {

    const options = {
      maximumFractionDigits: (decimals == -1) ? 2 : decimals,
      minimumFractionDigits: (decimals == -1) ? 2 : 0
    }

    return value.toLocaleString(undefined, options ) // "1,234.57"
  }
  
}
