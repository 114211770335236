import { CampaignSchedule } from './campaign-schedule';
import { ClientInfo, Target, Daypart, Market, TargetMarketData } from '../models/campaign.model';
import { MarketMetrics } from '../models/market-information.model';
import { UserPreferences } from '../classes/user-preferences';
import { CampaignDocument } from '../models/campaign-document.model';
import { PlanningMethod, PlanningPeriod } from './campaign-schedule-plan';

export enum CampaignDirty {
    markets,
    targets,
    stations,
    planning
}

export class RadioCampaign {
    created: Date;
    documentId: string; //elastic search UID
    clientInfo: ClientInfo;

    schedules: CampaignSchedule[] = [] // main schedules by target / plan / results
    currentSchedule: number;  // used by station selection for seeing current schedule station selection

    // selected for campaign planning
    markets: Market[] = [];  

    targets: Target[] = [];
    dayparts: Daypart[] = []; // dayparts to plan on, also known as preferred dayparts

    prefs: UserPreferences;

    // planning period property updates all schedules
    private _planningPeriod: PlanningPeriod; // ('singleweek', 'allweeks')
    get planningPeriod(): PlanningPeriod { return this._planningPeriod; }
    set planningPeriod(value: PlanningPeriod) {
        this._planningPeriod = value;
    }

    planningPeriodAsString( period: PlanningPeriod) { 
        return (period == PlanningPeriod.SingleWeek) ? "singleweek" : "allweeks"
    }

    // effective reach updates all schedules
    private _effectiveReachLevel: number;
    get effectiveReachLevel(): number { return this._effectiveReachLevel}
    set effectiveReachLevel( value: number) {
        this._effectiveReachLevel = value;
        this.schedules.forEach( sch => sch.effectiveReachLevel = value);
    }

    // numweeks propery updates all schedules (not any more)
    // private _numweeks: number;
    // get numWeeks(): number { return this._numweeks; }
    // set numWeeks(value: number) {
    //     this._numweeks = value;
    // }

    private _documentStarted: boolean;
    get documentStarted():boolean { return this._documentStarted }
    set documentStarted(value: boolean) { this._documentStarted = value}

    // keep track of what is dirty in the campaign.  Used to know what new R&F calls are for
    dirtyList: CampaignDirty[] = [];
    isDirty( item?: CampaignDirty) : boolean {

        if (!item) return !!this.dirtyList.length 
        return (this.dirtyList.indexOf(item) != -1);
    }

    addDirty( item: CampaignDirty ) {
         if (this.dirtyList.indexOf(item) == -1) this.dirtyList.push(item) 
    }

    removeDirty( item?: CampaignDirty ) {
        if ( !item ) this.dirtyList = []
        else {
            const index = this.dirtyList.indexOf(item);
            if (index !=- 1) this.dirtyList.splice(index, 1);
        }
    }

    constructor() {
        this.clearAll();
        this.prefs = new UserPreferences();
    }

    // clear all and set a few defaults ready to start fresh
    startNewDocument(marketFilename: string= "") {
        this.clearAll();
        this.documentStarted = true;
        this.clientInfo = { title: `New Plan, ${this.getCurrentDateTime()}`, brand: "", client: "", author: "", projectNumber: "1", version: "1", notes: "" }
        this.addSchedule(marketFilename, "Schedule 1");
    }

    // clear and reset all campaign settings
    clearAll() {
        this.clientInfo = { title: "", brand: "", client: "", author: "", projectNumber: "0", version: "0", notes: "" }
        this.created = new Date();
        this.documentId = "";
        this.documentStarted = false;
        this.removeDirty();

        //this.planningMethod = "generic";
        this.planningPeriod = PlanningPeriod.TotalWeeks;
        //this.numWeeks = 1;
        this.effectiveReachLevel = 3;
        this.markets = [];
        this.targets = [];
        this.schedules = [];
    }

    market(market: string | Market): Market {
        const marketFilename = ( typeof market === 'string') ? market : market.marketFilename;
        return this.markets.find( m=> m.marketFilename === marketFilename);
    }

    // adding a schedule and configuring with a specific market
    addSchedule(market: string | Market, name: string, copyFrom?: CampaignSchedule): CampaignSchedule {

        const marketFilename = (typeof market === 'string') ? market : market.marketFilename;
        
        this.schedules.push(new CampaignSchedule(marketFilename, name));
        let sch = this.schedules[ this.schedules.length-1 ];
        const scheduleMarket = sch.market(marketFilename);

        if (this.schedules.length == 1) this.currentSchedule = 0;

        sch.numWeeks = 1;
        sch.planningPeriod = this.planningPeriod;
        sch.planningMethod = PlanningMethod.Generic; 
        sch.effectiveReachLevel = this.effectiveReachLevel;

        if (copyFrom) {
            scheduleMarket.plan.copyFrom(copyFrom.market(marketFilename).plan);
            sch.numWeeks = copyFrom.numWeeks;  // campaign level but here as a placeholder
            sch.effectiveReachLevel = copyFrom.effectiveReachLevel; // campaign level but here as a placeholder
            sch.planningPeriod = copyFrom.planningPeriod;
            sch.planningMethod = copyFrom.planningMethod;
            scheduleMarket.stations = Object.assign([], copyFrom.market(marketFilename).stations);
        }
        return sch;
    }

    deleteSchedule( index: number ) {
        this.schedules.splice(index, 1);
    }

    clearScheduleResults() {
        this.schedules.forEach( sch => { sch.clearResults() } )
    }

    addMarket(marketFilename: string) {
        this.schedules.forEach( s=> s.addMarket(marketFilename) );
    }

    deleteMarket(marketFilename: string) {
        this.schedules.forEach( s=> s.deleteMarket(marketFilename) );

        // remove from the targets marketData
        this.targets.forEach( target => {
            if (target.marketData) {
                const idx = target.marketData.findIndex( t=> t.marketFilename === marketFilename);
                if (idx !== -1) target.marketData.splice(idx, 1);
            }
        })
    }

    clearMarkets(){
        const filenames = this.markets.map( m=> m.marketFilename);
        filenames.forEach( filename => this.deleteMarket(filename));

        this.targets.forEach( target => target.marketData = [] );
    }

    addTarget( target: Target ) {
        // add if not already there
        if (!this.targets.find( tgt => tgt.coding == target.coding) ) {

            this.targets.push(target);
            this.addDirty( CampaignDirty.targets );
        } 
    }

    // with the given market and target, return the universe and sample
    // buildTargetUniverses() found in the campaignservice
    getTargetMarketData(market: Market, target: Target): TargetMarketData {
        const tgt = this.targets.find( tgt => tgt.coding === target.coding);
        return tgt && tgt.marketData ? tgt.marketData.find( t => t.marketFilename === market.marketFilename) : null;
    }

    deleteTarget( target: Target ) {
        const index = this.targets.findIndex( tgt=> tgt.coding == target.coding);
        if (index != -1 ) {
            this.targets.splice(index, 1);
            this.addDirty( CampaignDirty.targets );
        }

        this.schedules.forEach( sch => {
            sch.deleteTarget(target);
        });
    }

    renameTarget( target: Target ) {
        let tgt = this.targets.find(t=> t.coding == target.coding);

        // was part of the campaign rather than just in the target selection list
        if (tgt) tgt.name = target.name;
    }

    formatFloat(value: number, decimals: number = 0): string {
        if (typeof value ==='undefined') return "";
        return value.toLocaleString(undefined, { maximumFractionDigits: decimals }) // "1,234.57"
    }

    // any results in any of the schedules
    hasResults(): boolean {
        return !!this.schedules.find( sch => sch.hasResults() );
    }

    hasPlan(marketFilename?: string): boolean {

        if (marketFilename) {
            return !!this.schedules.find( sch => sch.market(marketFilename) ? sch.market(marketFilename).plan.count > 0 : 0 );
        }

        // any plan regardless of market
        return !!this.schedules.find( sch=> sch.markets.find(m=> m.plan.count > 0 ));
    }

    // markets, targets and stations have all been selected
    readyForPlanning(): boolean {
        return !!(this.markets.length && this.targets.length && this.markets[0].stations?.length);
    }

    // title bar breadcrumbs string array
    // called from all views to build the breadcrumbs campaign summary
    getCampaignSummary(market: Market = null, target: Target = null, schedule: CampaignSchedule = null ): string[] {

        if (!this.markets.length) return []

        const mkt = market || this.markets[0]; 
        const tgt = target || (this.targets.length ? this.targets[0] : null );

        let stations = mkt.stations; // this.stations;
        let numWeeks = 0;

        // if there are stations then use the specific count
        if (schedule) {
            const scheduleMarket = schedule.market(mkt);
            stations = scheduleMarket.stations;
            numWeeks = scheduleMarket.numWeeks;
        }

        let text:string[] = [`${mkt.marketName} (${mkt.reportType}, ${mkt.geography}, ${mkt.periodNameLong})`];
        if (tgt) {

            const tgtData = this.getTargetMarketData(mkt, tgt);
            text.push( `${tgt.name} (Popn [00]: ${this.formatFloat( tgtData.universe )})` );
            text.push( `${stations.length} Station` + (stations.length ==1  ? "" : "s") );

             if (schedule)
                text.push( `${numWeeks} Week` + (numWeeks > 1 ? "s" : "") );
        }
        
        return text;
    }

    // configuration after a market load has been done.
    configureAfterLoadMarkets(mkts: MarketMetrics[], newFilenames: string[]) {

        // process all new markets (filter by filename)
        const markets = mkts.filter( m=> newFilenames.includes( m.market.marketFilename) );

        // keep a subset of dayparts and stations to be used in planning (default to all selected)        
        // for each new market to the campaign, fetch the preferred daypart and planningStations list
        markets.forEach( market => {
            
            this.dayparts = market.allPlanningDayparts;
            if (this.prefs.user.preferredDayparts.length) {
                this.dayparts = market.allPlanningDayparts.filter( dp=>  this.prefs.user.preferredDayparts.includes(dp.id) );

                if (!this.dayparts.length) this.dayparts = market.allPlanningDayparts;
            }

            // extract out the total Station and store
            const mkt = this.markets.find( m=> m.marketFilename == market.market.marketFilename);
            mkt.planningStations = market.allStations.filter( s=> s.id !== 999999);
            mkt.totalStation = market.allStations.find( s=> s.id == 999999);
        })
    }

    configureAfterTargetSelect(market: Market) {

        // the top n stations in this.stations is built in the campaignService
        //this.market(market).stations;
        this.schedules.forEach( schedule => {

            let scheduleMarket = schedule.market(market);
            if ( !scheduleMarket.stations.length ) scheduleMarket.stations = Object.assign([], this.market(market).stations) //market.stations
        })
    }

    private getCurrentDateTime() :string {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const d = new Date();
        return `${d.getDate()} ${months[d.getMonth()]} ${d.getHours()}:${(d.getMinutes() + '').padStart(2,'0')}`
    }
    

}