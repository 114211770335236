<client-area areatitle = "Documents" 
             subtitle = "See previously created documents as well as starting new schedules"
             icon = "dashboard">

    <tab-navigation *ngIf="documentStarted()" header current="dashboard"></tab-navigation>

    <div menu>
        <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onDocumentRefresh()"><mat-icon>loop</mat-icon>Refresh</button>
        </mat-menu>
      </div>
    
    
    <documents #documents></documents>

</client-area>
