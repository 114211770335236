  <div>
    <mat-table [dataSource]="dataSource" matSortActive="{{initialSortColumn}}" matSortDirection="asc" matSortDisableClear matSort dense >
  
      <!-- Generic column definition -->
      <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
        <mat-header-cell [ngClass]="column.css" mat-sort-header disableClear *cdkHeaderCellDef>{{ column.header }} </mat-header-cell>

        <!-- Checkbox Column -->
        <div *ngIf="column.columnType=='select'">
          <mat-cell [ngClass]="column.css" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="'select target'">
            </mat-checkbox>
          </mat-cell>
        </div>

        <!-- Actions Column -->
        <div *ngIf="column.columnType=='actions'">
            <mat-cell [ngClass]="column.css" *matCellDef="let row">
                <button *ngIf="row.documentId !== ''" mat-icon-button (click)="onDelete(row)" ><mat-icon style="color:red;">remove_circle_outline</mat-icon></button>
            </mat-cell>
        </div>
          
        <!-- Editable Column -->
        <div *ngIf="column.columnType=='editable'">
          <mat-cell [ngClass]="column.css" *matCellDef="let row; let i = index">
                <input matInput
                  id="edit_{{i}}"
                  (click)="$event.target.select()"
                  value="{{column.cell(row)}}"
                  (blur)="onSaveTitle(column.columnDef, $event.target.value, row)">
                  <button mat-icon-button (click)= "onEditTitle(i)"><mat-icon matPrefix>mode_edit</mat-icon></button>
                </mat-cell>
        </div>
      
        <!-- regular Column -->
        <div *ngIf="column.columnType=='string'">
          <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
        </div>
      </ng-container>
      
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      
    </mat-table>
  </div>
  