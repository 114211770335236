import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng5SliderModule } from 'ng5-slider';

import { TupAuthModule } from '@telmar-global/tup-auth';
import { TupAnalyticsModule } from '@telmar-global/tup-analytics';
import { TupChartModule } from '@telmar-global/tup-chart';
import { AppRoutingModule, routes } from './app-routing.module';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { TargetsComponent } from './pages/targets/targets.component';
import { StationsComponent } from './pages/stations/stations.component';
import { PlanningComponent } from './pages/planning/planning.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { GlossaryFilterPipe } from './pages/documentation/filter.pipe';

import { UserMenuComponent } from './shared/components/top-bar/user-menu/user-menu.component';
import { ConfirmDialogComponent } from './shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { SimpleInputDialogComponent } from './shared/components/dialogs/simple-input-dialog/simple-input-dialog.component';
import { ClientAreaComponent } from './shared/components/client-area/client-area.component';
import { DocumentsComponent } from './pages/dashboard/documents/documents.component';
import { BottomBarComponent } from './shared/components/bottom-bar/bottom-bar.component';
import { MarketsService } from './shared/services/markets.service';
import { ApiService } from './shared/services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { DialogService } from './shared/services/dialog.service';
import { UserMessageService } from './shared/services/user-message.service';
import { MixMarketService } from './shared/services/mix-market.service';
import { CampaignSummaryComponent } from './shared/components/campaign-summary/campaign-summary.component';
import { SchedulesComponent } from './pages/planning/schedules/schedules.component';
import { TotalsComponent } from './pages/planning/totals/totals.component';
import { TargetGridComponent } from './pages/targets/target-grid/target-grid.component';
import { NumberWidgetComponent } from './shared/components/campaign-summary/number-widget/number-widget.component';
import { TotalsGraphingComponent } from './pages/planning/totals-graphing/totals-graphing.component';
import { ChipSelectionComponent } from './shared/components/chip-selection/chip-selection.component';

import { ClientInfoDialogComponent } from './shared/components/dialogs/client-info-dialog/client-info-dialog.component';
import { FreqDistDialogComponent } from './shared/components/dialogs/freq-dist-dialog/freq-dist-dialog.component';
import { OptimisationDialogComponent } from './shared/components/dialogs/optimisation-dialog/optimisation-dialog.component';
import { SelectionDialogComponent } from './shared/components/dialogs/selection-dialog/selection-dialog.component';
import { TabNavigationComponent } from './shared/components/tab-navigation/tab-navigation.component';
import { SummaryBreadcrumbsComponent } from './shared/components/summary-breadcrumbs/summary-breadcrumbs.component';
import { TargetCreationDialogComponent } from './shared/components/dialogs/target-creation-dialog/target-creation-dialog.component';
import { FreqDistComponent } from './shared/components/freq-dist/freq-dist.component';
import { TupChartComponent } from './shared/components/ng-chart/ng-chart.component';
import { BugReporterComponent } from './pages/bug-reporter/bug-reporter.component'
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { TotalsMultiMarketComponent } from './pages/planning/totals-multi-market/totals-multi-market.component';
import { NTilesDialogComponent } from './shared/components/dialogs/n-tiles-dialog/n-tiles-dialog.component';
import { NTilesComponent } from './shared/components/n-tiles/n-tiles.component'

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    LoginComponent,
    SurveysComponent,
    TargetsComponent,
    StationsComponent,
    PlanningComponent,
    UserMenuComponent,
    DashboardComponent,
    ClientAreaComponent,
    DocumentsComponent,
    BottomBarComponent,
    ConfirmDialogComponent,
    SimpleInputDialogComponent,
    ClientInfoDialogComponent,
    CampaignSummaryComponent,
    SchedulesComponent,
    TotalsComponent,
    TargetGridComponent,
    NumberWidgetComponent,
    TotalsGraphingComponent,
    ChipSelectionComponent,
    FreqDistDialogComponent,
    OptimisationDialogComponent,
    SelectionDialogComponent,
    TabNavigationComponent,
    SummaryBreadcrumbsComponent,
    TargetCreationDialogComponent,
    FreqDistComponent,
    TupChartComponent,
    DocumentationComponent,
    GlossaryFilterPipe, 
    BugReporterComponent,
    ChangePasswordComponent,
    TotalsMultiMarketComponent,
    NTilesDialogComponent,
    NTilesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    CdkTableModule,
    FormsModule,
    FlexLayoutModule,
    Ng5SliderModule,
    TupAnalyticsModule.forRoot(environment.analytics),
    TupAuthModule.forRoot( environment, routes ),
    TupChartModule
  ],
  entryComponents: [ 
    ConfirmDialogComponent, 
    SimpleInputDialogComponent, 
    ClientInfoDialogComponent ,
    FreqDistDialogComponent,
    OptimisationDialogComponent,
    SelectionDialogComponent,
    TargetCreationDialogComponent,
    NTilesDialogComponent
  ],
  providers: [
    MarketsService,
    DialogService,
    UserMessageService,
    ApiService,
    MixMarketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
