
<h4 *ngIf="options?.title" >{{options?.title}}</h4>

<ngx-charts-bar-vertical-2d *ngIf="options?.type=='bar-vertical-2d'"
    [view]="options.view || view"
    [scheme]="options.colorScheme || colorScheme"
    [results]="data"
    [animations]="options.animations"
    [gradient]="options.gradient"
    [legend]="options.showLegend"
    [legendTitle]="options.legendTitle"
    [legendPosition]="options.legendPosition"
    [xAxis]="options.axis.showXAxis"
    [yAxis]="options.axis.showYAxis"
    [showXAxisLabel]="options.axis.showXAxisLabel"
    [showYAxisLabel]="options.axis.showYAxisLabel"
    [xAxisLabel]="options.axis.xAxisLabel"
    [yAxisLabel]="options.axis.yAxisLabel"
    [yScaleMax]="options.axis.yScaleMax"
    [yScaleMin]="options.axis.yScaleMin"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">

    <ng-template #tooltipTemplate let-model="model">
        <h4 style="margin-bottom: 4px; font-weight: 200;">{{ model.series }} &bull; {{ model.name }}</h4>
        <h3 style="margin-top: 4px; font-weight: 200;">{{ options.axis.yAxisLabel }}:&nbsp; {{ formatFloat(model.value, options.decimals) }} </h3>
    </ng-template>

</ngx-charts-bar-vertical-2d>

<ngx-charts-pie-chart *ngIf="options?.type=='pie-chart'"
    [view]="options.view || view"
    [scheme]="options.colorScheme || colorScheme"
    [results]="data"
    [animations]="options.animations"
    [gradient]="options.gradient"
    [legend]="options.showLegend"
    [legendTitle]="options.legendTitle || ''"
    [legendPosition]="options.legendPosition"
    [explodeSlices]="options.pie.explodeSlices"
    [labels]="options.pie.showLabels"
    [trimLabels]="options.pie.trimLabels"
    [labelFormatting]="getLabelFormatting"
    [doughnut]="options.pie.doughnut"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
</ngx-charts-pie-chart>

<ngx-charts-advanced-pie-chart *ngIf="options?.type=='advanced-pie-chart'"
    [view]="options.view || view"
    [scheme]="options.colorScheme || colorScheme"
    [results]="data"
    [animations]="options.animations"
    [gradient]="options.gradient"
    [legend]="options.showLegend"
    [legendTitle]="options.legendTitle || ''"
    [legendPosition]="options.legendPosition"
    [explodeSlices]="options.pie.explodeSlices"
    [labels]="options.pie.showLabels"
    [trimLabels]="options.pie.trimLabels"
    [labelFormatting]="getLabelFormatting"
    [doughnut]="options.pie.doughnut"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
</ngx-charts-advanced-pie-chart>


<ngx-charts-area-chart *ngIf="options?.type=='area-chart'"
    [view]="options.view || view"
    [scheme]="options.colorScheme || colorScheme"
    [results]="data"
    [animations]="options.animations"
    [gradient]="options.gradient"
    [legend]="options.showLegend"
    [legendTitle]="options.legendTitle"
    [legendPosition]="options.legendPosition"
    [xAxis]="options.axis.showXAxis"
    [yAxis]="options.axis.showYAxis"
    [showXAxisLabel]="options.axis.showXAxisLabel"
    [showYAxisLabel]="options.axis.showYAxisLabel"
    [xAxisLabel]="options.axis.xAxisLabel"
    [yAxisLabel]="options.axis.yAxisLabel"
    [timeline]="false"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">

    <ng-template #tooltipTemplate let-model="model">
        <h3 style="margin-top: 4px; font-weight: 200;">{{ options.axis.yAxisLabel }}:&nbsp; {{ formatFloat(model.value, options.decimals) }} </h3>
    </ng-template>

    <ng-template #seriesTooltipTemplate let-model="model">
        <h3 style="font-weight: 200">
            {{ model[0].series }}:&nbsp; {{ formatFloat(model[0].value, options.decimals) }} 
        </h3> 
    </ng-template>

</ngx-charts-area-chart>
