import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentService } from '../../shared/services/document.service';
import { CampaignService } from '../../shared/services/campaign.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ DocumentsComponent ]
})
export class DashboardComponent implements OnInit {

  @ViewChild('documents', { static: true} ) documents: DocumentsComponent;
  
  constructor( private documentService: DocumentService,
               private campaignService: CampaignService,
               public router: Router ) { }

  ngOnInit() {
  }

  documentStarted(): boolean {
    return this.campaignService.run.documentStarted;
  }

  onDocumentRefresh() {
    this.documents.loadData();
  }

}
