import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { MarketInfoResponse, Measure, Location, DemographicAgeSexNode, DemographicSocioNode, Demographic, MarketMetrics, DataTable } from '../models/market-information.model';
import { Target, Station, Daypart, Market, Survey } from '../models/campaign.model';

@Injectable({
  providedIn: 'root'
})
export class MarketsService {

  // data ready to use in selection UI
  allMarkets: Market[] = [];
  allSurveys: Survey[] = [];

  // main object holding market specific data
  markets: MarketMetrics[] = [];

  constructor(
     private apiService: ApiService,
  ) { }

  // call to fetch all the available surveys.  Additional calls needed for markets within each survey
  getSurveys(): Observable<Survey[]> {

    if (this.allSurveys.length) {
      return new Observable( observable => {
        observable.next(this.allSurveys);
        observable.complete();
      })
    }

    return this.apiService.request("GET", environment.api.data.url, environment.api.data.endPoint.surveys)
        .map ( surveys => {

          let formatted: Survey[] = [];
          surveys.forEach( survey => {

            // reformat the data into camel case vars to fit Market class, the save
            formatted.push({ 
              id: survey.SurveyID,
              periodNameLong: survey.PeriodNameLong,
              periodNameShort: survey.PeriodNameShort,
              reportPeriod: survey.ReportPeriod, 
              reportPeriodId: survey.ReportPeriodId,
              reportPeriodStart: survey.StartDate,
              reportPeriodEnd: survey.EndDate,
              reportTypeCode: survey.ReportTypeCode,
              reportTypeShort: survey.ReportTypeShort,
              copyright: survey.Copyright,
              reportType: survey.ReportType, 
              surveyType: survey.SurveyType,
              loaded: false,
            })

          })
          this.allSurveys = formatted;
          return formatted;
        })
  }

  // get al the markets related to the given survey
  getMarketsBySurvey( survey: Survey ): Observable<Market[]> {

    let options = {
      params: { Survey: survey.id }
    }

    return this.apiService.request("GET", environment.api.data.url, environment.api.data.endPoint.marketsBySurvey, options)
        .map ( markets => {

          let formatted: Market[] = [];
          markets.forEach(mk => {

            // reformat the data into camel case vars to fit Market class, the save
            formatted.push({ 
              surveyId: survey.id,
              geography: mk.Geography,
              marketName: mk.MarketName,
              marketCode: mk.MarketCode,
              marketType: mk.MarketType,
              marketFilename: mk.MarketFileName,
              universe: mk.Population,
              sample: mk.Sample,
              periodNameLong: survey.periodNameLong,
              periodNameShort: survey.periodNameShort,
              reportTypeShort: survey.reportTypeShort,
              reportPeriod: survey.reportPeriod,
              reportPeriodId: survey.reportPeriodId,
              reportType: survey.reportType,
              copyright: survey.copyright,
              startDate: new Date(survey.reportPeriodStart), 
              endDate: new Date(survey.reportPeriodEnd), 
            })
          })

          return formatted;
        })
  }

  // get a list of markets and associated info like start/end date
  getMarkets(): Observable<Market[]> {

    // dont re-fetch
    if (this.allMarkets.length) return of(this.allMarkets);

    return this.apiService.request("GET", environment.api.data.url, environment.api.data.endPoint.markets)
        .map ( markets => {

          let formatted: Market[] = [];
          markets.forEach(mk => {

            // reformat the data into camel case vars to fit Market class, the save
            formatted.push({ 
              surveyId: "",
              geography: mk.Geography,
              periodNameLong: mk.PeriodNameLong,
              periodNameShort: mk.PeriodNameShort,
              marketName: mk.MarketName,
              reportPeriod: mk.ReportPeriod,
              reportPeriodId: mk.ReportPeriodId,
              marketCode: mk.MarketCode,
              reportType: mk.ReportType,
              reportTypeShort: "",
              marketType: mk.MarketType,
              startDate: mk.StartDate,
              endDate: mk.EndDate,
              marketFilename: mk.MarketFileName,
              universe: mk.Population,
              sample: mk.Sample,
              copyright: ""
            })

          })
          this.allMarkets = formatted;
          return formatted;
        });
  }

  // accept an array of markets and call getMarketInfo for only those needed
  getMarketsInfo( marketFilenames: string[], forceReload: boolean = false ): Observable< MarketInfoResponse > {

    if (forceReload) this.clearMarkets();

    let filenames: string[] = []

    return new Observable( ob => {

      let reqs: Observable<MarketMetrics>[] = []
      marketFilenames.forEach( filename => {

        // check what is already loaded in the markets arrays
        // if not loaded then start a request
        if (!this.getMarket(filename)) {
          filenames.push(filename);
          reqs.push(this.getMarketInfo( filename, forceReload));
        }
      })

      if (reqs.length) {
        forkJoin(reqs).subscribe( data => {
          ob.next( { added: filenames} );
          ob.complete();
        })
      }
      else {
        ob.next( { added: []} )
        ob.complete();
      }
    })
  }


  // get all the additional meta data to be stored for a particular market. demos, stations, dayparts etc
  getMarketInfo( marketFilename: string, forceReload: boolean = false ): Observable<MarketMetrics> {

    let mkt = this.getMarket(marketFilename);
    if (mkt && !forceReload) return of(mkt);

    const options = {
      params: { MarketFile: marketFilename }
    }

    return this.apiService.request("GET", environment.api.data.url, environment.api.data.endPoint.marketData, options )
      .map( data => {

        this.markets.push({

          // expecting to have data.ReportType, data.ShoerReportType etc, in here
          // should be able to build a market object rather than copying from the input
          market: this.processMarket(data, marketFilename),

          allMeasures: this.processMeasures(data.Measures),
          allLocations: this.processLocations(data.Locations),
          allDayparts: this.processDayparts(data.Dayparts),
          allStations: this.processStations(data.Stations, data.Formats, data.NetworkAffiliations, data.Networks),
          allPlanningDayparts: [],

          demographicLookup: this.processDemographicLookup(data.Demographics),
          demographicTree: this.processSocioDemographics(data.SocioEconomicGroups), 
          demosAgeSexGroups: this.processAgeSexDemographics(data.AgeSexDemos),
          dataTable: data.DataItems ? Object.assign([], data.DataItems) : [], // straight duplication.  Too big to faff
          dataTableQtrHours: [],

          uniqueAges: [],
          socioAges: [],
        });

        // get those ages that cant be used with socio
        let mkt = this.markets[this.markets.length-1];
        
        mkt.allPlanningDayparts = mkt.allDayparts.filter( dp => dp.isPlanning); // dayparts that can be planned with
        mkt.uniqueAges = mkt.demographicLookup.filter( socio => socio.socEconGroupID == 0 ).map( socio => socio.ageSexCode); // ages that cant be used with socio/econ

        mkt.dataTableQtrHours = mkt.dataTable.filter( data => { return data.MeasureID == 1 && data.LocationID == 1 }); //lookup table containing only QtrHrs

        // ages that have Ids to be used with the socio tree, also remove duplicates
        mkt.demographicLookup.forEach( socio => {
          if (socio.socEconGroupID != 0 && !mkt.socioAges.includes( socio.ageSexCode )) mkt.socioAges.push(socio.ageSexCode);
        });

        return this.getMarket(marketFilename);
    });
  }


  // hoping there will be more data from the /marketdata/ call to fill in here
  private processMarket( data: any, marketFilename: string): Market {

    return {
      surveyId: data.SurveyID,
      geography: data.Geography,
      periodNameShort: data.PeriodNameShort,
      periodNameLong: data.PeriodNameLong,
      marketName: data.MarketName || "",
      marketCode: 0,
      marketType: data.MarketType || "",
      marketFilename: marketFilename,
      reportPeriod: "",
      reportPeriodId: data.ReportPeriodId,
      reportTypeShort: data.ReportTypeShort,
      reportType: data.ReportType,
      startDate: new Date(data.StartDate),
      endDate: new Date(data.EndDate),
      universe: data.Population,
      sample: data.Sample,
      copyright: data.Copyright,  
      //  also data.Year
    }
  }

  // extract measures
  private processMeasures(measures: any): Measure[] {
    if (!measures) return [];
    let data = [];
    measures.forEach(element => {
      data.push({
        id: element.ID,
        name: element.Name
      })
    });
    return data;
  }


  // extract locations
  private processLocations(locations: any): Location[] {
    if (!locations) return [];
    let data = [];
    locations.forEach(element => {
      data.push({
        id: element.ID,
        name: element.Name
      })
    });
    return data;
  }

  // extract dayparts and daypart definitions
  private processDayparts(dayparts: any): Daypart[] {

    if (!dayparts) return [];

    // fetch dayparts
    let data: Daypart[] = []
    dayparts.forEach(element => {
      data.push({
        id: element.ID,
        description: element.Description,
        numQhrs: element.NumQhrs,
        isPlanning: element.isPlanning,
        isSocioPlanning: element.isPlanningForSocioDemo,
        definition: []
      });

      // copy out the definitions array
      let d = data[data.length-1];
      element.definition.forEach(element => {
        d.definition.push({
          startDay: element.StartDay,
          endDay: element.EndDay,
          startQhr: element.StartQhr,
          endQhr: element.EndQhr
        });
      });
    });
    return data;
  }

  // stations
  private processStations(stns: any, formats: any, affiliations: any, networks: any): Station[] {

    if (!stns) return [];
    let stations: Station[] = []

    stns.forEach(element => {
      stations.push({
        id: element.ID,
        callLetters: element.CallLetters,
        name: `${element.CallLetters} (${element.Frequency})`,
        band: element.Band,
        frequency: element.Frequency,
        data: element.Data,
        formats: formats ? formats.filter( f => f.ID == element.StationFormat ).map( f=> { return { id: f.ID, description: f.Description } } ) : [],
        networks: []
      });

      // build the networks array using the affiliations table
      if (affiliations && networks){
        let curr = stations[stations.length-1];

        let aff = affiliations.filter( a => a.StationId === curr.id); // get all affiliations of the current stn
        aff.forEach( a => { 
           let network = networks.find( net => net.ID == a.NetworkId ); // match the network aff.ids in the network array
           if (network) curr.networks.push({ id: network.ID, code: network.Code, description: network.Description });
        });
      }

    });

    return stations;
  }

  // full demographic tree
  private processSocioDemographics(socioDemos): DemographicSocioNode[] {
    let tree = []
    if (!socioDemos) return tree;

      socioDemos.forEach(element => {
        tree.push({
          id: element.ID,
          name: element.Name,
          code: "",
          children: []
        });

        let child = tree[tree.length-1].children;
        element.Values.forEach(element => {
          child.push({
            name: element.text,
            code: element.value,
          })
          
        });
        
      });
    return tree;
  }

  private processAgeSexDemographics(ageDemos): DemographicAgeSexNode[] {

    let tree = []
    if (!ageDemos) return tree;

    ageDemos.forEach(element => {
      tree.push({
        name: element.Code,
        code: element.Code,
        ageFrom: element.AgeFrom,
        ageTo: element.AgeTo,
        sex: element.Sex,
      })
    });

    return tree;
  }

  private uniq(a) {
    var seen = {};
    return a.filter(function(item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

  private processDemographicLookup(demographics: any[]): Demographic[]{

    if (!demographics) return [];

    let lookup: Demographic[] = [];
    for (let i=0; i < demographics.length; i++) {
      lookup.push({
        universe: demographics[i].Population || 0,
        sample: demographics[i].Sample || 0,
        id: demographics[i].ID,
        ageSexCode: demographics[i].AgeSexCode,
        socEconGroupID: demographics[i].SocEconGroupID,
        socEconGroupValue: demographics[i].SocEconGroupValue
      });
    }
    return lookup;
  }

  getTargetUniverse(market: string | MarketMetrics, target: Target): any {

    let res = { universe: 0, sample: 0, demographicIds: [] }
    let ages = target.age.map(age => age.code);
    let groupId = target.socio ? target.socio.id : 0;
    let groupValues: number[] = target.socio ? target.socio.children.map( ids =>  parseInt(ids.code) ) : [0]

    let mkt = (typeof market == "string") ? this.getMarket(market) : market;

    for (let i=0; i < mkt.demographicLookup.length; i++){

      let demo = mkt.demographicLookup[i];
      if (!ages.includes(demo.ageSexCode)) continue; // ages dont match so skip

      if (groupId == demo.socEconGroupID && groupValues.includes(demo.socEconGroupValue)) {

           res.universe += demo.universe;
           res.sample += demo.sample;
           res.demographicIds.push(demo.id);
        }
    }
    return res;
  }

  // return quarter hour ratings for each daypart, station combination 
  getDaypartAvgQtrHourRatingsList( market: MarketMetrics, target: Target, dayparts: Daypart[], stations: Station[] ): any[] { 

    let stationDaytpartRatings = [];
    stations.forEach( stn => {
      dayparts.forEach( daypart => {

        // ratings for this station daypart combo
        const ratings = this.getDaypartAvgQtrHourRatings( market, daypart, target, [stn] );
        stationDaytpartRatings.push({
          stationId: stn.id,
          daypartId: daypart.id,
          ratings: ratings
        })
      })
    })
    return stationDaytpartRatings;
  }

  // quarter hour ratings for the given dayparts using the station array ( as decimal )
  getDaypartAvgQtrHourRatings( market: MarketMetrics, daypart: Daypart, target: Target, stations: Station[] ): number {

    let ratings = 0;
    stations.forEach( stn => {
      let value = this.getStationData(market, stn, 1, 1, daypart.id, target);
      ratings += value;
    })

    // get the universe for this market
    const universe = target.marketData.find( m=> m.marketFilename === market.market.marketFilename).universe;
    return universe ? (ratings / universe) : 0;
  }

  getStationsData(market: string | MarketMetrics,  stations: Station[], measureIds: number[], locationId: number, daypart: Daypart, target: Target): any {

    // for each station, for each measureId get the value for the given daypart and target
    // object returned:  [ { [stnId][measure_Id]: value } ]
    let mkt = (typeof market == "string") ? this.getMarket(market) : market;
    let result = {}

    stations.forEach( stn => {

      result[stn.id] = {}
      measureIds.forEach( id => {
        let value = this.getStationData(mkt, stn, id, locationId, daypart.id, target);
        result[stn.id]['measure' + id] = value;
      })

    });

    return result
  }

  // using the inputs, return the corressponding value from the station.data array. demoIds is an array as returned values can be summed
  getStationData(market: string | MarketMetrics,  station: Station, measureID: number, locationId: number, daypartID: number, target: Target): number {

    let mkt = (typeof market == "string") ? this.getMarket(market) : market;
    let filtered: DataTable[];

    // qtr only table already exists
    if (measureID == 1 && locationId == 1){
      filtered = mkt.dataTableQtrHours.filter( data => {
        return (data.DaypartID == daypartID &&
                target.demographicIds.includes(data.DemographicID));
      });
    }
    else { // use full table
      filtered = mkt.dataTable.filter( data => {
        return (data.MeasureID == measureID && 
                data.LocationID == locationId &&
                data.DaypartID == daypartID &&
                target.demographicIds.includes(data.DemographicID));
      });
    }

    // array of filtered, need all the index properties lookedup in the station.data and summed
    let total = 0;
    filtered.forEach( data => {
      total += station.data[data.Index];
    })

    return total
  }

  // search the daypart array for the daypart of the required quarter hours.  Returns the 1st it finds only.
  getDaypart(market: string | MarketMetrics, numQhrs: number): Daypart {

    let mkt = (typeof market ==="string")? this.getMarket(market): market;
    let dp = mkt.allPlanningDayparts.filter( daypart => {
       return daypart.numQhrs == numQhrs
    });

    return dp.length ? dp[0] : null;
  }


  // clear out the markets [] 
  clearMarkets() {
    this.markets = []
  }

  deleteMarket(marketFilename: string){
    const idx = this.markets.findIndex( market => market.market.marketFilename === marketFilename )
    if (idx !== -1) this.markets.splice(idx, 1);
  }

  // get the market using the filename as the identifier
  getMarket(marketFilename: string): MarketMetrics {
    return this.markets.find( market => market.market.marketFilename === marketFilename )
  }

  // get the current market hardcoded to first in array for now
  getFirst(): MarketMetrics {
    return this.markets.length ? this.markets[0] : null
  }

  // get market based on filename (unique)
  get(filename: string): MarketMetrics {
    return this.markets.find( mkt=> mkt.market.marketFilename === filename);
  }
}
