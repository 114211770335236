<client-area areatitle="Planning" subtitle="Schedule planning and editing" icon="post_add">

    <tab-navigation header current="planning"></tab-navigation>
    <summary-breadcrumbs breadcrumbs [values]="campaignSummary"></summary-breadcrumbs>

    <div menu>
        <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="menu_file">File</button>
<!--            <button mat-menu-item [matMenuTriggerFor]="menu_schedules">Schedules</button>  -->
            <button mat-menu-item [matMenuTriggerFor]="menu_select">Select</button>
            <button mat-menu-item [matMenuTriggerFor]="menu_view">View</button>
            <mat-divider></mat-divider>
            <button mat-menu-item><mat-icon>help_outline</mat-icon>Help</button>
            
        </mat-menu>

        <mat-menu #menu_file="matMenu">
            <button mat-menu-item (click)="onNewDocument()">New...</button>
            <mat-divider></mat-divider>
            <button (click)="onSaveDocument(false)" mat-menu-item>Save</button>
            <button (click)="onSaveDocument(true)" mat-menu-item>Save As..</button>
            <mat-divider></mat-divider>
            <button (click)="onDAUExport()" mat-menu-item>Export Media Mix File</button>
        </mat-menu>

        <mat-menu #menu_select="matMenu">
            <button (click)="onPreferredDaypartSelection()" mat-menu-item>Select Dayparts</button>
            <button (click)="onPreferredStationSelection()" mat-menu-item>Select Stations</button>
            <button (click)="onEffectiveReachLevelChange()" mat-menu-item>Effective Reach</button>
        </mat-menu>

        <mat-menu #menu_view="matMenu">
            <button (click)="onFrequencyDistribution()"  mat-menu-item>Frequency distribution</button>
            <button (click)="onNTiles()"  mat-menu-item>N-Tiles</button>
        </mat-menu>
    </div>

    <!-- Main title showing current market and schedule-->
    <div class="main-title">
        <button mat-icon-button color="primary" aria-label="Previous Market" (click)="onNavigateMainTitle(-1)">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>      
        {{mainTitle}}
        <button  mat-icon-button color="primary" aria-label="Previous Market" (click)="onNavigateMainTitle(1)">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>      
    </div>

    <!-- campaign summary panel -->
    <campaign-summary [values]="campaignSummaryData"></campaign-summary>
    <mat-progress-bar value="0" [mode]="processing? 'indeterminate' : 'determinate'"></mat-progress-bar>

    <!-- build market tabs -->
    <div class="Tabs-container">
        <mat-tab-group [selectedIndex]="currentMarket"
                    (selectedIndexChange)="onCurrentMarketChange($event)">
        <mat-tab *ngFor="let tab of marketTabs" [label]="tab.name"></mat-tab>
        </mat-tab-group>    
    </div>
    
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3>Daypart and Station Planning</h3>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- schedule tabs -->
        <mat-tab-group [selectedIndex]="currentSchedule" id="schedule-tabs"
                (selectedIndexChange)="onScheduleChange($event)">
            <mat-tab *ngFor="let tab of scheduleTabs">
                <ng-template mat-tab-label style="display:flex; justify-content:space-between; width:100%;">
                    <div fxFlex="grow"> {{tab.name}} </div>
                    <div fxFlex="nogrow">
                        <button mat-icon-button (click)="onScheduleRename(tab.id)"><mat-icon color="primary">create</mat-icon></button>
                        <button mat-icon-button (click)="onScheduleDelete(tab.id)"><mat-icon style="color:red;">remove_circle_outline</mat-icon></button>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab disabled>
                <ng-template mat-tab-label>
                    <button mat-icon-button (click)="onScheduleAdd()"><mat-icon color="primary">add_circle</mat-icon></button>
                    <button mat-icon-button (click)="onOptimisationClick()"><mat-icon color="primary">calculate</mat-icon></button>
                </ng-template>
            </mat-tab>            
        </mat-tab-group>

        <!-- optimisation notes -->
        <mat-card style="padding: 0px" *ngIf="hasOptimisation()" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="onOptimisationClick()"><mat-icon>calculate</mat-icon></button>
            <div *ngFor="let message of optimisationMessages">
                &nbsp;&nbsp;&nbsp;&bull; {{message}} 
            </div>
        </mat-card>
              

        <div class="schedule-options">
            <!-- plan all markets checkbox -->
            <mat-slide-toggle
                color="primary"
                [disabled]="planAcrossMarketsDisabled()"
                [checked]="planAcrossMarkets"
                (change)="onPlanAcrossMarketsChange($event.checked)">
                Plan across markets
            </mat-slide-toggle>
        </div>

        <!-- button groups -->
        <mat-button-toggle-group #planningMethod="matButtonToggleGroup" value="{{ getPlanningMethod() }}" aria-label="Planning Type">
            <mat-button-toggle matTooltip="Generic Planning" (click)="onPlanningMethodChange()" value="generic"><mat-icon>tab_unselected</mat-icon>&nbsp; Generic Planning</mat-button-toggle>
            <mat-button-toggle matTooltip="Market / Station Specific Planning" (click)="onPlanningMethodChange()" value="bystation"><mat-icon>tab</mat-icon>&nbsp; Market / Station Specific</mat-button-toggle>
        </mat-button-toggle-group>  

        <div class="controls-container">

            <!-- targets dropdown -->
            <mat-form-field>
                <mat-label>Targets</mat-label>
                <mat-select (selectionChange) = "onTargetChange($event)" [(value)]="currentTarget">
                <mat-option *ngFor="let tgt of planningTargets" value="{{tgt.id}}">{{tgt.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Show for generic planning -->
            <div style="margin-left: 5px;" [hidden]="planningMethod.value !== 'generic'">
                <!-- N Station Selection-->
                <mat-form-field>
                    <mat-label>Select Top n Stations</mat-label>
                    <mat-select [(ngModel)]="nStationsSelected"
                                (ngModelChange)="onNStationsChange()">
                        <mat-option *ngFor="let n of nStations" [value]="n.value">
                        {{n.text}} 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Show for by station planning -->
            <div style="margin-left: 5px;" [hidden]="planningMethod.value !== 'bystation'">

                <!-- station dropdown -->
                <mat-form-field>
                    <mat-label>Stations</mat-label>
                    <mat-select (selectionChange)="onStationChange($event)" [(value)]="currentStation">
                    <mat-option *ngFor="let stn of getScheduleStations()" value="{{stn.id}}">{{stn.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button (click)="onNavigate(['stations'] )"><mat-icon>radio</mat-icon></button>
            </div>

            <!--NumWeeks Selection-->
            <div style="margin-left:5px;">
                <mat-form-field>
                    <mat-label>Campaign week count</mat-label>
                    <mat-select [(ngModel)]="numWeekSelected"
                                (ngModelChange)="onNumWeeksChange()">
                    <mat-option *ngFor="let n of numWeekCounts" [value]="n.value">
                        {{n.text}} 
                    </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- single/ all weeks -->
                <mat-button-toggle-group #planningWeekPeriod="matButtonToggleGroup" value="{{ getPlanningWeekPeriod() }}" aria-label="Planning Week Type">
                    <mat-button-toggle matTooltip="Single Week" (click)="onPlanningWeekPeriodChange()" value="singleweek"><mat-icon>filter_1</mat-icon></mat-button-toggle>
                    <mat-button-toggle matTooltip="Total Campaign" (click)="onPlanningWeekPeriodChange()" value="allweeks"><mat-icon>library_books</mat-icon></mat-button-toggle>
                </mat-button-toggle-group> 
            </div>
       </div>
 
    <schedules-grid #scheduleGrid (tableChange)="onScheduleGridChange($event)"
                                  (calculating)="onCalculating($event)"
                                  (export)="onScheduleExport($event)"></schedules-grid>
        
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
            <h3>Campaign Totals</h3>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- multi multi totals expansion grid -->
        <div class="grid-container">
            <totals-multi-market-grid class="grid-container" #totalsGridMultiMarket></totals-multi-market-grid>
        </div>

        <!-- single market totals results grid -->
        <!-- <div class="grid-container">
            <totals-grid class="grid-container" #totalsGrid></totals-grid>
        </div> -->
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
            <h3>Campaign Graphing</h3>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

            <totals-graphing #totalsGraphing></totals-graphing>
       <!--     <freq-dist #totalsFreqDist></freq-dist>  -->
    </mat-expansion-panel>


</client-area>