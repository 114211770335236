import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MarketMetrics } from '../models/market-information.model';
import { environment } from '../../../environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { Target, Station, Daypart, ScheduleEvaluation, ScheduleResult, ScheduleResultPeriod } from '../models/campaign.model';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  constructor( private apiService: ApiService ) { }

  frequencyDistribution(request: any): Observable<any> {
    let options = { body: request }
    return this.apiService.request("POST", environment.api.data.url, environment.api.data.endPoint.distribution, options);
  }
  nTiles(request: any): Observable<any> {
    let options = { body: request }
    return this.apiService.request("POST", environment.api.data.url, environment.api.data.endPoint.nTiles, options);
  }

  evaluate(request: any): Observable<any> {
    let options = { body: request }
    return this.apiService.request("POST", environment.api.data.url, environment.api.data.endPoint.evaluate, options);
  }

  optimisation(request: any): Observable<any> {
    let options = { body: request }
    return this.apiService.request("POST", environment.api.data.url, environment.api.data.endPoint.optimisation, options);
  }

  exportDAU(request: any): Observable<any> {
    let options = { body: request }
    return this.apiService.request("POST", environment.api.data.url, environment.api.data.endPoint.exportDAU, options);
  }

  // build a proper object from the json result of the service
  getEvaluatonResponse(data: any): ScheduleResult {

    let res: ScheduleResult = {

      weekly: {
        stationDayparts: [],
        stations: [],
        dayparts: [],
        scheduleReach: data.ScheduleReachSingleWeek || 0,
        scheduleEffectiveReach: data.EffectiveReaches ? data.EffectiveReaches.ScheduleReachSingleWeek || 0 : 0,
        scheduleImpacts: 0,
        scheduleSpots: 0,
      },
      total: {
        stationDayparts: [],
        stations: [],
        dayparts: [],
        scheduleReach: data.ScheduleReach || 0,
        scheduleEffectiveReach: data.EffectiveReaches ? data.EffectiveReaches.ScheduleReach || 0 : 0,
        scheduleImpacts: 0,
        scheduleSpots: 0,
      }
    }

    // station by daypart reaches
    const effStationDayPartReachesSingleWeek = data.EffectiveReaches ? data.EffectiveReaches.StationDayPartReachesSingleWeek : null;
    const effStationDayPartReaches = data.EffectiveReaches ? data.EffectiveReaches.StationDayPartReaches : null;

    if (data.StationDayPartReachesSingleWeek) this.processResponseStationDayparts(data.StationDayPartReachesSingleWeek, effStationDayPartReachesSingleWeek, res.weekly);
    if (data.StationDayPartReaches) this.processResponseStationDayparts(data.StationDayPartReaches, effStationDayPartReaches, res.total);

    // station reaches
    const effStationReachesSingleWeek = data.EffectiveReaches ? data.EffectiveReaches.StationReachesSingleWeek : null;
    const effStationReaches = data.EffectiveReaches ? data.EffectiveReaches.StationReaches : null;
    
    if (data.StationReachesSingleWeek) this.processResponseStationReaches(data.StationReachesSingleWeek, effStationReachesSingleWeek, res.weekly);
    if (data.StationReaches) this.processResponseStationReaches(data.StationReaches, effStationReaches, res.total);

    //daypart reaches
    const effDaypartReachesSingleWeek = data.EffectiveReaches ? data.EffectiveReaches.DaypartReachesSingleWeek : null;
    const effDaypartReaches = data.EffectiveReaches ? data.EffectiveReaches.DaypartReaches : null;

    if (data.DaypartReachesSingleWeek) this.processResponseDaypartReaches(data.DaypartReachesSingleWeek, effDaypartReachesSingleWeek, res.weekly);
    if (data.DaypartReaches) this.processResponseDaypartReaches(data.DaypartReaches, effDaypartReaches, res.total);

    return res;
  }

  // *** Called from getEvaluatonResponse to process the reaches into usable objects ***
  // process station daypart reaches
  private processResponseStationDayparts(data: any, effectiveReach: any, res: ScheduleResultPeriod) {

    let stationIds = Object.keys(data);
    stationIds.forEach( stn =>  {

      let dayparts = Object.keys(data[stn]);
      dayparts.forEach( dp=> {

        res.stationDayparts.push({
          stationId: parseInt(stn),
          daypartId: parseInt(dp),
          reach: data[stn][dp],
          effectiveReach: effectiveReach ? effectiveReach[stn][dp] : 0,
          impacts: 0,
          spots: 0,
        })
      })
    })
  }

  // process total station reaches
  private processResponseStationReaches(data: any, effectiveReach: any, res: ScheduleResultPeriod) {

    let stationIds = Object.keys(data);
    stationIds.forEach( stn => {
       res.stations.push({
        stationId: parseInt(stn),
        reach: data[stn],
        effectiveReach: effectiveReach ? effectiveReach[stn] : 0,
        impacts: 0,
        spots: 0,
      })
    })
  }

  // daypart total total reaches
  private processResponseDaypartReaches(data: any, effectiveReach: any, res: ScheduleResultPeriod) {
    let daypartIds = Object.keys(data);
    daypartIds.forEach( dp=> {
      res.dayparts.push({
        daypartId: parseInt(dp),
        reach: data[dp],
        effectiveReach: effectiveReach ? effectiveReach[dp] : 0,
        impacts: 0,
        spots: 0,
      })
    })
  }

  // *** above functions all called from getEvaluatonResponse *** 


  // build a valid reach and frequncy request object (also used for DAU exporting request)
  getEvaluationRequest(market: MarketMetrics, allStations: Station[], dayparts: Daypart[], schedule: ScheduleEvaluation): any {

    let req = {
      DaypartAQHs: {},
      DaypartCumes: {},
      Population: schedule.universe,
      numWeeks: schedule.numWeeks,
      stationRequested: -1,
      daypartRequested: -1,
      EffectiveReachLevel: schedule.effectiveReachLevel,
      StationReaches: null,
      Insertions: {}
    }

    let daypartIds = dayparts.map( dp=> "" + dp.id); // make string[]

    // use passed in stations as we want all stations here
    allStations.forEach( stn => {
        let stationId = "" + stn.id;

        let values = this.getStationEvaluationData(market, stn, daypartIds, schedule.target.demographicIds);
        req.DaypartAQHs[stationId] = values[1];
        req.DaypartCumes[stationId] = values[2];

      });

      // build schedule from stations inside the SchedulePlan interface
      schedule.plan.forEach( plan => {
        req.Insertions[ ""+ plan.stationId ] = req.Insertions[ ""+ plan.stationId ] || {}
        req.Insertions[ "" + plan.stationId ][ "" + plan.daypartId] = plan.spots;
      });

    return req;
  }

  // called by getEvaluationRequest to get the qhr and cumes from the station.data 
  public getStationEvaluationData(market: MarketMetrics, station: Station, daypartIds: string[], demographicIds: number[]): any[] {

    let res = [ null, {}, {} ];
    daypartIds.forEach( dp=> { 
      res[1][dp] = 0;  // avg storage measureId=1
      res[2][dp] = 0;  // cume storage measureId=2
    });

    for (let i=0; i < market.dataTable.length; i++){
      let data = market.dataTable[i];

      // either avg Qtr Hour or cume and location Total (Home & Away)
      if ((data.MeasureID == 1 || data.MeasureID == 2) && (data.LocationID == 1)) {

        // found a demo id we want
        if (demographicIds.includes(data.DemographicID) && (daypartIds.includes( "" + data.DaypartID))) {
          res[data.MeasureID]["" + data.DaypartID] += station.data[data.Index];
        }

      }
    }
    return res;
  }

  // calculate impressions using the R&F request object (using station and daypart ratings)  Result written back to schedule object
  calculateImpacts( data: any, schedule: ScheduleEvaluation ): void {

    if ( !data.Insertions || !data.DaypartAQHs ) return;

    // spots are always weekly only so need to multiply up when calculating the total week values
    const multiplier =  schedule.numWeeks;

    let stationIds = Object.keys( data.Insertions ); // stations to work with
    let stnImpacts = {}
    let stnSpots = {}
    let stnDpImpacts = {}
    let stnDpSpots = {}
    let dpImpacts = {}
    let dpSpots = {}
    let totalImpacts = 0;
    let totalSpots = 0;

    // for each station in the insertions object
    let impacts = 0;
    let spots = 0;
    let ins = 0;

    stationIds.forEach( stn => {
      impacts = 0;
      spots = 0;

      let daypartIds = Object.keys( data.Insertions[stn] ); // dayparts planned on within this station
      daypartIds.forEach( dp => {

          ins = data.Insertions[stn][dp];

          impacts += (data.DaypartAQHs[stn][dp] * ins );
          spots += ins;

          stnDpImpacts[ stn+"_"+dp ] = stnDpImpacts[ stn+"_"+dp ] || 0;
          stnDpImpacts[ stn+"_"+dp ] += (data.DaypartAQHs[stn][dp] * ins );
          stnDpSpots[ stn+"_"+dp ] = stnDpSpots[ stn+"_"+dp ] || 0;
          stnDpSpots[ stn+"_"+dp ] += ins;

          dpImpacts[ ""+dp] = dpImpacts[ ""+dp] || 0;
          dpImpacts[ ""+dp] += (data.DaypartAQHs[stn][dp] * ins );
          dpSpots[ ""+dp] = dpSpots[ ""+dp] || 0;
          dpSpots[ ""+dp] += ins;
        });

        stnImpacts[stn] = impacts;
        stnSpots[stn] = spots;
        totalImpacts += impacts;
        totalSpots += spots;
    })

    // copy to schedule results object WEEKLY
    schedule.result.weekly.stationDayparts.forEach( stndp=> { 
      stndp.impacts = stnDpImpacts[ stndp.stationId +"_"+ stndp.daypartId ];
      stndp.spots = stnDpSpots[ stndp.stationId +"_"+ stndp.daypartId ];
    }); // station dayparts

    // copy to schedule results object TOTAL
    schedule.result.total.stationDayparts.forEach( stndp=> { 
      stndp.impacts = stnDpImpacts[ stndp.stationId +"_"+ stndp.daypartId ] * multiplier;
      stndp.spots = stnDpSpots[ stndp.stationId +"_"+ stndp.daypartId ] * multiplier;
    }); // station dayparts


    // dayparts WEEKLY
    schedule.result.weekly.dayparts.forEach( dp => {
      dp.impacts = dpImpacts[ ""+dp.daypartId];
      dp.spots = dpSpots[""+dp.daypartId];
    })

    // dayparts TOTAL
    schedule.result.total.dayparts.forEach( dp => {
      dp.impacts = dpImpacts[ ""+dp.daypartId] * multiplier;
      dp.spots = dpSpots[""+dp.daypartId] * multiplier;
    })

    // station totals WEEKLY
    schedule.result.weekly.stations.forEach( stn => {
      stn.impacts = stnImpacts[ "" + stn.stationId ];
      stn.spots = stnSpots["" + stn.stationId ];
   }); 

    // station totals TOTAL
    schedule.result.total.stations.forEach( stn => {
       stn.impacts = stnImpacts[ "" + stn.stationId ] * multiplier;
       stn.spots = stnSpots["" + stn.stationId ] * multiplier;
    }); 

    //WEEKLY
    schedule.result.weekly.scheduleImpacts = totalImpacts;  // schedule total 
    schedule.result.weekly.scheduleSpots = totalSpots; // applied equally between each station so need to divide

    //TOTAL
    schedule.result.total.scheduleImpacts = totalImpacts * multiplier;  // schedule total
    schedule.result.total.scheduleSpots = totalSpots * multiplier; // applied equally between each station so need to divide
  }

}
