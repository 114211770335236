import { Injectable } from '@angular/core';

export interface Preferences {
    preferredDayparts: number[];
    selectedNStations: number;
    UI: UIPreferences;
}

export interface UIPreferences {
    stationColumns: string[],
    scheduleColumns: string[],
    totalsColumns: string[],
    ratingsFormat: string,
    floatFormat: string,
    wholeFormat: string,
    costFormat: string;

    ratingsDecimals: number,
    floatDecimals: number,
    wholeDecimals: number,
    costDecimals: number;
}

@Injectable({
    providedIn: 'root'
})
export class UserPreferences {

    user: Preferences;
    constructor() {

        this.user = {
            preferredDayparts: [64, 65, 66, 67, 79],
            selectedNStations: 3,
            UI: {
                stationColumns: [],
                scheduleColumns: [],
                totalsColumns: [],
                ratingsFormat: '1.2-2',
                floatFormat: '1.1-1',
                wholeFormat: '1.0-0',
                costFormat: '1.2-2',
                ratingsDecimals: 2,
                floatDecimals: 1,
                wholeDecimals: 0,
                costDecimals: 2,
            }
        }
        this.loadFromStorgae();
    }

    loadFromStorgae() {
        if ( typeof localStorage !=='undefined' ) {
            let data = localStorage.getItem('userPrefs');
            if (data) {
                const newData = JSON.parse(data);

                this.user.preferredDayparts = newData.preferredDayparts || this.user.preferredDayparts;
                this.user.selectedNStations = newData.selectedNStations || this.user.selectedNStations;
                this.user.UI.stationColumns = newData.stationColumns || this.user.UI.stationColumns;
                this.user.UI.scheduleColumns = newData.scheduleColumns || this.user.UI.scheduleColumns;
                this.user.UI.totalsColumns = newData.totalsColumns || this.user.UI.totalsColumns;
                this.user.UI.floatFormat = newData.floatFormat || this.user.UI.floatFormat;
                this.user.UI.wholeFormat = newData.wholeFormat || this.user.UI.wholeFormat;
                this.user.UI.costFormat = newData.costFormat || this.user.UI.costFormat;
                this.user.UI.ratingsDecimals = newData.ratingsDecimals || this.user.UI.ratingsDecimals;
                this.user.UI.floatDecimals = newData.floatDecimals || this.user.UI.floatDecimals;
                this.user.UI.wholeDecimals = newData.wholeDecimals || this.user.UI.wholeDecimals;
                this.user.UI.costDecimals = newData.costDecimals || this.user.UI.costDecimals;
            }
        }
    }

    saveToStorage() {
        if ( typeof localStorage !=='undefined' ) {
            localStorage.setItem('userPrefs', JSON.stringify(this.user));
        }
    }
}