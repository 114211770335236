<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
  <mat-form-field>
    <mat-label>
      {{valueName}}
    </mat-label>
    <input matInput type="text" [(ngModel)]="inputValue" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{confirmButtonText}}</button>
  <button mat-raised-button color="primary" (click)="onDismiss()">{{dismissButtonText}}</button>
</div>
