import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { TupAuthService, UserDetails, NULL_USER_DETAILS, AuthEvent } from '@telmar-global/tup-auth';

import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-bug-reporter',
  templateUrl: './bug-reporter.component.html',
  styleUrls: ['./bug-reporter.component.scss']
})
export class BugReporterComponent implements OnInit, OnDestroy {

  public bugForm: FormGroup;

  public userDetails: UserDetails = NULL_USER_DETAILS;

  private subscriptionAuthEvents : Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authService: TupAuthService,
    private userMessage: TupUserMessageService,
  ) { }

  ngOnInit(): void {
    this.createBugForm();
    this.subscriptionAuthEvents = this.authService.authEvents.subscribe( e => {
      if( AuthEvent.AuthEventUserDataChanged == e ) {
        if( this.authService.user ) this.userDetails = this.authService.user;
      }
    })
  }

  ngOnDestroy() {
    this.subscriptionAuthEvents.unsubscribe();
  }

  private createBugForm(): void {
    this.bugForm = this.formBuilder.group({
      subject: [null, Validators.required],
      content: [null, Validators.required],
    });
  }

  OnSubmit() {
    let subject = this.bugForm.value.subject;
    let content = this.bugForm.value.content;
    let emailAttr = this.userDetails.attributes['email'];
    let email = emailAttr ? emailAttr.value : 'n/a';
    let username = this.userDetails.username;
    let customer = this.userDetails.customer;

    const dataArray: Object[] = [
      {'name': 'hs_pipeline', 'value': '0'},
      {'name': 'hs_pipeline_stage', 'value': '1'},
      {'name': 'subject', 'value': subject},
      {'name': 'content', 'value': `App: Audio\n\nSubmitted By: ${username}\nEmail: ${email}\nCompany: ${customer} \n\nDescription: ${content}`}
    ]

    // Bug Dashboard API endpoint
    this.http.post<any>('https://bugdash-api-telmar.herokuapp.com/bugs', JSON.stringify(dataArray)).subscribe(data => {
      console.log(data)
      switch(data.message){
        case 'done':
          this.userMessage.showSnackBar('Feedback submitted successfully', 'OK');
          break;

        default:
          this.userMessage.showSnackBar('Please try again', 'OK');
      }
    })
  }
}
