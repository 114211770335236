    <mat-button-toggle-group #viewSelection="matButtonToggleGroup" value="graph">
      <mat-button-toggle value="table" aria-label="View as Table">
        <mat-icon>list_alt</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="graph" aria-label="view as Graph">
        <mat-icon>bar_chart</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  
    <div>Frequency Distribution {{viewSelection.value}}</div>
  
    <div class="table-container" [hidden]="viewSelection.value == 'graph'">
      <mat-table [dataSource]="dataTable" dense>
  
        <!-- Generic column definition -->
        <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
          <mat-header-cell [ngClass]="column.css" *cdkHeaderCellDef>{{ column.header }} </mat-header-cell>
  
          <!-- string Column -->
          <div *ngIf="column.columnType=='string'">
            <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
          </div>
  
          <!-- value Column -->
          <div *ngIf="column.columnType=='value'">
            <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) | number: column.format }}
            </mat-cell>
          </div>
  
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
      </mat-table>
    </div>
  
    <div class="graph-container" [hidden]="viewSelection.value == 'table'">
      <tup-chart [type]="chartType" [data]="chartData" [options]="chartOptions" ></tup-chart>
    </div>
