import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../../shared/services/campaign.service';
import { Market, Target } from '../../../shared/models/campaign.model';
import { CampaignSchedule } from '../../../shared/classes/campaign-schedule';
import { TupChartOptions, TupChartData, TupChartDataSeries, TupChartAxisOptions } from '../../../shared/components/ng-chart/ng-chart.component';
import { MarketsService } from 'src/app/shared/services/markets.service';
import { FreqDistValues } from 'src/app/shared/components/freq-dist/freq-dist.component';
import { PlanningService } from 'src/app/shared/services/planning.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { PlanningMethod } from 'src/app/shared/classes/campaign-schedule-plan';
import { CampaignMarketSchedule } from 'src/app/shared/classes/campaign-market-schedule';
import { TupProgressDialogType } from '@telmar-global/tup-user-message';

@Component({
  selector: 'totals-graphing',
  templateUrl: './totals-graphing.component.html',
  styleUrls: ['./totals-graphing.component.scss']
})
export class TotalsGraphingComponent implements OnInit {

  market: Market;
  target: Target;
  schedule: CampaignSchedule;

  //main chart
  chartViewOptions: any[] 
  chartViewSelected: any;

  chartOptions: any;
  chartData: any;

  //pie
  pieChartTitle: string;
  pieChartData: any;
  pieChartOptions: any;

  // Frequency distribution
  distribution: FreqDistValues;
  
  constructor( private campaignService: CampaignService,
               private helperService: HelperService,
               private planningService: PlanningService ) {

    this.chartViewOptions = [
      { name: "Spots", field: "spots", decimals: 0 },
      { name: "GRPs", field: "grps", decimals: 1 },
      { name: "Reach %", field: "reachPct", decimals: 2 },
      { name: "Frequency", field: "avgFreq", decimals: 1 },
      { name: "Net Reach [00]", field: "reach00", decimals: 0 },
      { name: "Impressions [00]", field: "impacts", decimals: 0 },
      { name: "Effective Reach %", field: "effReachPct", decimals: 2 },
      { name: 'CPP $', field: 'cpp', decimals: 2	},
      { name: 'Cost $', field: 'totalCost', decimals: 2 },
      { name: 'CPM $', field: 'cpm', decimals: 2	},
      { name: 'CPS', field: 'cps', decimals: 2 },
    ]
    this.chartViewSelected = this.chartViewOptions[2].field;  // start reach%  .find( x=> x.field=='reachPct').field
    this.distribution = { universe: 0, distribution: [] }
   }

  ngOnInit() {
  }

  onChartViewChange() {
    this.loadChart(this.market, this.schedule, this.chartViewSelected );
  }

  loadData( market: Market, target: Target, schedule: CampaignSchedule, reachChanged: boolean ) {

    this.market = market;
    this.target = target;
    this.schedule = schedule;

    this.loadChart( market, schedule, this.chartViewSelected );
    this.loadPie( market, target );
    this.loadFreq( market, target, schedule, reachChanged );
  }

  // populate the main chart
  loadChart( market: Market, schedule: CampaignSchedule, field: string ): any { 

    const view = this.chartViewOptions.find( o => o.field == field);
    const barColors:string [] = ['rgba(135, 175, 247, 0.9)', 'rgba(87, 142, 244, 0.9)', 'rgba(39, 110, 241, 0.9)', 'rgba(14, 85, 216, 0.9)']
    
    // build options
    this.chartOptions = { 
      legend: { display: true },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {

            const label = (data.datasets[tooltipItem.datasetIndex].label || '');
            const value = (tooltipItem.yLabel).toLocaleString(undefined, { maximumFractionDigits: view.decimals, minimumFractionDigits: view.decimals } );

            return label + `, ${view.name}: ${value}`; 
          }
        },
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Station",
          },
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: view.name
          },
        }]
      }
    }

    // build data
    this.chartData = {
      labels: [],
      datasets: []
    }

    const scheduleMarket = schedule.market(market.marketFilename);

    // for each target...
    // datasets added upfront with the targetCoding ID to keep track
    let barColorIndex = 0;
    this.campaignService.run.targets.forEach( target => {

      this.chartData.datasets.push({
        backgroundColor: barColors[barColorIndex], 
        label: target.name,
        borderWidth: 2,
        borderColor: 'rgba(10, 60, 152, 0.6)',
        hoverBackgroundColor: 'rgba(10, 60, 152, 0.9)',
        hoverBorderColor: 'rgba(14, 68, 215, 0.9)',
        data: [],
        targetCoding: target.coding,
      })

      barColorIndex = barColorIndex > barColors.length-1 ? 0 : barColorIndex +1;
    })

    // for each station
    scheduleMarket.stations.forEach( stn => {

      this.chartData.labels.push(stn.name);

      if (scheduleMarket.results) {

        this.campaignService.run.targets.forEach( target => {

          const result = scheduleMarket.results.find(res => res.target.coding == target.coding);
          if (result) {
            const dataset = this.chartData.datasets.find( set => set.targetCoding === target.coding);
            const total = scheduleMarket.getScheduleTotals(result.target, stn);
            dataset.data.push(total[view.field]);
          }

        })
      }

    }) // station
  }

  loadPie( market: Market, target: Target){
    // pie
    const fmt = this.helperService.formatFloat;

    const targetResults = target.marketData.find( tgt => tgt.marketFilename === market.marketFilename );
    const perc = (targetResults.universe / market.universe) * 100;
    const part = market.universe - targetResults.universe;

    this.pieChartData = {
      labels: [
        `${target.name} ${fmt( targetResults.universe )}[00] (${fmt( perc )}%)`,
        `${market.marketName} ${fmt( part )}[00] (${fmt( 100-perc )}%)`,
      ],
      datasets: [{
        label: 'Composition',
        data: [targetResults.universe, market.universe],
        backgroundColor: [
          'rgba(39, 110, 241, 0.9)', 
          'rgba(39, 93, 241, 0.7)',
        ],
        borderColor: 'rgba(10, 60, 152, 0.6)',
        hoverOffset: 10,
        hoverBackgroundColor: 'rgba(10, 60, 152, 0.9)',
        hoverBorderColor: 'rgba(14, 68, 215, 0.9)'
      }]
    }

    this.pieChartTitle = `Total Market population for ${market.marketName}: ${fmt(market.universe)}[00]`;
    this.pieChartOptions =  { 
      legend: {
        display: true
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => {
            return "Composition %";
          },
          label: (tooltipItem, data) => {
            let label = (data.labels[tooltipItem.index] || '');
            return label;
          }
        },
      },

    }

  }


  //freq dist
  loadFreq(market: Market, target: Target, schedule: CampaignSchedule, reachChanged: boolean) {

    const scheduleMarket = schedule.market(market.marketFilename);
    if ( scheduleMarket.hasResults() ) {

      const targetResults = scheduleMarket.results.find ( res => res.target.coding == target.coding);
      const distribution = targetResults.total.frequencyDistribution;

      if (reachChanged || !distribution) {  // need to recalc and use fresh results
        this.planningService.frequencyDistribution(market, schedule, target).subscribe( freq => {

          targetResults.total.frequencyDistribution = freq;

          this.distribution = {
            universe: this.target.universe,
            distribution: freq
          }
        })
      }
      else { // use the results we have already
        this.distribution = {
          universe: this.target.universe,
          distribution: distribution
        }
      }
    }

  }

  }
