import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetUserTargetResponseModel, UserTargetDocument } from '../models/usertarget-document.model';
import { ElasticResponseModel, ElasticService } from './elastic.service';

@Injectable({
  providedIn: 'root'
})
export class UserTargetService {

  constructor( private elasticService: ElasticService) { }

  createOrUpdateUserTarget( userTarget: UserTargetDocument): Observable< ElasticResponseModel > {
    return this.elasticService.createOrUpdate( userTarget, environment.api.elastic.endPoint.userTargets, `'${userTarget.target.name}'` );
  }

  deleteUserTarget(id: string ): Observable< ElasticResponseModel > {
    return this.elasticService.delete( id, environment.api.elastic.endPoint.userTargets, 'target' )
  }

  getUserTargets(): Observable< GetUserTargetResponseModel > {

    return this.elasticService.search( environment.api.elastic.endPoint.userTargets ).map (data => {
      return {
        userTargets: data.documents,
        responseTime: data.responseTime
      }
    })
  }
}
