import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'client-area',
  templateUrl: './client-area.component.html',
  styleUrls: ['./client-area.component.scss']
})

export class ClientAreaComponent implements OnInit {

  @Input() areatitle: string;
  @Input() subtitle: string;
  @Input() sectiontitle: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
