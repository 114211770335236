import { Injectable } from "@angular/core";
import { ScheduleEvaluation, Station, Target, OptimisationEvaluation, CampaignScheduleResults, Daypart, Market, TargetMarketData } from "../models/campaign.model";
import { MarketsService } from "./markets.service";
import { EvaluationService } from "./evaluation.service";
import { Observable, of, forkJoin } from "rxjs";
import "rxjs/add/observable/forkJoin";
import { CampaignSchedule } from "../classes/campaign-schedule";
import { DaypartOptimisationCosts } from "../components/dialogs/optimisation-dialog/optimisation-dialog.component";
import { PlanningPeriod } from "../classes/campaign-schedule-plan";
import { CampaignMarketSchedule } from "../classes/campaign-market-schedule";
import { MarketMetrics } from "../models/market-information.model";
import lodash from "lodash";

@Injectable({
  providedIn: "root",
})
export class PlanningService {
  constructor(
    private marketsService: MarketsService,
    private evaluationService: EvaluationService
  ) {}

  // calculate costs for the given schedule and target
  evaluateCosts(
    targets: Target[],
    scheduleMarket: CampaignMarketSchedule
  ): boolean {
    targets.forEach((target) => {
      let result = scheduleMarket.results.find(
        (res) => res.target.coding == target.coding
      );
      if (!result) {
        scheduleMarket.results.push(scheduleMarket.getEmptyResults());
        result = scheduleMarket.results[scheduleMarket.results.length - 1];
      }

      const plan = scheduleMarket.plan.asJson(
        scheduleMarket.planningPeriod,
        scheduleMarket.numWeeks
      );
      this.calculateCosts(plan, result);
    });

    return true;
  }

  // loop through the schedule plan object and build costs based on entry method (cpp, cps, cpm)
  // when grps or impressions are required use the results object
  calculateCosts(plan: any, results: CampaignScheduleResults) {
    let dpCostWeekly = {};
    let dpCostTotal = {};

    let stdpCostWeekly = {};
    let stdpCostTotal = {};

    let stCostWeekly = {};
    let stCostTotal = {};

    let totCostWeekly = 0;
    let totCostTotal = 0;

    let costWeekly = 0;
    let costTotal = 0;

    let stationIds = Object.keys(plan); // sttaions to work with
    stationIds.forEach((stn) => {
      let daypartIds = Object.keys(plan[stn]); // dayparts planned on within this station
      daypartIds.forEach((dp) => {
        // cost to work with
        if (plan[stn][dp].costEntry && plan[stn][dp].costEntry.value) {
          let resWeekly = results.weekly.stationDayparts.find(
            (val) => "" + val.daypartId == dp && "" + val.stationId == stn
          );
          let resTotal = results.total.stationDayparts.find(
            (val) => "" + val.daypartId == dp && "" + val.stationId == stn
          );

          if (!resWeekly) {
            // if the result doesnt exist (no spots) create the object and write the cost in later
            results.weekly.stationDayparts.push({
              stationId: parseInt(stn),
              daypartId: parseInt(dp),
              reach: 0,
              effectiveReach: 0,
              impacts: 0,
              spots: 0,
              cost: 0,
            });
            resWeekly =
              results.weekly.stationDayparts[
                results.weekly.stationDayparts.length - 1
              ];
          }
          if (!resTotal) {
            // if the result doesnt exist (no spots) create the object and write the cost in later
            results.total.stationDayparts.push({
              stationId: parseInt(stn),
              daypartId: parseInt(dp),
              reach: 0,
              effectiveReach: 0,
              impacts: 0,
              spots: 0,
              cost: 0,
            });
            resTotal =
              results.total.stationDayparts[
                results.weekly.stationDayparts.length - 1
              ];
          }

          switch (
            plan[stn][dp].costEntry.field // honour the entry method when getting the cost
          ) {
            case "cpp": {
              // cost per point (grp)
              costWeekly =
                plan[stn][dp].costEntry.value *
                ((resWeekly.impacts / results.target.universe) * 100); // [00]
              costTotal =
                plan[stn][dp].costEntry.value *
                ((resTotal.impacts / results.target.universe) * 100); // [00]
              break;
            }

            case "cps": {
              // cost per spot
              costWeekly = plan[stn][dp].costEntry.value * resWeekly.spots; //plan[stn][dp].spots;
              costTotal = plan[stn][dp].costEntry.value * resTotal.spots; //plan[stn][dp].spots;
              break;
            }

            case "cpm": {
              // cost per thousand (impressions)
              costWeekly =
                (plan[stn][dp].costEntry.value * resWeekly.impacts) / 10; // [00]
              costTotal =
                (plan[stn][dp].costEntry.value * resTotal.impacts) / 10; // [00]
              break;
            }
          }
          resWeekly.cost = costWeekly; // cost put in object whether there are spots or not
          resTotal.cost = costTotal; // cost put in object whether there are spots or not
          plan[stn][dp].cost = costTotal;

          dpCostWeekly[dp] = dpCostWeekly[dp] || 0;
          dpCostWeekly[dp] += costWeekly;

          dpCostTotal[dp] = dpCostTotal[dp] || 0;
          dpCostTotal[dp] += costTotal;

          stdpCostWeekly[stn + "_" + dp] = stdpCostWeekly[stn + "_" + dp] || 0;
          stdpCostWeekly[stn + "_" + dp] += costWeekly;

          stdpCostTotal[stn + "_" + dp] = stdpCostTotal[stn + "_" + dp] || 0;
          stdpCostTotal[stn + "_" + dp] += costTotal;

          stCostWeekly[stn] = stCostWeekly[stn] || 0;
          stCostWeekly[stn] += costWeekly;

          stCostTotal[stn] = stCostTotal[stn] || 0;
          stCostTotal[stn] += costTotal;

          totCostTotal += costTotal;
          totCostWeekly += costWeekly;
        }
      });
    });

    // copy to results object
    results.weekly.dayparts.forEach(
      (dp) => (dp.cost = dpCostWeekly[dp.daypartId])
    );
    results.total.dayparts.forEach(
      (dp) => (dp.cost = dpCostTotal[dp.daypartId])
    );

    results.weekly.stationDayparts.forEach(
      (dp) => (dp.cost = stdpCostWeekly[dp.stationId + "_" + dp.daypartId])
    );
    results.total.stationDayparts.forEach(
      (dp) => (dp.cost = stdpCostTotal[dp.stationId + "_" + dp.daypartId])
    );

    results.weekly.stations.forEach(
      (stn) => (stn.cost = stCostWeekly[stn.stationId])
    );
    results.total.stations.forEach(
      (stn) => (stn.cost = stCostTotal[stn.stationId])
    );

    results.weekly.totalCost = totCostWeekly;
    results.total.totalCost = totCostTotal;
  }

  // input from planning grid
  // this needs to build a complete ScheduleEvaluation of all stations and dayparts and spots as it returns reaches at each level
  processAndEvaluate( market: Market, targets: Target[], scheduleMarket: CampaignMarketSchedule ): Observable<ScheduleEvaluation> {
    let requests = [];

    // for each target generate an R&F call.
    targets.forEach((planningTarget) => {
      const target = this.prepareTargetWithUniverse(market, planningTarget);

      // build evaluation object
      let sch: ScheduleEvaluation = {
        marketFilename: market.marketFilename,
        universe: target.universe,
        target: target,
        plan: scheduleMarket.plan.asSchedulePlan(
          scheduleMarket.planningPeriod,
          scheduleMarket.numWeeks,
          scheduleMarket.stations
        ), // always return single week spots
        numWeeks: scheduleMarket.numWeeks,
        effectiveReachLevel: scheduleMarket.effectiveReachLevel,
        planningPeriod: scheduleMarket.planningPeriod,
      };

      requests.push(
        this.evaluateSchedule(sch).map((data) => {
          scheduleMarket.results = scheduleMarket.results || [];

          // check if target already has a record and use it else create new one.
          let result = scheduleMarket.results.find(
            (res) => res.target.coding == target.coding
          );
          if (!result) {
            scheduleMarket.results.push(scheduleMarket.getEmptyResults());
            result = scheduleMarket.results[scheduleMarket.results.length - 1];
          }

          //clone the target and set the universe and sample as per this market
          result.target = target;

          // copy results into results object
          if (!sch.errorMessage) {
            // weekly results
            result.weekly.stations = data.result.weekly.stations;
            result.weekly.stationDayparts = data.result.weekly.stationDayparts;
            result.weekly.dayparts = data.result.weekly.dayparts;

            result.weekly.totalReach = data.result.weekly.scheduleReach;
            result.weekly.totalEffectiveReach = data.result.weekly.scheduleEffectiveReach;
            result.weekly.totalImpacts = data.result.weekly.scheduleImpacts;
            result.weekly.totalSpots = data.result.weekly.scheduleSpots;

            //total results
            result.total.stations = data.result.total.stations;
            result.total.stationDayparts = data.result.total.stationDayparts;
            result.total.dayparts = data.result.total.dayparts;

            result.total.totalReach = data.result.total.scheduleReach;
            result.total.totalEffectiveReach = data.result.total.scheduleEffectiveReach;
            result.total.totalImpacts = data.result.total.scheduleImpacts;
            result.total.totalSpots = data.result.total.scheduleSpots;

            this.evaluateCosts([target], scheduleMarket);
          }

          return data;
        })
      ); //push
    }); // targets

    return Observable.forkJoin(...requests);
  }

  // return the universe of a target in reference to a market
  getTargetUniverse(market: Market, target: Target): TargetMarketData {
    const mkt = this.marketsService.get(market.marketFilename);
    const res = this.marketsService.getTargetUniverse(mkt, target);
    res.marketFilename = market.marketFilename;
    return res;
  }

  prepareTargetWithUniverse(market: Market, target: Target): Target {
    const res = this.getTargetUniverse(market, target);
    const copy: Target = lodash.cloneDeep(target);

    copy.universe = res.universe;
    copy.sample = res.sample;
    return copy;
  }

  // Schedule in, completed Schedule out
  evaluateSchedule(
    schedule: ScheduleEvaluation
  ): Observable<ScheduleEvaluation> {
    if (!schedule.plan.length) {
      //schedule.errorMessage = "plan array empty";
      schedule.result = this.evaluationService.getEvaluatonResponse({});
      return of(schedule);
    }

    let mkt = this.marketsService.get(schedule.marketFilename);

    // build specific json query for service request
    let request = this.evaluationService.getEvaluationRequest(
      mkt,
      mkt.allStations,
      mkt.allPlanningDayparts,
      schedule
    ); // need all stns and all dayparts
    return this.evaluationService.evaluate(request).map((data) => {
      schedule.result = this.evaluationService.getEvaluatonResponse(data); // put into a decent object
      this.evaluationService.calculateImpacts(request, schedule);
      return schedule;
    });
  }

  // frequency distribution request in, output with results added
  frequencyDistribution(
    market: Market,
    schedule: CampaignSchedule,
    target: Target
  ): Observable<number[]> {
    const scheduleMarket = schedule.market(market);
    const scheduleResults = scheduleMarket.results.find(
      (tgt) => tgt.target.coding == target.coding
    );
    const weeks = 1; //schedule.numWeeks;  // only pass a single week request

    const request = {
      GRPs:
        (scheduleResults.total.totalImpacts / scheduleResults.target.universe) *
        100 *
        weeks,
      Reach:
        (scheduleResults.total.totalReach / scheduleResults.target.universe) *
        100,
      NumSpots: scheduleResults.total.totalSpots * weeks,
      maxDist: Math.trunc(scheduleResults.total.totalSpots * weeks),
    };
    return this.doFrequency(request).map((freq) => {
      scheduleResults.total.frequencyDistribution = freq;
      return freq;
    });
  }

  frequencyDistributionCombined(
    schedule: CampaignSchedule,
    target: Target
  ): Observable<number[]> {
    const result = this.getMarketsCombined(schedule, target);

    const request = {
      GRPs: result.GRPs,
      Reach: result.Reach,
      NumSpots: result.NumSpots,
      maxDist: result.maxDist,
    };
    
    return this.doFrequency(request).map((freq) => {
      schedule.addCombinedFreqDistResult(target, freq);
      return freq;
    });
  }

  private doFrequency(request: any): Observable<number[]> {
    return this.evaluationService.frequencyDistribution(request).map((data) => {
      let freq: number[] = [];
      if (data.length) {
        let value: number;
        for (let d = 0; d < data.length; d++) {
          value = 1;
          for (let i = 0; i <= d; i++) {
            value = value - data[i];
          }
          freq.push(value);
        }
      }

      return freq;
    });
  }

  nTiles(
    market: Market,
    schedule: CampaignSchedule,
    target: Target
  ): Observable<number[]> {
    const scheduleMarket = schedule.market(market);
    const scheduleResults = scheduleMarket.results.find(
      (tgt) => tgt.target.coding == target.coding
    );

    const request = {
      GRPs:
        (scheduleResults.total.totalImpacts / scheduleResults.target.universe) *
        100,
      Reach:
        (scheduleResults.total.totalReach / scheduleResults.target.universe) *
        100,
      NumSpots: scheduleResults.total.totalSpots,
      maxDist: Math.trunc(scheduleResults.total.totalSpots),
      NumTiles: 5,
      Population: scheduleResults.target.universe,
    };

    return this.evaluationService.nTiles(request).map((data) => {
      return data;
    });
  }

  nTilesCombined(
    schedule: CampaignSchedule,
    target: Target
  ): Observable<number[]> {
    // get markets combined request values
    let result: any = this.getMarketsCombined(schedule, target);

    // form request
    const request = {
      GRPs: result.GRPs,
      Reach: result.Reach,
      NumSpots: result.NumSpots,
      maxDist: result.maxDist,
      NumTiles: 5,
      Population: result.Population,
    };

    return this.evaluationService.nTiles(request).map((data) => {
      return data;
    });
  }

  getMarketsCombined(schedule: CampaignSchedule, target: Target): any {
    const request = {
      GRPs: 0,
      Reach: 0,
      NumSpots: 0,
      maxDist: 0,
      Population: 0,
    };

    let universe = 0;

    schedule.markets.forEach((scheduleMarket) => {
      const scheduleResults = scheduleMarket.results.find(
        (tgt) => tgt.target.coding == target.coding
      );
      request.GRPs += scheduleResults.total.totalImpacts;
      request.Reach += scheduleResults.total.totalReach;
      request.NumSpots += scheduleResults.total.totalSpots;
      universe += scheduleResults.target.universe;
    });

    request.GRPs = (request.GRPs / universe) * 100;
    request.Reach = (request.Reach / universe) * 100;
    request.maxDist = Math.trunc(request.NumSpots);
    request.Population = universe;

    return request;
  }

  optimisation(
    market: Market,
    stations: Station[],
    dayparts: Daypart[],
    targets: Target[],
    primaryTarget: Target,
    schedule: CampaignSchedule,
    options: OptimisationEvaluation
  ): Observable<ScheduleEvaluation> {
    return new Observable((observable) => {
      // build the complete request here.  Evaluation just blindly sends it
      // after the optimisation an R&F call will be needed so done directly below

      // create integers for the request
      const stationRankBy = [
        "avgQtrHr",
        "cume",
        "rch",
        "freq",
        "imp",
        "cpmImp",
        "cpmRch",
      ].indexOf(options.criteria.rankStationBy);
      const stationBuyingGoal = [
        "minFreq",
        "minPctCume",
        "turnover",
        "buySched",
      ].indexOf(options.criteria.stationBuyingGoal);
      const marketGoal = [
        "rch",
        "freq",
        "grps",
        "budget",
        "topNStations",
      ].indexOf(options.criteria.marketGoal);
      const marketGoal2 = [
        "rch",
        "freq",
        "grps",
        "budget",
        "topNStations",
      ].indexOf(options.criteria.marketGoal2);
      const goalCombination = ["off", "and", "or"].indexOf(
        options.criteria.goalCombination
      );

      const stationBuyingGoalValue = options.criteria.stationBuyingGoalValue;
      const marketGoalValue = options.criteria.marketGoalValue;
      const marketGoalValue2 = options.criteria.marketGoalValue2;

      const scheduleMarket = schedule.market(market);
      const primaryTargetCopy = this.prepareTargetWithUniverse(
        market,
        primaryTarget
      );

      // build evaluation object
      let sch: ScheduleEvaluation = {
        marketFilename: market.marketFilename,
        universe: primaryTargetCopy.universe,
        target: primaryTargetCopy,
        plan: [], // plan ignored for schedule.  Base Inserts entered by daypart only, below
        numWeeks: schedule.numWeeks,
        effectiveReachLevel: schedule.effectiveReachLevel,
        planningPeriod: schedule.planningPeriod, // planning period ignored by optimiser
      };

      const mkt = this.marketsService.get(market.marketFilename);
      let req = this.evaluationService.getEvaluationRequest(
        mkt,
        mkt.allStations,
        mkt.allPlanningDayparts,
        sch
      ); // need all stns and all dayparts

      // write base costs and base insertions
      req.StationDaypartCPM = this.getOptimiseCPM(
        mkt,
        mkt.allStations,
        mkt.allPlanningDayparts,
        options.criteria.daypartCosts,
        primaryTargetCopy
      );
      req.Insertions = this.getOptimiseInitialPlan(
        options.criteria.daypartCosts
      );

      req.stationRankBy = stationRankBy;
      req.StationBuyingGoal = stationBuyingGoal;
      req.stationBuyingGoalValue = stationBuyingGoalValue;
      req.marketGoal = marketGoal;
      req.marketGoalValue = marketGoalValue;
      req.marketGoal2 = marketGoal2;
      req.marketGoalValue2 = marketGoalValue2;
      req.goalCombination = goalCombination;
      req.Population = primaryTargetCopy.universe;
      req.numWeeks = schedule.numWeeks;
      req.maxInsertsAvailable = Number.MAX_VALUE;

      this.evaluationService.optimisation(req).subscribe((data) => {
        options.results = data;

        scheduleMarket.optimisation =
          scheduleMarket.optimisation ||
          scheduleMarket.initialiseOptimisation();

        // additonal feedback from the optmise engine to be added to the optimisation object
        scheduleMarket.optimisation.messages = data.messages || [];
        scheduleMarket.optimisation.warning = data.warning || "";
        scheduleMarket.optimisation.error = data.error || "";

        // build scheule.plan using data.InsertsByStationAndDaypart: { stnId { dpId: n } }
        scheduleMarket.plan.clear();
        const planningPeriodBackup = schedule.planningPeriod;
        //schedule.planningPeriod = PlanningPeriod.SingleWeek; // results back are single week, so treat schedule as such

        // extract any messages passed back
        scheduleMarket.optimisation.messages = [];
        if (data.Messages) {
          scheduleMarket.optimisation.messages = data.Messages.map((msg) =>
            msg.trim()
          );
        }

        // get all the stations and dayparts from the results and build a plan
        if (data.InsertsByStationAndDaypart) {
          let mkt = this.marketsService.get(market.marketFilename);
          const allCosts = this.getOptimiseCostConversions(
            mkt.allStations,
            mkt.allPlanningDayparts,
            options.criteria.daypartCosts,
            primaryTargetCopy
          );
          const stationIds = Object.keys(data.InsertsByStationAndDaypart);

          // keep a list of the specific stations used for the optimisation
          scheduleMarket.stations = mkt.allStations.filter(
            (s) => stationIds.indexOf("" + s.id) !== -1
          );
          scheduleMarket.planningStations = []; // clear UI representation of stations

          // deliver an all weeks plan if required
          const multiplier =
            scheduleMarket.planningPeriod == PlanningPeriod.TotalWeeks
              ? schedule.numWeeks
              : 1;

          stationIds.forEach((stnId) => {
            let daypartIds = Object.keys(
              data.InsertsByStationAndDaypart[stnId]
            );
            daypartIds.forEach((dpId) => {
              let spots =
                parseInt(data.InsertsByStationAndDaypart[stnId][dpId]) *
                multiplier;
              scheduleMarket.plan.addSpots(
                parseInt(stnId),
                parseInt(dpId),
                spots,
                { field: "spots", value: spots }
              );

              // if there's costs...
              if (allCosts[stnId] && allCosts[stnId][dpId] !== undefined) {
                const cost = allCosts[stnId][dpId];
                scheduleMarket.plan.addCosts(
                  parseInt(stnId),
                  parseInt(dpId),
                  cost[cost.field],
                  { field: cost.field, value: cost[cost.field] }
                );
              }
            });
          });
        }

        // evaluate the plan (results put back in schedule)
        this.processAndEvaluate(market, targets, scheduleMarket).subscribe(
          (data) => {
            scheduleMarket.planningPeriod = planningPeriodBackup; // restore planning period
            observable.next(sch); // return schedule
            observable.complete();
          }
        );
      });
    }); // Observable
  }

  // Those dayparts with spots are included in the plan
  private getOptimiseInitialPlan(
    daypartCosts: DaypartOptimisationCosts[]
  ): any {
    let plan = {};
    daypartCosts.forEach((dp) => {
      if (dp.spots) plan["" + dp.id] = dp.spots;
    });
    return plan;
  }

  // get CPMs from the cost object array populated on the opt dialog
  private getOptimiseCPM(
    mkt: MarketMetrics,
    stations: Station[],
    dayparts: Daypart[],
    costs: DaypartOptimisationCosts[],
    target: Target
  ): any {
    let plan = {};
    let cost: DaypartOptimisationCosts = null;
    let cpm = 0;

    stations.forEach((station) => {
      plan["" + station.id] = {};
      dayparts.forEach((daypart) => {
        cost = costs.find((d) => d.id == daypart.id); // cost object found for this daypart
        cpm = 0;

        if (cost && cost.spots) {
          // has cost object and user entered at least 1 spot for the daypart

          const ratings = this.marketsService.getDaypartAvgQtrHourRatings(
            mkt,
            daypart,
            target,
            [station]
          );
          if (ratings && target.universe) {
            if (cost.cps) cost.cpp = cost.cps / (ratings * 100); //  cps/grps
            if (cost.cpp) cpm = (cost.cpp * 1000) / target.universe;
          }
          cost.cpm = cpm;
        }

        plan["" + station.id]["" + daypart.id] = cpm;
      });
    });

    return plan;
  }

  private getOptimiseCostConversions(
    stations: Station[],
    dayparts: Daypart[],
    costs: DaypartOptimisationCosts[],
    target: Target
  ): any {
    const mkt = this.marketsService.getFirst();

    let plan = {};
    let cost: DaypartOptimisationCosts = null;
    let cpm = 0;

    stations.forEach((station) => {
      plan["" + station.id] = {};
      dayparts.forEach((daypart) => {
        cost = costs.find((d) => d.id == daypart.id); // cost object found for this daypart
        cpm = 0;

        if (cost && cost.spots) {
          // has cost object and user entered at least 1 spot for the daypart

          const ratings = this.marketsService.getDaypartAvgQtrHourRatings(
            mkt,
            daypart,
            target,
            [station]
          );
          if (ratings && target.universe) {
            cost.field = cost.cps ? "cps" : "cpp";

            if (cost.cps) cost.cpp = cost.cps / (ratings * 100); //  cps/grps
            if (cost.cpp) cpm = (cost.cpp * 1000) / target.universe;
            cost.cpm = cpm;
          }

          plan["" + station.id]["" + daypart.id] = {
            cps: cost.cps,
            cpp: cost.cpp,
            cpm: cost.cpm,
            field: cost.field,
          };
        }
      });
    });

    return plan;
  }
}
