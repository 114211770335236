import { ResultLine, Target, Market, MultiResultLine, CampaignScheduleFrequencyResults } from '../models/campaign.model';
import { CampaignMarketSchedule } from './campaign-market-schedule';
import { CampaignSchedulePlan, PlanningPeriod, PlanningMethod} from './campaign-schedule-plan';

// main campaign schedule.  Holds the plan (stations, dayparts and spots) and the full results
export class CampaignSchedule {
    name: string;

    // plan, results and optimisaitons are all by market, so held as an array
    markets: CampaignMarketSchedule[] = [] 
    combinedFrequencyDistribution: CampaignScheduleFrequencyResults[] = []
    planAcrossMarkets: boolean;

    private _effectiveReachLevel: number;
    get effectiveReachLevel(): number { return this._effectiveReachLevel }
    set effectiveReachLevel( value: number) {
        this._effectiveReachLevel = value;
        this.markets.forEach( m => m.effectiveReachLevel = value);
    }

    private _numWeeks: number;
    get numWeeks(): number { return this._numWeeks }
    set numWeeks( value: number) {
        this._numWeeks = value;
        this.markets.forEach( m => m.numWeeks = value);
    }

    private _planningPeriod: PlanningPeriod;
    get planningPeriod(): PlanningPeriod { return this._planningPeriod }
    set planningPeriod( value: PlanningPeriod) {
        this._planningPeriod = value;
        this.markets.forEach( m => m.planningPeriod = value);
    }

    private _planningMethod: PlanningMethod;
    get planningMethod(): PlanningMethod { return this._planningMethod }
    set planningMethod( value: PlanningMethod) {
        this._planningMethod = value;
        this.markets.forEach( m => m.planningMethod = value);
    }

    constructor(marketFilename: string, name?: string) {
        this.name = name || "Schedule";
        this.planAcrossMarkets = true;
        this.addMarket(marketFilename);
    }

    hasResults(): boolean {
        return this.markets.some( m=> m.hasResults());
    }

    hasOptimisation(): boolean {
        return this.markets.some( m=> m.hasOptimisation() );
    }

    // delete the results objects for a given target
    deleteTarget(target: Target) {

        const index = this.combinedFrequencyDistribution.findIndex( tgt => tgt.target.coding === target.coding);
        if (index != -1) this.combinedFrequencyDistribution.splice( index, 1);

        this.markets.forEach( m=> m.deleteTarget(target) );
    }

    //storing combined frequencyDistrobution results by target
    addCombinedFreqDistResult( target: Target, freq: number[]) {
        const dist = this.combinedFrequencyDistribution.find( tgt => tgt.target.coding === target.coding);
        dist? dist.frequencyDistribution = freq : this.combinedFrequencyDistribution.push( { target: target, frequencyDistribution: freq } );
    }

    getCombinedFreqDistResult( target: Target): CampaignScheduleFrequencyResults {
        return this.combinedFrequencyDistribution.find( tgt => tgt.target.coding === target.coding);
    }

    // get the the correct market data in the markets array
    market( market: string | Market ): CampaignMarketSchedule {

        const filename = (typeof market === 'string') ? market : market.marketFilename;
        let mkt = this.markets.find( m=> m.marketFilename === filename);
        if (!mkt) mkt = this.addMarket(filename);

        return mkt; 
    }

    addMarket(filename: string): CampaignMarketSchedule {

        let newMarket = this.markets.find( m=> m.marketFilename === filename);
        if ( !newMarket ) {

            newMarket = new CampaignMarketSchedule();
            newMarket.marketFilename = filename;
            newMarket.plan = new CampaignSchedulePlan();
            newMarket.results = [];
            newMarket.optimisation = null;
            newMarket.stations = [];
            newMarket.planningStations = [];
            newMarket.numWeeks = this.numWeeks;
            newMarket.effectiveReachLevel = this.effectiveReachLevel;
            newMarket.planningPeriod = this.planningPeriod;
            newMarket.planningMethod = this.planningMethod;
    
            this.markets.push(newMarket);
        }

        // a blank market was passed on app start as a placeholder.  When a real market is added this can be deleted.
        if (this.markets.length > 1) this.deleteMarket("");
        return newMarket
    }

    getCombinedMarketTotalLine( target: Target): ResultLine {
        return this.getCombinedMarketTotal(target)[0];
    }

    getCombinedMarketTotal(target: Target): MultiResultLine[] {

        let data: MultiResultLine[] = []
        let combined: ResultLine;

        this.markets.forEach( market => {

            // market totals
            let total: MultiResultLine = market.getScheduleTotals( target )
            total.title = market.marketFilename;

            combined = combined || market.getEmptyResultLine( (this.markets.length == 1)? "Campaign Total" : "Combined Mkt Total" );
            combined.impacts += total.impacts;
            //combined.grps += total.grps;
            combined.reach00 += total.reach00;
            combined.effReach00 += total.effReach00;
            combined.universe += total.universe;
            combined.spots += total.spots;
            combined.totalCost += total.totalCost;

            total.stations = [];

            // get stations for this market
            market.stations.forEach( station => {
                total.stations.push(market.getScheduleTotals( target, station ))
            })

            data.push(total);
        })

        combined.grps = combined.universe ? (combined.impacts / combined.universe) * 100 : 0;
        combined.reachPct = combined.universe ? (combined.reach00 / combined.universe) * 100 : 0;
        combined.effReachPct = combined.universe ? (combined.effReach00 / combined.universe) * 100 : 0;
        combined.avgFreq = combined.reach00 ?  combined.impacts / combined.reach00 : 0;
        combined.cps = combined.spots ? combined.totalCost / combined.spots : 0;
        combined.costRchPnt = combined.reachPct ? combined.totalCost / combined.reachPct : 0;
        combined.cpm = combined.impacts ? ( combined.totalCost / combined.impacts) * 10 : 0; 
        combined.cpp = combined.grps ? combined.totalCost / combined.grps : 0; 

        data.unshift(combined); //combined always first in the list
        return data
    }

    deleteMarket(filename: String) {
      const idx = this.markets.findIndex( m=> m.marketFilename === filename);
      if (idx !== -1) this.markets.splice(idx, 1);
    }

    clearResults() {

        this.combinedFrequencyDistribution = []
        this.markets.forEach( market => {
            market.clearResults();
        })
    }

    clearAll() {

        this.planAcrossMarkets = true;
        this.combinedFrequencyDistribution = []
        this.markets.forEach( market => {
            market.clear();
        })
    }

}
