import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SummaryValues } from "../../campaign-summary/campaign-summary.component";
import { HelperService } from "src/app/shared/services/helper.service";
import { UserPreferences } from "src/app/shared/classes/user-preferences";
import {
  FreqDistComponent,
  FreqDistValues,
} from "src/app/shared/components/freq-dist/freq-dist.component";

// column definitions
export interface DistributionColumn {
  columnType: string;
  columnDef: string;
  header: string;
  format: string;
  css: string;
  cell(x: any): any;
}

export interface DistributionSet {
  title: string;
  marketFilename?: string;
  reach: number;
  universe: number;
  GRPs: number;
  distribution?: number[];
}

export class FreqDistDialogModel {
  constructor(
    public title: string,
    public startIndex: number,
    public distributionSet: DistributionSet[]
  ) {}
}

@Component({
  selector: "freq-dist-dialog",
  templateUrl: "./freq-dist-dialog.component.html",
  styleUrls: ["./freq-dist-dialog.component.scss"],
})
export class FreqDistDialogComponent implements OnInit {
  title: string = "Frequency Distribution";
  summary: SummaryValues;
  freq: FreqDistValues;
  currentMarket: number = 0;

  constructor(
    public dialogRef: MatDialogRef<FreqDistDialogComponent>,
    private helperService: HelperService,
    private userPrefs: UserPreferences,
    @Inject(MAT_DIALOG_DATA) public data: FreqDistDialogModel
  ) {} // the data if used in a dialog

  ngOnInit() {
    this.currentMarket = this.data.startIndex;
    this.loadData();
  }

  onClose() {
    this.dialogRef.close();
  }

  onGraphSelect(e) {}

  // UI onscreen
  getTitle(): string {
    return this.data.title;
  }

  getCurrentMarket(): string {
    return this.data.distributionSet[this.currentMarket].title;
  }

  onSelectCurrentMarket(direction: number) {
    const x = this.currentMarket + direction;
    this.currentMarket =
      x < 0
        ? this.data.distributionSet.length - 1
        : x > this.data.distributionSet.length - 1
        ? 0
        : x;
    this.loadData();
  }

  loadData() {
    const decimal = this.userPrefs.user.UI.floatDecimals;
    const data = this.data.distributionSet[this.currentMarket];

    // sumary panel
    this.summary = {
      sideList: [],
      title: "",
      featured: [
        {
          title: "GRPs",
          value: this.helperService.formatFloat(data.GRPs, decimal),
        },
        {
          title: "Total Reach %",
          value: this.helperService.formatFloat(data.reach, decimal),
        },
        {
          title: "Population [00]",
          value: this.helperService.formatFloat(data.universe),
        },
      ],
    };

    // table/grid data
    this.freq = {
      universe: data.universe,
      distribution: data.distribution,
    };
  }
}
