import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  searchText: string;

  termsJSON = [
    {
      "API Name": "rchPct",
      "Data Label": "Reach % 1+",
      "Definitions": "The approximate percentage of a target audience’s population who notice an advertising message at least once."
    },
    {
      "API Name": "effectiveRch",
      "Data Label": "Effective Reach % 3+",
      "Definitions": "The percent of persons within the target audience exposed to the advertising schedule an average of three or more times."
    },
    {
      "API Name": "avgQtrHourPersons",
      "Data Label": "Average Quarter-Hour Persons (AQH Persons)",
      "Definitions": "The average number of persons listening to a particular station for at least five minutes during a 15-minute period."
    },
    {
      "API Name": "avgQtrHourRating",
      "Data Label": "Average Quarter-Hour Rating (AQH Rating)",
      "Definitions": "The AQH Persons estimate expressed as a percentage of the population being measured. This estimate is printed for the MSA and DMA. It can also be computed for the TSA. [AQH Persons / Population] x 100 = AQH Rating (%)"
    },
    {
      "API Name": "cumePersons",
      "Data Label": "Cume Persons",
      "Definitions": "The total number of different persons who tune to a radio station during the course of a daypart for at least five minutes. The key word here is different. No matter how long the listening occurred, each person is counted only once. Cume is also referred to unduplicated audience reach or circulation."
    },
    {
      "API Name": "cumeRtg",
      "Data Label": "Cume Rating",
      "Definitions": "The Cume Persons audience expressed as a percentage of all persons estimated to be in the specified demographic group. [Cume Persons / Population] x 100 = Cume Rating (%)"
    },
    {
      "API Name": "share",
      "Data Label": "Share",
      "Definitions": "The percentage of those listening to radio in the Metro who are listening to a particular radio station. [AQH Persons to a Station / AQH Persons to All Stations] x 100 = Share (%)"
    },
    {
      "API Name": "impressions",
      "Data Label": "Gross Impressions",
      "Definitions": "The sum of the Average Quarter-Hour Persons audience for all spots in a given schedule. This is a duplicated value. [AQH Persons] x [the number of spots in an advertising schedule] = GIs"
    },
    {
      "API Name": "grps",
      "Data Label": "Gross Rating Points (GRPs)",
      "Definitions": "The sum of all rating points achieved for a particular spot schedule. [AQH Rating] x [the number of spots in an advertising schedule] = GRPs"
    },
    {
      "API Name": "cpp",
      "Data Label": "Cost Per Rating Point",
      "Definitions": "The cost of reaching an Average Quarter-Hour Persons audience that's equivalent to one percent of the population in a given demographic group. 1. [Cost of Schedule] / [GRP] = Cost Per Rating Point 2. [Spot Cost] / [AQH Rating] = Cost Per Rating Point"
    },
    {
      "API Name": "cpm",
      "Data Label": "Cost Per Thousand (CPM)",
      "Definitions": "The cost of delivering 1,000 gross impressions. 1. [Cost of Schedule] / [GI] x 1,000 = CPM  or 2. [Spot Cost] / [AQH Persons] x 1,000 = CPM"
    },
    {
      "API Name": "netRch",
      "Data Label": "Net Reach",
      "Definitions": "The number of different persons reached in a given schedule. This is a non duplicated value. Unique Individuals reached."
    },
    {
      "API Name": "freq",
      "Data Label": "Frequency",
      "Definitions": "The average number of times a person is exposed to a radio spot schedule. [GI] / Net Reach = Frequency"
    },
    {
      "API Name": "tsl",
      "Data Label": "Time Spent Listening (TSL)",
      "Definitions": "An estimate of the number of quarter-hours the average person spends listening during a specified time period. [(Quarter-Hours in a time period) x (AQH Persons)] / Cume Audience = TSL"
    },
    {
      "API Name": "turnover",
      "Data Label": "Turnover",
      "Definitions": "The number of different groups of persons that make up a station’s audience. The lower the turnover factor, the more loyal the stations audience. (people are not switching stations. The higher the turnover factor, the faster the rate of Cume growth. (think of an all-news stations listening---listen for 20 minutes then go back to your music or other station). [Cume Persons / AQH Persons = Turnover Factor"
    },
    {
      "API Name": "metro",
      "Data Label": "Metro",
      "Definitions": "Includes a city (or cities) whose population is specified as that of the central city together with the county (or counties) in which it is located. The Metro also includes contiguous or additional counties when the economic and social relationships between the central and additional counties meet specific criteria. Nielsen Audio Metros generally correspond to the Metropolitan Statistical Areas (MSAs) defined by the U.S. Government's Office of Management and Budget. They are subject to exceptions dictated by historical industry usage and other marketing considerations."
    },
    {
      "API Name": "tsa",
      "Data Label": "Total Survey Area (TSA)",
      "Definitions": "A geographic area that encompasses the Metro Survey Area and may include additional counties located outside the Metro which meet certain listening criteria to Metro-licensed stations."
    },
    {
      "API Name": "dma",
      "Data Label": "Designated Market Area (DMA®)",
      "Definitions": "The DMA is composed of sampling units (counties or geographically split counties) and is defined and updated annually by Nielsen Media Research, Inc., based on historical television viewing patterns. A county or split county is assigned exclusively to one DMA. Arbitron reports radio listening estimates for the Top 50 DMAs (ranked on TV households) in the Radio Market Reports of all Standard radio markets whose Metros are located within the DMA and whose names are contained in the DMA name."
    }
  ]

  constructor() {
  }

  ngOnInit(): void {
  }

}
