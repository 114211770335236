  <div class="container" [style.width] = "width ? width : ''">

    <mat-form-field class="field">
      <button mat-icon-button matPrefix (click)="onReset()" matTooltip="Reset"><mat-icon>remove_circle_outline</mat-icon></button>
      <mat-label>{{title}}</mat-label>
      <mat-select [formControl]="formControl" (selectionChange)="onAdd($event)" multiple>
      <mat-select-trigger>
        <mat-chip-list>
          <mat-chip *ngFor="let chip of formControl.value"
            [removable]="true" (removed)="onRemove(chip)">
            {{chip}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>

      <mat-option color="accent" *ngFor="let chip of data" [value]="chip">{{chip}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>