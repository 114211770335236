import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable } from 'rxjs';

@Component({
  selector: 'tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})
export class TabNavigationComponent implements OnInit {

  tabs: string[] = ['dashboard', 'markets', 'targets', 'stations', 'planning'];

  tabData: any[] = [
    { label: "dashboard", title: "Documents", icon: "dashboard"},
    { label: "markets", title: "Markets", icon: "assignment_turned_in"},
    { label: "targets", title: "Targets", icon: "people"},
    { label: "stations", title: "Station Selection", icon: "radio"},
    { label: "planning", title: "Planning", icon: "post_add"},
  ] 
  
  selected = new FormControl(0);
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @Input('current') current: string;
  @Input() tabChange: (next: string) => Observable<boolean>

  ignoreCallback: boolean = false;

  constructor(private campaignService: CampaignService, 
              private router: Router) {
   }

  ngOnInit() {

    let idx = this.tabData.findIndex( tab=> tab.label == this.current);
    // workaround to select NO tabs if the index was not found
    if ( idx==-1 ) idx = this.tabData.length;  // one past the end, hidden tab

    this.selected.setValue(idx);
  }

  // using buttons to switch tabs
  onTabChanged(next: number) {


    // theres a callback
    if ( this.tabChange ) {

      this.tabChange( this.tabs[next] ).subscribe( res => {

        this.ignoreCallback = false;

        if (res) {
          this.selected.setValue(next);
          this.router.navigate( [this.tabs[next]] );
        }
        else {
          this.ignoreCallback = true;
          this.tabGroup.selectedIndex = this.tabData.findIndex( tab => tab.label == this.current);
        }

      })
    }
    // no callback so change correctly.
    else {
      this.selected.setValue(next);
      this.router.navigate( [this.tabs[next]] );
    }


  }

}
