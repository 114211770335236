import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { CampaignDocument, SearchDocumentResponseModel} from '../models/campaign-document.model';
import { ElasticResponseModel, ElasticService } from './elastic.service';
import { EventCategory, TupAnalyticsService } from '@telmar-global/tup-analytics';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor( private elasticService: ElasticService, 
               private as: TupAnalyticsService ) { }

  createOrUpdateDocument( doc: CampaignDocument ): Observable< ElasticResponseModel > {

    this.as.e(EventCategory.Document, "save", doc.title);
    return this.elasticService.createOrUpdate( doc, environment.api.elastic.endPoint.documents, `'${doc.title}'` );
  }

  deleteDocument( id: string, title: string = "" ): Observable< ElasticResponseModel > {

    this.as.e(EventCategory.Document, "delete", title);
    return this.elasticService.delete( id, environment.api.elastic.endPoint.documents, 'document' );
  }

  searchDocuments(): Observable< SearchDocumentResponseModel > {
    return this.elasticService.search( environment.api.elastic.endPoint.documents ).map (data => {
      return {
         documents: data.documents, responseTime: data.responseTime 
        }
    })
  }

}

