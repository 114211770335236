// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: "US-AUDIO",

  // google analytics
  analytics: {
    trackingId : 'UA-177065187-3'
  },

  // login
  tupApiKey: 'GD64bmAlyU4wUGJ5uZDFP5MOcRgWxmIl7SCfRXk3', 
  tupAuth: {
    loginLocation: '/login',
    defaultLocation: '/',
  },

  amplifyAuth: {
    region: 'us-east-1',

    // TELMAR POOL
    userPoolId: "us-east-1_eaMylC1Cb",   
    userPoolWebClientId: "3t0dbv886fkn6cpebit0neubjr",  
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: 'telmar.com',
          expires: 365, 
          secure: true
      },
    // clientMetadata: { myCustomKey: 'myCustomValue' },  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  },

  // engine endpoints
  api: {
    data: {
      url: "https://wifmhizm5c.execute-api.us-east-1.amazonaws.com/DEV",
      endPoint: {
        surveys: "/surveys",
        marketsBySurvey: "/marketsbysurvey",
        
        markets: "/markets",
        marketData: "/marketdata",
        evaluate: "/calculations/reachandfrequency",
        distribution: "/calculations/distribution",
        nTiles:"/calculations/ntiles",
        optimisation: "/optimisation",
        exportDAU: "/export/dau"
      }
    },

  // elasticsearch endpoints
  elastic: {
      url: "https://wkk8g6kza6.execute-api.us-east-1.amazonaws.com/dev/data",  //to es  "https://search-usaudio-pa5dgzfdyau46gm2rf7ba4i4mi.us-east-1.es.amazonaws.com/prod"
      endPoint: {
        documents: "/documents",
        health: "/_cluster/health",
        userTargets: "/user_targets",
      }
    }
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
