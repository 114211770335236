import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Target, Daypart, ResultLine, Market } from 'src/app/shared/models/campaign.model';
import { CampaignSchedule } from 'src/app/shared/classes/campaign-schedule';
import { PlanningService } from 'src/app/shared/services/planning.service';
import { CampaignMarketSchedule } from 'src/app/shared/classes/campaign-market-schedule';

// grid row fields
export interface DaypartOptimisationCosts {
  id: number;
  title: string;
  spots: number;
  cps: number;
  cpp: number;
  cpm?: number;
  field?: string;
}

export class OptimisationDialogModel {
  // target and schedule selection
  scheduleIndex: string = '-1';
  targetIndex: string = '0';
  targets: Target[];
  market: Market;
  schedules: CampaignSchedule[];
  dayparts: Daypart[];
  daypartCosts: DaypartOptimisationCosts[] = [];

  constructor (
    public rankStationBy: string,
    public stationBuyingGoal: string,
    public stationBuyingGoalValue: number,
    public marketGoal: string,
    public marketGoalValue: number,
    public goalCombination: string,
    public marketGoal2: string,
    public marketGoalValue2: number,
    public numWeeks: number,
  ) {}

  // accept input from a OptimisationCritera interface (CampaignSchedule)
  copyFrom(scheduleMarket: CampaignMarketSchedule) {

    this.rankStationBy = scheduleMarket.optimisation.rankStationBy;
    this.stationBuyingGoal = scheduleMarket.optimisation.stationBuyingGoal;
    this.stationBuyingGoalValue = scheduleMarket.optimisation.stationBuyingGoalValue;
    this.marketGoal = scheduleMarket.optimisation.marketGoal;
    this.marketGoalValue = scheduleMarket.optimisation.marketGoalValue;
    this.goalCombination = scheduleMarket.optimisation.goalCombination;
    this.marketGoal2 = scheduleMarket.optimisation.marketGoal2;
    this.marketGoalValue2 = scheduleMarket.optimisation.marketGoalValue2;
    this.numWeeks = scheduleMarket.numWeeks;

    this.daypartCosts = [] 
    let costs = scheduleMarket.plan.getCosts();

    costs.forEach( cost => {

      // take a copy of all the daypart costs from the schedule itself.. To be displayed on the opt dialog
      if (this.daypartCosts.find ( c => c.id == cost.daypartId) == null) {
    
        this.daypartCosts.push({
          spots: 0,
          title: '',
          id: cost.daypartId,
          cpp: cost.entryType.field == 'cpp' ? cost.entryType.value : 0,
          cps: cost.entryType.field == 'cps' ? cost.entryType.value : 0,
          cpm: cost.entryType.field == 'cpm' ? cost.entryType.value : 0,
        })
      }
    });
  }

  // output CampaignSchedule for saving
  saveSettings(market: Market, schedule: CampaignSchedule): void {

    const scheduleMarket = schedule.market(market);

    scheduleMarket.optimisation = {
      rankStationBy: this.rankStationBy,
      stationBuyingGoal: this.stationBuyingGoal,
      stationBuyingGoalValue: this.stationBuyingGoalValue,
      marketGoal: this.marketGoal,
      marketGoalValue: this.marketGoalValue,
      goalCombination: this.goalCombination, 
      marketGoal2: this.marketGoal2,
      marketGoalValue2: this.marketGoalValue2,
      daypartCosts: this.daypartCosts,
      numWeeks: this.numWeeks,
      messages: [],
      warning: "",
      error: "",
    }
  } 

}

@Component({
  selector: 'optimisation-dialog',
  templateUrl: './optimisation-dialog.component.html',
  styleUrls: ['./optimisation-dialog.component.scss']
})
export class OptimisationDialogComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private costFmt = '1.2-2';
  private wholeFmt = '1.0-0';

  // grid columns fields
  columns: any[] = [
//    { columnDef: 'select', header: '', type: 'select', css: 'column-select', cell: (row: any) => `${row.id}` },
    { columnDef: 'title', columnType: 'string', header: 'Daypart', css: '', format: '', cell: (row: any) => `${row.title}` },
    { columnDef: 'spots', columnType: 'editable', header: 'Spots', css: '', format: this.wholeFmt, cell: (row: any) => `${row.spots}` },
    { columnDef: 'cps', columnType: 'editable', header: 'CPS', css: '', format: this.costFmt, cell: (row: any) => `${row.cps}` },
    { columnDef: 'cpp', columnType: 'editable', header: 'CPP $', css: '', format: this.costFmt, cell: (row: any) => `${row.cpp}` },
//    { columnDef: 'menu', columnType: 'string', header: '', css: 'column-options', format: '', cell: (row: any) => `` },
  ]

  displayedColumns = this.columns.map( c => c.columnDef );
  dataSource: MatTableDataSource<DaypartOptimisationCosts>;
  dayparts: DaypartOptimisationCosts[];
  validationMessage: string;

  optimisationOptionsGroup: FormGroup;
  title: string = "Optimization";

  allowMarketGoal2: string;

  // optimisation criteria options
  rankStations: any[] = [
    { id: 'avgQtrHr', name: 'Avg Qtr Hr'},
    { id: 'cume', name: 'Cume'},
    { id: 'rch', name: 'Reach'},
    { id: 'freq', name: 'Freq'},
    { id: 'imp', name: 'Impressions'},
    { id: 'cpmImp', name: 'CPM Impressions'},
    { id: 'cpmRch', name: 'CPM Reach'}
  ]

  stationBuyingGoals: any[] = [
    { id: 'minFreq', name: 'Min Freq'},
    { id: 'minPctCume', name: 'Min % Cume'},
    { id: 'turnover', name: 'Turnover'},
    { id: 'buySched', name: 'Buy Sched N Times'},
  ]

  marketGoals: any[] = [
    { id: 'rch', name: 'Reach'},
    { id: 'freq', name: 'Freq'},
    { id: 'grps', name: 'GRPs'},
    { id: 'budget', name: 'Budget'},
    { id: 'topNStations', name: 'Top N Stations'}
  ]

  targets: any[];
  schedules: any[];

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef< OptimisationDialogComponent >,
              private planningService: PlanningService,
              @Inject(MAT_DIALOG_DATA) public data: OptimisationDialogModel) { }

  ngOnInit() {

    this.targets = this.data.targets.map( (tgt, i) => { return { id: i, name: tgt.name} } );
    this.schedules = this.data.schedules.map( (sch, i) => { return { id: i, name: sch.name} } );
    this.schedules.unshift({ id: -1, name: '<< New Schedule >>' });

    let idx = parseInt(this.data.scheduleIndex);
    if (idx <0) idx = 0;

    this.populateDayparts(this.data.market, this.data.dayparts, this.data.schedules[idx], this.data.targets[0] );
    this.dataSource = new MatTableDataSource<DaypartOptimisationCosts>(this.dayparts); 
    this.dataSource.sort = this.sort;

    this.createForm();
  }

  populateDayparts(market: Market, dayparts: Daypart[], schedule: CampaignSchedule, planningTarget: Target) {

    this.dayparts = []
    const scheduleMarket = schedule.market(market);
    const target = this.planningService.prepareTargetWithUniverse(market, planningTarget);
   
    dayparts.forEach( dp => {

      // get costs saved with schedule
      const line = scheduleMarket.getScheduleLines(null, dp, target);

      let row: DaypartOptimisationCosts = { id: dp.id, title: dp.description,  spots: 1, field: "cpp", cps: line.cps, cpp: line.cpp }

      if (scheduleMarket.optimisation && scheduleMarket.optimisation.daypartCosts) {
        const res = scheduleMarket.optimisation.daypartCosts.find( r=> r.id == dp.id );
        if (res) row = res;
      }
    
      this.dayparts.push({
        id: dp.id,
        title: dp.description,
        spots: row.spots,
        cps: row.field == "cps" ? row.cps : 0,
        cpp: row.field == "cpp" ? row.cpp : 0,
      })

    });
  }

  createForm() {

    this.optimisationOptionsGroup = this.formBuilder.group({
      'rankStationBy': [this.data.rankStationBy],
      'stationBuyingGoal': [this.data.stationBuyingGoal],
      'stationBuyingGoalValue': new FormControl( this.data.stationBuyingGoalValue, [ Validators.required, Validators.min(0.1) ]),
      'marketGoal' : [this.data.marketGoal],
      'marketGoalValue': new FormControl( this.data.marketGoalValue, [ Validators.required, Validators.min(0.1) ]),
      'goalCombination': [this.data.goalCombination],
      'marketGoal2' : new FormControl( { value: this.data.marketGoal2, disabled: this.data.goalCombination=='off'} ),
      'marketGoalValue2': new FormControl( { value: this.data.marketGoalValue2, disabled: this.data.goalCombination=='off'}, [ Validators.required, Validators.min(0.1)] ),
      'scheduleIndex': [this.data.scheduleIndex],
      'targetIndex': [this.data.targetIndex],
      'numWeeks': [this.data.numWeeks],
    });

  }  

  // enable or disable the second markey goal 
  onMarketGoal2Change( e: MatRadioChange ) {
    const enabled = e.value !== 'off';

    if ( enabled ) {
      this.optimisationOptionsGroup.controls['marketGoal2'].enable();
      this.optimisationOptionsGroup.controls['marketGoalValue2'].enable();
    } else {
      this.optimisationOptionsGroup.controls['marketGoal2'].disable();
      this.optimisationOptionsGroup.controls['marketGoalValue2'].disable();
    }

  }

  // check box toggling
  checkboxToggle(row) {
    row.spots = (row.spots)? 0 : 1;
  }

  checkboxIsSelected(row): boolean {
    return !!(row.spots != 0);
  }

  // master select all/none
  masterCheckBoxToggle() {
    const spots = this.isAllSelected() ? 0 : 1;
    this.dataSource.data.forEach( row => row.spots = spots );
  }

  // return all dayparts are selected
  isAllSelected(): boolean {
    let count = 0;
    this.dataSource.data.forEach( row => { if (row.spots) count ++ });
    return (count == this.dataSource.data.length);
  }

  // at least one checkbox checked
  checkBoxHasValue(): boolean {
    return this.dataSource.data.find( row => row.spots) !== null;
  }

  // save cost edits
  onSaveInput(column: string, value: string, row: ResultLine) {
    row[column] = parseFloat(value);

    if (column == "cps") row.cpp = 0;
    if (column == "cpp") row.cps = 0;
    this.validationMessage = '';
  }

  validateCosts(): boolean {

    // check any spots (dayparts) are enabled but have no costs
    let failed = this.dayparts.filter( dp=> (dp.spots > 0 && dp.cpp == 0 && dp.cps == 0 ));
    this.validationMessage = failed.length ? 'Enter costs against each enabled daypart.' : ''

    // all spots (dayparts) are turned off
    failed = this.dayparts.filter( dp=> (dp.spots == 0));
    this.validationMessage = (failed.length == this.dayparts.length) ? 'Enable at least one daypart.' : this.validationMessage;

    return !this.validationMessage;
  }

  onCancel() {
    this.dialogRef.close(null); // pass a null back meaning cancel optimisation
  }

  onSubmit() {

    const val = this.optimisationOptionsGroup.value;

    // cost validation
    let usingCosts = (val.stationBuyingGoal == 'turnover' || val.marketGoal == 'budget') ||
                     ((val.marketGoal2 == 'budget' && val.goalCombination !== 'off'));

    console.log(usingCosts);
    if ( usingCosts && !this.validateCosts() ) return;

    // preparing model to send back
    const model: OptimisationDialogModel = new OptimisationDialogModel(
        val.rankStationBy, val.stationBuyingGoal, val.stationBuyingGoalValue,
        val.marketGoal, val.marketGoalValue, val.goalCombination, val.marketGoal2, val.marketGoalValue2, val.numWeeks);

    model.scheduleIndex = val.scheduleIndex;
    model.targetIndex = val.targetIndex;
    model.daypartCosts = this.dayparts;

    this.dialogRef.close(model);
  }
}
