<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{title}}
</h1>
 
<div mat-dialog-content>
  <p *ngFor = "let line of message">{{line}}</p>
</div>
 
<div mat-dialog-actions>
  <button *ngFor = "let but of buttons" mat-raised-button color="primary" (click) = onButtonClick(but)> {{but.caption}}</button>
</div>
