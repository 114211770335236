import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string[];
  buttons: any[];

  constructor(
    public dialogRef: MatDialogRef< ConfirmDialogComponent >,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.buttons = data.buttons;
  }

  ngOnInit() {
  }

  onButtonClick( but: QuestionDialogModelButtonOptions ): void {
    this.dialogRef.close( but );
  }

}

export interface QuestionDialogModelOptions {
  buttons? : QuestionDialogModelButtonOptions[];
}

export interface QuestionDialogModelButtonOptions {
  caption: string;
  data: any;
}

export class ConfirmDialogModel {

  buttons: QuestionDialogModelButtonOptions[];

  constructor(public title: string, public message: string[], options?: QuestionDialogModelOptions) {

    if ( options ) {
      this.buttons = options.buttons || this.buttons;
    }
  }
}
