import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { HelperService } from 'src/app/shared/services/helper.service';
import { MarketsService } from 'src/app/shared/services/markets.service';
import { LabelType, Options, ChangeContext } from 'ng5-slider';
import { DemographicAgeSexNode, MarketMetrics, DemographicSocioNode } from 'src/app/shared/models/market-information.model';
import { SummaryValues } from '../../campaign-summary/campaign-summary.component';
import { Market, Target } from 'src/app/shared/models/campaign.model';
import { Observable, of, from } from 'rxjs';
import { delay } from 'rxjs/operators';

// stores the results in and out of the dialog. Used to return the created target details
export class TargetCreationDialogModel {
  constructor (
    public marketFilenames: string[],
    public target?: Target,
  ) {}
}

@Component({
  selector: 'target-creation-dialog',
  templateUrl: './target-creation-dialog.component.html',
  styleUrls: ['./target-creation-dialog.component.scss']
})
export class TargetCreationDialogComponent implements OnInit {

  // slider config
  sliderMinValue: number;
  sliderMaxValue: number;
  sliderOptions: Options;
  sliderRefresh: EventEmitter<void> = new EventEmitter<void>(); 

  // demo data
  ageSexOnlyData: DemographicAgeSexNode[];
  ageSexSocioData: DemographicAgeSexNode[];

  mkt: MarketMetrics;
  currentMarket: number;  // view index to current market

  unSavedTarget: Target; // stores the target while it's being built
  selectedGender: string;
  genders: string[];
  selectedAges: DemographicAgeSexNode[];

  // socio loop
  selectedSocioGroup: string;
  socioGroups: string[];
  socioItems: any[];

  campaignSummaryData: SummaryValues;
  constructor(public dialogRef: MatDialogRef< TargetCreationDialogComponent >,
              private marketsService: MarketsService,
              private helperService: HelperService,
              @Inject(MAT_DIALOG_DATA) public data: TargetCreationDialogModel) { }

  ngOnInit() {

    // start with empty target
    this.unSavedTarget = {
      name: "", coding: "",
      sample: 0, universe: 0,
      age: [], demographicIds: [], socio: null
    }

    this.currentMarket = 0;
    this.mkt = this.marketsService.getMarket(this.data.marketFilenames[this.currentMarket]);
    this.loadData();
  }

  loadData() {

    //two age arrays to build, standalone ages and socio mixed ages
    this.ageSexOnlyData = this.mkt.demosAgeSexGroups.filter(age => this.mkt.uniqueAges.includes( age.code )); // uniqueAges filter to those that dont use the socio tree
    this.ageSexSocioData = this.mkt.demosAgeSexGroups.filter(age => this.mkt.socioAges.includes( age.code )); // socioAges filter to those that use the socio tree

    // socio economic props
    this.socioGroups = this.mkt.demographicTree.map( soc => soc.name);
    this.socioGroups.unshift("None");
    this.selectedSocioGroup = this.socioGroups[0];
    this.socioItems = []

    //gender props
    this.selectedGender = "All";
    this.genders = ["All", "Male", "Female"]

    // slider props
    const ages = this.ageSexOnlyData.map( a=> a.ageFrom);
    const lowest = Math.min.apply( Math, ages );
    const highest = Math.max.apply( Math, ages );
    this.sliderMinValue = 25;// lowest + 20;
    this.sliderMaxValue = 54; //highest - 12;

    this.configureSlider(this.ageSexOnlyData);
    this.updateTarget();
  }

  // data for the campaign summary component
  populateCampaignSummary() {

    this.campaignSummaryData = {
      title: "",
      featured: [
        { title: "Population [00]", value: this.helperService.formatFloat(this.unSavedTarget.universe) },
        { title: "Sample", value: this.helperService.formatFloat(this.unSavedTarget.sample)  },
      ],
    }
  }

  // slider set up
  configureSlider( agesToUse: DemographicAgeSexNode[] ) {

    const lowest = Math.min.apply( Math, agesToUse.map( a=> a.ageFrom) );
    const highest = Math.max.apply( Math, agesToUse.map( a=> a.ageFrom) );

    this.sliderOptions = {
      floor: lowest,
      ceil: highest + 1,
      step: 1,
      animate: false,
      draggableRange: true,
      ticksArray: agesToUse.map( age=> age.ageFrom ).sort( (a,b) => a - b ), // a tick for every age from position
      translate: ( value: number, label: LabelType ): string => {
        return "";
        // switch (label) {
        //   case (LabelType.Floor): return this.getAgeLabel(true);
        //   case (LabelType.Ceil): return this.getAgeLabel(false);
        //   case (LabelType.Low): return "From " + this.getAge(value, label);
        //   case (LabelType.High): return "To " + this.getAge(value, label);
        // }
      }
    }

    // correct the current range in case of swap of age ranges
    this.sliderMinValue = Math.max(this.sliderMinValue, this.sliderOptions.floor);
    this.sliderMaxValue = Math.min(this.sliderMaxValue, this.sliderOptions.ceil -1);

    // a short delay before forcing the slider to refresh (slider UI bug)
    of([]).pipe( delay( 100 ) ).subscribe( data => {
      this.sliderRefresh.emit();
    });

  }

  getAgeLabel(lowest: boolean): string {
    let save: DemographicAgeSexNode;
    this.ageSexOnlyData.forEach( a => {
      if (!save) save = a;

      if (lowest && save.ageFrom > a.ageFrom) save = a;
      if (!lowest && save.ageTo < a.ageTo) save = a;
    }); 
    return "Age " + save.ageFrom;
  }

  getAge(value: number, label: LabelType): number {

    const ages = (this.selectedSocioGroup == "None") ? this.ageSexOnlyData : this.ageSexSocioData

    let age = ages.filter( g=> value >= g.ageFrom && value <= g.ageTo);
    if (age.length) {
       if (label == LabelType.Low) return age.map( t=> t.ageFrom )[0];
       if (label == LabelType.High) return age.map( t=> t.ageTo )[0];
    }
    return value;
  }

  getTargetName( coding: boolean ) {
    const ageFrom = this.getAge(this.sliderMinValue, LabelType.Low);
    const ageTo = this.getAge(this.sliderMaxValue, LabelType.High);
    let socio = "";

    if (this.unSavedTarget.socio && this.unSavedTarget.socio.children.length) {
      socio = "," + this.unSavedTarget.socio.children.map( c=> c.name).join(",");
    }
    return coding ? `${this.selectedGender}${ageFrom}-${ageTo}${socio}` : `${this.selectedGender} ${ageFrom}-${ageTo}${socio}`;
  }

  getAgeRange(): string {
    const ageFrom = this.getAge(this.sliderMinValue, LabelType.Low);
    const ageTo = this.getAge(this.sliderMaxValue, LabelType.High);
    return `${ageFrom}-${ageTo}`;
  }

  // current market desc
  getCurrentMarket(): string {
    return this.mkt.market.marketName
  }

  // left and right arrows used next to the market desc
  onSelectCurrentMarket(direction: number) {

    const x = this.currentMarket + direction;
    this.currentMarket = (x < 0) ? this.data.marketFilenames.length-1 : (x > this.data.marketFilenames.length-1) ? 0 : x;
    this.mkt = this.marketsService.getMarket(this.data.marketFilenames[this.currentMarket]);
    this.updateTarget();
  }

  // slider moved.
  onUserChange(changeContext: ChangeContext): void {
    this.updateTarget();
  }

  onGenderChange() {
    this.updateTarget();
  }

  onSocioGroupChange(e: MatRadioChange) {

    this.socioItems = [];
    
    if (e.value !=="None") {
      let group = this.mkt.demographicTree.find( g=> g.name == e.value);

      group.children.forEach( item => {
        this.socioItems.push({ id: item.id, code: item.code, name: item.name, checked: false });
      })
    }

    this.configureSlider( e.value == "None" ? this.ageSexOnlyData : this.ageSexSocioData );
    this.updateTarget();
  }

  onSocioItemChange(){
    this.updateTarget();
  }

  // update unSavedTarget with new ids then call getTargetUniverse
  updateTarget() {
    
    this.unSavedTarget.socio = null;
    let ages = this.ageSexOnlyData;
    if (this.selectedSocioGroup !== "None") {

      // check for and build the socio part
      let group = this.mkt.demographicTree.find( soc => soc.name == this.selectedSocioGroup); // find selected group by name
      let children: DemographicSocioNode[] = this.socioItems.filter( s=> s.checked).map( s=> {return { code: s.code, name: s.name, children:null } });

      if (group && children.length) { // if group found and some children were selected
         this.unSavedTarget.socio = { id: group.id, code: group.code, name: group.name, children: null }
         this.unSavedTarget.socio.children = children;

         ages = this.ageSexSocioData; // ues socio data ages
      }
    }

    this.unSavedTarget.name = this.getTargetName( false );
    this.unSavedTarget.coding = this.getTargetName( true );
    this.unSavedTarget.age = this.getAgeCodes(ages, this.sliderMinValue, this.sliderMaxValue, this.selectedGender ); // changeContext.value, changeContext.highValue

    const res = this.marketsService.getTargetUniverse(this.mkt.market.marketFilename, this.unSavedTarget);
    this.unSavedTarget.universe = res.universe;
    this.unSavedTarget.sample = res.sample;
    this.unSavedTarget.demographicIds = res.demographicIds;
    this.populateCampaignSummary();
  }

  getAgeCodes(ages: DemographicAgeSexNode[], minValue: number, maxValue: number, gender: string): DemographicAgeSexNode[] {

    let codes: DemographicAgeSexNode[] = []
    const sex = this.genders.indexOf(gender) - 1;  // All=-1 Male=0 Female=1

    ages.forEach( age => {
      if ((minValue <= age.ageTo) && (maxValue >= age.ageFrom) && (sex == -1 || sex == age.sex)) codes.push(age);
    });

    this.selectedAges = codes;
    return codes;
  }

  // cancel button
  onCancel() {
    this.dialogRef.close(); // pass a null back
  }

  //submit button
  onSubmit() {

    let result: TargetCreationDialogModel = {
      marketFilenames: this.data.marketFilenames,
      target: this.unSavedTarget
    }

    this.dialogRef.close( result );  // pass back new target info
  }

}
