import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ClientInfoDialogModel {

  constructor (
    public title: string,
    public client: string,
    public author: string,
    public brand: string,
    public projectNumber: string,
    public version: string,
    public notes: string
  ) {}

}

@Component({
  selector: 'client-info-dialog',
  templateUrl: './client-info-dialog.component.html',
  styleUrls: ['./client-info-dialog.component.scss']
})
export class ClientInfoDialogComponent implements OnInit {

  clientInfoGroup: FormGroup;
  title: string = "Client Info";

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef< ClientInfoDialogComponent >,
              @Inject(MAT_DIALOG_DATA) public data: ClientInfoDialogModel) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {

    this.clientInfoGroup = this.formBuilder.group({
      'title': [this.data.title],
      'client': [this.data.client],
      'author': [this.data.author],
      'brand' : [this.data.brand],
      'projectNumber': [this.data.projectNumber],
      'version': [this.data.version],
      'notes': [this.data.notes],
    });
  }  

  onSubmit() {
   
    const info = new ClientInfoDialogModel(
      this.clientInfoGroup.value.title, this.clientInfoGroup.value.client, this.clientInfoGroup.value.author, this.clientInfoGroup.value.brand,
      this.clientInfoGroup.value.projectNumber, this.clientInfoGroup.value.version, this.clientInfoGroup.value.notes );

    this.dialogRef.close(info); 
  }

  onCancel() {
    this.dialogRef.close();
  }

}
