import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel, QuestionDialogModelButtonOptions } from '../components/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserMessageService {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  message( message: string | string[], title: string, options?: any ): MatDialogRef<ConfirmDialogComponent, QuestionDialogModelButtonOptions> {

    options = options || {};

    if (!options.buttons) { 
      options.buttons = [ {caption: "Ok", data: "OK"} ]
    }

    const messageArray = (typeof message === "string") ? [message] : message;
    const dialogData = new ConfirmDialogModel(title, messageArray, options);

    options = Object.assign( options, {
      data: dialogData
    });

    return this.dialog.open(ConfirmDialogComponent, options);
  }

}
