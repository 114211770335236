<div id="login-outer-wrapper">
    <div id="login-inner-wrapper">
        <div id="login-progress">
            <mat-progress-bar *ngIf="tupAuth.busy" mode="indeterminate"></mat-progress-bar>
        </div>
        <div id="login-content">
            <div id="login-app-logo">
                <img src="assets/images/telmar logo-blue.svg">
                <h2 class="mat-display-1">Audio</h2>
            </div>
            <tup-auth #tupAuth [showProgressBar]="false"></tup-auth>
        </div>
    </div>
</div>
