import { Injectable } from '@angular/core';
import { AppPackage } from 'src/app/app.package';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  // keep the memory references, just copy the values from -> to
  shallowCopy(fromObject: any, toObject: any) {

    const keys = Object.keys(fromObject);

    keys.forEach( key=> {
      toObject[key] = fromObject[key];
    })
  }

  removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  getAppVersion(): string {
    return AppPackage.version;
  }

  // return number as correctly formatted string
  formatFloat(value: number, decimals: number = 0): string {

    const options = {
      maximumFractionDigits: (decimals == -1) ? 2 : decimals,
      minimumFractionDigits: (decimals == -1) ? 2 : 0
    }

    return value.toLocaleString(undefined, options ) // "1,234.57"
  }

  constructor() { }
}
