import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

export class SelectionDialogModel {
  
  multiSelect: boolean = false;

  constructor (
    public title: string,
    public message: string,
    public list: string[],
    public selection: string[],
  ) {}

}

export interface ListColumn {
  text: string;
}

@Component({
  selector: 'app-selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./selection-dialog.component.scss']
})
export class SelectionDialogComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  columns: any[] = [
    { columnDef: 'select', type: 'select', header: '', cell: (row: any) => '' },
    { columnDef: 'text', type: 'string', header: 'Selection', cell: (row: any) => `${row.text}` },
  ];

  displayedColumns: string[] = this.columns.map( c=> c.columnDef);

  dataSource: MatTableDataSource<any>;
  selection: SelectionModel<ListColumn>;
  filterText: string = "";

  title: string;
  message: string;
  list: ListColumn[];
  cancelButtonText: string = "Cancel";
  confirmButtonText: string = "Ok";

  constructor(
    public dialogRef: MatDialogRef<SelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectionDialogModel) {

      this.title = data.title;
      this.message = data.message;
      this.list = data.list.map (s=> {return { text: s} });
      
      this.dataSource = new MatTableDataSource<any>(this.list);
      this.dataSource.sort = this.sort;
      this.selection = new SelectionModel<ListColumn>(data.multiSelect, this.list.filter( s => data.selection.includes(s.text) ));
   }

  ngOnInit() {
  }


  onDismiss(){
    this.dialogRef.close(); 
  }

  onConfirm(){

    let result = new SelectionDialogModel(this.title, 
          this.message, 
          this.list.map (s=> s.text),
          this.selection.selected.map(s=> s.text))

      this.dialogRef.close(result); 
  }

  onReset() {
    this.filterText = "";
    this.dataSource.filter = this.filterText;
  }

  onApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }  

  // station checkbox on/off
  toggle(row: any){
    this.selection.toggle(row);
  }

}
