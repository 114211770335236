import FileSaver, { saveAs, FileSaverOptions } from 'file-saver';

export class CSVExporter {

    lines: string[] = []
    separator: string;

    constructor(separator: string =',') {
        this.separator = separator;
    }

    add(line: string | string[]): void {
        if (typeof(line) === "string") {
            this.lines.push(line) 
        }
        else {
            if (line.length) line.forEach( l=> this.lines.push(l) )
        }    
    }

    addLine(line: string | string[] ): void {
       
        if (typeof(line) === "string") {
            this.lines.push(`"${line}"\n`) 
        }
        else {
            let text = '';
            line.forEach( l=> {
                if ( typeof(l) === 'undefined' ) {text += `""${this.separator}` } 
                else { text += `"${l}"${this.separator}` }
            }) 
            text += '\n';
            this.lines.push(text);
        }
    }

    addLines(lines: string[] | string[][]): void {
        lines.forEach( line => this.addLine(line) )
    }

    addBlankLine(count: number=1): void {

        for (let i=0; i < count; i++) {
            this.addLine('');
        }
    }

    addTable(columns: any[], data: any[], options: any = null): void {
        options = options || {}
        let line :string[] = []
        let lines: any[] = []
        let header: string[] = []
        let doHeader = true;

        data.forEach( dataLine => {

            line = []
            columns.forEach( column => {

                let field = column.columnDef || column.field;
                if ( typeof(dataLine[field]) !== 'undefined' ) {

                    if (doHeader) header.push(column.header);

                    let s = dataLine[field];
                    if (column.format) s = this.formatFloat( parseFloat(dataLine[field]), column.format );
                    line.push( s );
                }
            })
            lines.push(line);
            doHeader = false;
        })

        this.addLine(header);
        this.addLines(lines);

        // export any stations
        data.forEach( dataLine => {

            if (dataLine.stations) {
                this.addBlankLine();
                this.addLine(dataLine.longTitle);  // Horrible and hardcoded
                this.addTable(columns, dataLine.stations, options);
            }

        });
    }

    saveAs( filename: string ): void {

        const blob = new Blob( this.lines );
        FileSaver.saveAs(blob, filename); // { type: "text/plain;charset=utf-8" }
    }

    private formatFloat(value: number, format: string): string {

        let part = format.split('.').pop(); // get last part of '1.2-2'
        let decimals = part.split('-');

        const options = {
          maximumFractionDigits: (decimals.length) ? parseInt( decimals[0] || '0' ) : 0,
          minimumFractionDigits: (decimals.length > 1) ? parseInt( decimals[1] || '0' ) : 0,
        }

        return value.toLocaleString(undefined, options ) // "1,234.57"
      }
    
}