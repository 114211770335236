<mat-tab-group #tabGroup class="tab-navigation" [selectedIndex]="selected.value"
    (selectedIndexChange)="onTabChanged($event)"
    color="accent"
    backgroundColor="primary"
    mat-align-tabs="left">

    <mat-tab *ngFor="let tab of tabData"> 
        <ng-template mat-tab-label>
        <mat-icon>{{tab.icon}}</mat-icon>
        &nbsp;{{tab.title}}        
        </ng-template>
    </mat-tab>
    <mat-tab></mat-tab>
</mat-tab-group>

<router-outlet></router-outlet>
