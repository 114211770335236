import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Target } from 'src/app/shared/models/campaign.model';
import { DOCUMENT } from '@angular/common'; 
import { SelectionModel } from '@angular/cdk/collections';
import { CampaignService } from 'src/app/shared/services/campaign.service';
import { MarketsService } from 'src/app/shared/services/markets.service';
import { TargetRow, PlanningColumn } from './target-grid.models';

@Component({
  selector: 'target-grid',
  templateUrl: './target-grid.component.html',
  styleUrls: ['./target-grid.component.scss']
})
export class TargetGridComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Output() delete: EventEmitter<Target> = new EventEmitter<Target>();
  @Output() rename: EventEmitter<Target> = new EventEmitter<Target>();
  @Output() selected: EventEmitter<Target[]> = new EventEmitter<Target[]>();

  columns: PlanningColumn[] = [
    { columnDef: 'select', columnType: 'select', header: '', css: 'column-action', cell: (row: any) => '' },
    { columnDef: 'actions', columnType: 'actions', header: '', css: 'column-action', cell: (row: any) => '' },
    { columnDef: 'title', columnType: 'editable', header: 'Title', css: '', cell: (row: any) => `${row.title}` },
  ]
  
  displayedColumns = this.columns.map(c => c.columnDef);
  initialSortColumn: string = 'title';

  dataSource: MatTableDataSource<any>;
  targetRows:TargetRow[]; // used by grid
  targets: Target[];  // actual targets array
  document: Document;

  selection: SelectionModel<TargetRow>;

  constructor ( @Inject(DOCUMENT) document, 
                private campaignService: CampaignService,
                private marketsService: MarketsService ) {

    this.document = document;
   }

  ngOnInit() {
  }

  loadData( targets: Target[], selected: Target[] ) {

    this.targets = targets;
    this.targetRows = this.createTargetRows(targets);

    this.columns = this.createMarketColumns();
    this.displayedColumns = this.columns.map(c => c.columnDef);

    this.dataSource = new MatTableDataSource< TargetRow >(this.targetRows); 
    this.dataSource.sort = this.sort;

    // select if already selected using campaign service list
    this.selection = new SelectionModel< TargetRow >(true, 
      this.targetRows.filter( row => selected.find( target => target.coding == row.coding) )  
    )
  }

  // create target rows array with just whats needed for the table
  createTargetRows(targets: Target[]): TargetRow[] {

    let rows: TargetRow[] = [];
    let i=0;

    targets.forEach( tgt => {

      let row: TargetRow = {
        documentId: tgt.id || '',
        id: i++,
        title: tgt.name,
        coding: tgt.coding,
        market0Universe: 0,
        market0Sample: 0
      }

      this.campaignService.run.markets.forEach( (mkt, index) => {

        let res = this.marketsService.getTargetUniverse(mkt.marketFilename, tgt);
        row[`market${index}Universe`] = res.universe;
        row[`market${index}Sample`] = res.sample;

      })
      rows.push(row);

    })
    return rows;
  }

  createMarketColumns(): PlanningColumn[] {

    // filter out current market columns
    let columns = this.columns.filter( cols=> !cols.columnDef.startsWith('market') );
    this.campaignService.run.markets.forEach( (market, index) => {

      // universe column
      columns.push({
        columnDef: `market${index}Universe`,
        columnType: 'string',
        header: `${market.marketName} - Population`, 
        css: 'column-value',
        cell: (row: any) => row[`market${index}Universe`],
      })

      // Sample column
      columns.push({
        columnDef: `market${index}Sample`,
        columnType: 'string',
        header: `${market.marketName} - Sample`, 
        css: 'column-value',
        cell: (row: any) => row[`market${index}Sample`],
      })

    })

    return columns;
  }

  // start a title edit
  onEditTitle(index: number) {
    let x = (document.getElementById('edit_' + index) as HTMLInputElement);
    x.select();
    x.focus();
  }

  // title edit confirmed and saved
  onSaveTitle(column: string, value: string, row: TargetRow) {

    this.targetRows.forEach( tgt => {
      if (tgt.id == row.id) tgt.title = value;
    });

    this.targets[row.id].name = value; // name change on actual targets array

    this.dataSource.data = this.targetRows;
    this.rename.emit(this.targets[row.id]);  // report to parent
  }

  // remove target
  onDelete(row: TargetRow){

    this.delete.emit( this.targets[row.id] ); // report to parent
  }

  toggle( row: TargetRow ){
    
    this.selection.toggle(row);

    const targets: Target[] = this.targets.filter( target => this.selection.selected.find( row => row.coding == target.coding) );
    this.selected.emit( targets );
  }


}
