import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DocumentService } from 'src/app/shared/services/document.service';
import { CampaignService } from 'src/app/shared/services/campaign.service';
import { CampaignDocument } from 'src/app/shared/models/campaign-document.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


// column definitions 
export interface PlanningColumn {
  columnType: string;
  columnDef: string;
  header: string;
  css: string;
  cell(x: any): any
}

// table rows
export interface DocumentRow {
  id: string;
  title: string,
  targets: string,
  market: string,
  modified: Date,
  author: string,
}

@Component({
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent  implements OnInit  {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  columns: PlanningColumn[] = [
    { columnDef: 'actions', columnType: 'actions', header: 'Action', css: 'column-action', cell: (row: any) => '' },
    { columnDef: 'title', columnType: 'link', header: 'Title', css: '', cell: (row: any) => `${row.title}` },
    { columnDef: 'market', columnType: 'string', header: 'Market', css: '', cell: (row: any) => `${row.market}` },
    { columnDef: 'targets', columnType: 'string', header: 'Targets', css: '', cell: (row: any) => `${row.targets}` },
    { columnDef: 'modified', columnType: 'datetime', header: 'Modified', css: 'column-value', cell: (row: any) => `${row.modified}` },
    { columnDef: 'author', columnType: 'string', header: 'Author', css: 'column-value', cell: (row: any) => `${row.author}` },
  ]
  
  displayedColumns = this.columns.map(c => c.columnDef);
  dataSource: MatTableDataSource<any>;
  documentData: DocumentRow[] = []
  documents: CampaignDocument[];
  filterText: string = "";
  processing: boolean = false; // refreshing documents table
  processingLoadDoc: boolean = false; // loading a document
    
  constructor(
        private router: Router,
        private documentService: DocumentService,
        private campaignService: CampaignService,
        private dialogService: DialogService ) {}


  ngOnInit() {
    this.loadData();
  }

  loadData() {

    this.processing = true;
    this.documentService.searchDocuments().subscribe( data => {

      this.processing = false;
      this.documents = [];
      this.documentData = [];

      data.documents.forEach( doc => {
        this.documents.push(doc);

        this.documentData.push( {
          id: doc.id,
          modified: doc.header? doc.header.modified : new Date(),
          author: doc.header ? doc.header.username : "",
          title: doc.title,
          market: doc.marketName,
          targets: doc.targets ? doc.targets.map( tgt=> tgt.name).join(" | ") : "",
        })

      });

      this.dataSource = new MatTableDataSource< DocumentRow >(this.documentData); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  onRefresh() {
    this.loadData();
  }
  
  onResetFilter() {
    this.filterText = "";
    this.dataSource.filter = this.filterText;
  }

  onApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDocumentInfo(doc: DocumentRow) {

    const document: CampaignDocument = this.documents.find( d=> d.id == doc.id );

  }

  // Loading a new document.  Check for existing unsaved first
  onOpenDocument(doc: DocumentRow) {

    this.campaignService.checkDocumentDirty().subscribe( ans => {
      if (ans) {

        const document: CampaignDocument = this.documents.find( d=> d.id == doc.id);
        this.processingLoadDoc = true;

        this.campaignService.configureDocumentLoad(document).subscribe( res => {

          this.processingLoadDoc = false;
          if (res) this.router.navigate(['planning']);
        });

      }
    });

  }

  //on delete
  onDeleteDocument(doc: DocumentRow) {

    this.dialogService.question(`Do you really want to delete the document '${doc.title}'`, "Document").afterClosed().subscribe( ans => {

      if ( ans.data == "OK" ) this.documentService.deleteDocument(doc.id, doc.title).subscribe( result => {

        // remove it from current table
        const idx = this.documentData.findIndex( d => d.id == doc.id);
        if (idx != -1 ) {
          this.documentData.splice(idx, 1);
          this.dataSource.data = this.documentData;
        }
      });

    });

  }

  // new document.  First check if there's an existing one loaded
  onNewDocument(){
    this.campaignService.checkDocumentDirty().subscribe( ans => {
      if (ans) {
        this.campaignService.clearAll();  // clear campaign and marketsService
        this.campaignService.run.startNewDocument();
        this.router.navigate(['markets']);
      }
    });

  }

  documentStarted(): boolean {
    return this.campaignService.run.documentStarted;
  }


}
