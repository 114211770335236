
<client-area areatitle="Markets" subtitle="Select or change your primary market" icon="assignment_turned_in">

  <tab-navigation header current="markets" [tabChange]="onTab"></tab-navigation>  

  <summary-breadcrumbs breadcrumbs [values]="campaignSummary"></summary-breadcrumbs>

  <div menu>
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item><mat-icon>help_outline</mat-icon>Help</button>
    </mat-menu>
  </div>

  <!-- filter controls -->
  <div class="filter-container">

    <!-- Short Report Type -->
    <mat-form-field class="small-spacing">
        <mat-label>Short Report Type</mat-label>
        <mat-select (selectionChange)="onReportTypeShortChange($event)" [(value)]="reportTypeShortSelected">
          <mat-option *ngFor="let report of reportTypesShort" value="{{report}}">{{report}}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Long Report Type -->
    <mat-form-field class="small-spacing">
      <mat-label>Long Report Type</mat-label>
      <mat-select (selectionChange)="onReportTypeLongChange($event)" [(value)]="reportTypeLongSelected">
        <mat-option *ngFor="let report of reportTypesLong" value="{{report}}">{{report}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Survey names dropdown-->
    <mat-form-field class="small-spacing">
      <mat-label>Survey</mat-label>
      <mat-select (selectionChange)="onSurveySourceChange($event)" [(value)]="surveySourceSelected">
        <mat-option *ngFor="let survey of surveySources" value="{{survey.id}}">{{survey.periodNameLong}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- free type filtering-->
    <mat-form-field>
      <mat-label>Market Name</mat-label>
      <input matInput (keyup)="onMarketNameChange($event)" placeholder="">
    </mat-form-field>    

  </div>   

  <!-- chip selection -->
  <div class="chip-container">
    <mat-form-field style="width: 100%">
      <mat-label >Selected Markets</mat-label>
      <mat-chip-list #chipList aria-label="Market selection">
        <mat-chip
          *ngFor="let mkt of marketsSelection"
          selectable="true"
          removable="true"
          (removed)="removeChip(mkt)">
          {{mkt.marketName}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>
  </div>


  <h4>{{ dataSource?.data?.length }} Market{{ dataSource?.data?.length !==1 ? 's' : ''}} found</h4>
  <!-- <p *ngIf="!!startMarket">Current selection: {{ getCurrentMarket() }}</p> -->

  <div class="table-container">

    <mat-spinner class="centered" *ngIf="processing" ></mat-spinner>

    <mat-table [dataSource]="dataSource" matSortActive="{{initialSortColumn}}" matSortDirection="asc" matSortDisableClear matSort dense>

      <!-- Generic column definition -->
      <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.columnDef">
        <mat-header-cell [ngClass]="column.css" mat-sort-header disableClear *cdkHeaderCellDef>{{ column.header }} </mat-header-cell>
    
        <!-- Checkbox Column -->
        <div *ngIf="column.type=='select'">
          <mat-cell [ngClass]="column.css" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? toggle(row) : null"
                          [checked]="isSelected(row)"
                          aria-label="select / deselect">
            </mat-checkbox>

          </mat-cell>
        </div>
    
        <!-- station Column -->
        <div *ngIf="column.type=='string'">
          <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
        </div>

        <!-- Value Column -->
        <div *ngIf="column.type=='value'">
          <mat-cell [ngClass]="column.css" *cdkCellDef="let row">{{ column.cell(row) | number: column.format }}</mat-cell>
        </div>
        
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggle(row)"></mat-row>
    
      </mat-table>
  </div>
</client-area>

  