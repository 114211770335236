<table mat-table  
       matSort
       [dataSource]="dataSource" multiTemplateDataRows 
       class="mat-elevation-z8">
  <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columns">
    <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef> {{ column.header }} </th>

    <!-- string Column -->
    <div class="column-string" *ngIf="column.type=='string'">
        <td mat-cell *cdkCellDef="let row;">
          <!-- <button *ngIf="column.field==='title'" mat-icon-button><mat-icon>keyboard_arrow_right</mat-icon></button> -->
          {{ row[column.field] }}
        </td>
    </div>
    
    <!-- value Column -->
    <div class="column-value" *ngIf="column.type=='value'">
        <td mat-cell *cdkCellDef="let row;">{{ row[column.field] | number: column.format }}</td>
    </div>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
      <div class="example-element-detail"
           [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
        <div style="width:100%">

            <!-- Expanded station results by market -->
            <h4 class="market-title">{{row.longTitle}}</h4>
            <table mat-table [dataSource]="row.stations" class="mat-elevation-z8">
              <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef> {{ column.header }} </th>
            
                <!-- string Column -->
                <div *ngIf="column.type=='string'">
                    <td mat-cell *cdkCellDef="let row;"><span>&bull; &nbsp;</span>{{ row[column.field] }}</td>
                </div>
                
                <!-- value Column -->
                <div *ngIf="column.type=='value'">
                    <td mat-cell *cdkCellDef="let row;">{{ row[column.field] | number: column.format }}</td>
                </div>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  class="example-element-row">
              </tr>
          </table>

      </div>
    </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      class="example-element-row"
      [class.example-expanded-row]="expandedRow === row"
      (click)="row.stations !=null ? expandedRow = expandedRow === row ? null : row : expandedRow">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
