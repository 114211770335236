import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'chip-selection',
  templateUrl: './chip-selection.component.html',
  styleUrls: ['./chip-selection.component.scss']
})
export class ChipSelectionComponent implements OnInit {

  formControl = new FormControl([]);
  @Input('data') data: string[];
  @Input('title') title: string;
  @Input('width') width: string;
  @Output() change: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor( private helperService: HelperService ) { }

  ngOnInit() {
  }

  onReset() { 
    this.formControl.setValue([]);
    this.change.emit([]);
  }

  onAdd(e: MatSelect ) {
    const items = this.formControl.value as string[];
    this.change.emit(items);
  }

  onRemove(item: string) {
    const items = this.formControl.value as string[];
    this.helperService.removeFirst(items, item);
    this.formControl.setValue(items);
    this.change.emit(items);
  }

}
