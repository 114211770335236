import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { AuthEvent, TupAuthService } from '@telmar-global/tup-auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Audio US';

  constructor(public authService: TupAuthService, 
              public router: Router, 
              public as: TupAnalyticsService) {
    
    as.sendPageviews(router);
    this.authService.authEvents.subscribe((e: AuthEvent) => {
      if (AuthEvent.AuthEventUserDataChanged == e) {
        if (this.authService.user) {
          as.login(this.authService.user?.uuid, this.authService.user?.customer);
        } else {
          as.logout();
        }
      }
    })
            
  }
}
