import { Injectable } from '@angular/core';
import { CampaignDirty, RadioCampaign } from '../classes/radio-campaign';
import { MarketMetrics } from '../models/market-information.model';
import { Target, Daypart, ClientInfo, Market, Survey } from '../models/campaign.model';
import { MarketsService } from './markets.service';
import { DialogService } from './dialog.service';
import { forkJoin, Observable } from 'rxjs';
import 'rxjs/add/observable/forkJoin';
import { CampaignDocument, DocumentTarget, DocumentSchedule, DocumentMarket, DocumentScheduleMarket } from '../models/campaign-document.model';
import { TupAuthService } from '@telmar-global/tup-auth';
import { environment } from 'src/environments/environment';
import { AppPackage } from "../../app.package";
import { DocumentService } from './document.service';
import { PlanningService } from './planning.service';
import { PlanningMethod, PlanningPeriod } from '../classes/campaign-schedule-plan';
import { UserTargetDocument } from '../models/usertarget-document.model';
import { UserTargetService } from './user-target.service';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';

// DOCUMENT VERSION HISTORY
// 1 = Initial release
// 2 = 30 March 2021 - Multi Market

// USER TARGET VERSION HISTORY
// 1 = Initial release
const DOCUMENT_VERSION: number = 2;  
const USERTARGET_VERSION: number = 1;

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  
  run: RadioCampaign;
 
  constructor(private marketsService: MarketsService,
              private authService: TupAuthService,
              private documentService: DocumentService,
              private planningService: PlanningService,
              private dialogService: DialogService) { 

    this.run = new RadioCampaign();
    this.run.addSchedule("", "Schedule 1");  // market with no name deleted later when real markets are added
  }

  clearAll() {
    this.marketsService.clearMarkets();
    this.run.clearAll();
  }

  configureAfterLoadMarkets(markets: MarketMetrics[], newFilenames: string[], target: Target = null) {

    this.run.configureAfterLoadMarkets(markets, newFilenames); // build planning stations and total station for the new markets

    // if there are targets already then build the universe and samples for the markets
    if (this.run.targets.length) {

      this.buildTargetUniverses();

      markets.forEach( market => {

        // refresh universe and sample of any saved targets against the new market
        // this isnt strictly accurate as every market will have it's own popn and sample for the target
        this.run.targets.forEach( target => {
          let results = this.marketsService.getTargetUniverse( market, target );
          target.universe = results.universe;
          target.sample = results.sample;
        })
      })

      // Select top n stations. Based on first target and only on new markets
      const newMarkets = markets.filter( m=> newFilenames.includes( m.market.marketFilename) );
      this.configureAfterTargetSelect( newMarkets, target || this.run.targets[0] );

      this.copySchedulesToNewMarkets(markets, newFilenames, target || this.run.targets[0]);
    }

  }

  // verify schedules are generic then copy the generic plan from an existing market to all the new markets
  copySchedulesToNewMarkets(markets: MarketMetrics[], newFilenames: string[], target: Target) {

        // copy schedules to new markets (if planAcrossMarkets==true) 
        // attempt to find a market that is not new to the campaign
        // extract the plan and sum up across all dayparts
        // reapply to each of the markets that are new to the camapaign.  This will simulate generic planning

        const newMarkets = this.run.markets.filter( m=> newFilenames.includes(m.marketFilename) ); // get new markets
        const fromMarket = this.run.markets.find( m=> ! newFilenames.includes(m.marketFilename) ); // an existing market to copy from
  
        if (newMarkets.length) {

          // for each schedule
          this.run.schedules.forEach( schedule => {

            // if we are to duplcate the plan to this schedule
            if ( schedule.planAcrossMarkets ) {

                // if there is a valid fromMarket containing results, loop through each of the new markets and attempt to copy the plan
                if ( fromMarket && schedule.market(fromMarket).hasResults() ) {

                  this.run.addDirty(CampaignDirty.markets);
                  const fromSchedule = schedule.market(fromMarket);

                    // all the new markets
                    newMarkets.forEach( newMarket => {

                      const toScheduleMarket = schedule.market(newMarket);
                      const newMkt = this.marketsService.get(newMarket.marketFilename);

                      // Ratings used for GRP conversions
                      const stationDaytpartRatings = this.marketsService.getDaypartAvgQtrHourRatingsList( newMkt, target, this.run.dayparts, toScheduleMarket.stations);
                      toScheduleMarket.numWeeks = fromSchedule.numWeeks;

                      toScheduleMarket.buildPlanFrom(fromSchedule.plan.getPlanByDayparts(), toScheduleMarket.stations, stationDaytpartRatings);
                    })
                }

            }
        })
      }
    }

  // builds the marketData array for each target for every market, universe and sample
  buildTargetUniverses() {

      this.run.targets.forEach( target => {

        target.marketData = [];
        this.marketsService.markets.forEach( market => {

          const res = this.marketsService.getTargetUniverse(market.market.marketFilename, target);
          target.marketData.push( { marketFilename: market.market.marketFilename, universe: res.universe, sample: res.sample } )
      })
    })

  }

  // after target selction configure top N Stations
  configureAfterTargetSelect(markets: MarketMetrics[], target: Target, daypart?: Daypart) {

    markets.forEach( mkt => {
      this.selectTopNStations(this.run.prefs.user.selectedNStations, mkt, target, daypart);
      this.run.configureAfterTargetSelect(this.run.market(mkt.market)); // get the campaign copy of the market
    })
  }

  // main document load. Receive CampaignDocument then load relevant market and info and configure schedule(s)
  configureDocumentLoad( doc: CampaignDocument ): Observable<boolean> {

    return new Observable( observable => {

      this.run.startNewDocument();
      this.marketsService.clearMarkets();

      if ( doc.markets.length ) {

        // This will be undefined if surveyId was not saved
        //const survey: Survey = this.marketsService.allSurveys.find( survey => survey.id === doc.markets[0].surveyId );

        // load all markets full info
        this.marketsService.clearMarkets();
        this.marketsService.getMarketsInfo( doc.markets.map( m=> m.filename) ).subscribe( added => {

          // set up client info
          this.run.clientInfo = {
            title: doc.clientInfo.title,
            author: doc.clientInfo.author,
            brand: doc.clientInfo.brand,
            client: doc.clientInfo.client,
            notes: doc.clientInfo.notes,
            projectNumber: doc.clientInfo.projectNumber,
            version: doc.clientInfo.version
          }

          this.run.created = new Date(doc.header.created);
          this.run.documentId = doc.id;

          // if planning dayparts were specified then take the list from the first market (should all be he same)
          if (doc.planningDayparts && doc.planningDayparts.length) {
            this.run.dayparts = this.marketsService.markets[0].allPlanningDayparts.filter( dp=> doc.planningDayparts.includes(dp.id));
          } 
          // else {

          //   // else build from the userprefs (if available)
          //   if (this.run.prefs.user.preferredDayparts && this.run.prefs.user.preferredDayparts.length)
          //     this.run.dayparts = this.marketsService.markets[0].allPlanningDayparts.filter( dp=> this.run.prefs.user.preferredDayparts.includes(dp.id));
          // }

          // campaign market and marketsService market assignments
          this.run.markets = this.marketsService.markets.map( mkt => mkt.market);
          this.configureAfterLoadMarkets(this.marketsService.markets, added.added);

          // get campaign level stations
          // This is now done for each schedule later on

          // set up targets
          this.run.targets = []
          doc.targets.forEach( docTgt => {
              this.run.targets.push( this.toTarget(docTgt, this.marketsService.getFirst()) );  // hardcoded market so universe and sample are from markets[0]
          })

          // markets and targets now loaded, build all the universes
          this.buildTargetUniverses();

          let requests = [] // collection of R&F calls

          // set up schedule
          this.run.schedules = []
          doc.schedules.forEach( docSch => {

            // create the schedule
            const schedule = this.run.addSchedule("", docSch.name);
            schedule.numWeeks = docSch.numWeeks;
            schedule.effectiveReachLevel = docSch.effectiveReachLevel || 3;
            schedule.planningPeriod = (docSch.PlanningWeekPeriod == "allweeks") ? PlanningPeriod.TotalWeeks : PlanningPeriod.SingleWeek;
            schedule.planningMethod = (docSch.planningMethod ==='bystation') ? PlanningMethod.ByStation : PlanningMethod.Generic;
            schedule.planAcrossMarkets = docSch.planAcrossMarkets;

            // ***** multi market document processing *****
            if (doc.header.fileVersion > 1) {

              // for each market in the given schedule
              docSch.markets.forEach( docSchMkt => {

                const scheduleMarket = schedule.addMarket(docSchMkt.marketFilename);
                const mkt = this.marketsService.get(docSchMkt.marketFilename);

                // schedule level stuff (also stored at scheduleMarket level)
                scheduleMarket.numWeeks = docSch.numWeeks;
                scheduleMarket.effectiveReachLevel = docSch.effectiveReachLevel || 3;  // default to 3 if old doc file
                scheduleMarket.planningPeriod = (docSch.PlanningWeekPeriod == "allweeks") ? PlanningPeriod.TotalWeeks : PlanningPeriod.SingleWeek;
                scheduleMarket.planningMethod = (docSch.planningMethod ==='bystation') ? PlanningMethod.ByStation : PlanningMethod.Generic;
  
                // grab the stations
                scheduleMarket.stations = mkt.allStations.filter( stn=> docSchMkt.stations.includes(stn.id) );
                this.run.market(mkt.market).stations = mkt.allStations.filter( stn=> docSchMkt.stations.includes(stn.id) );

                // schedule has some optimisation settings to use
                scheduleMarket.optimisation = null;
                if (docSchMkt.optimisation && docSchMkt.optimisation.inUse) {
  
                  let op = docSchMkt.optimisation;
                  scheduleMarket.optimisation = {
                    rankStationBy: op.rankStationBy,
                    stationBuyingGoal: op.stationBuyingGoal,
                    stationBuyingGoalValue: op.stationBuyingGoalValue,
                    marketGoal: op.marketGoal,
                    marketGoalValue: op.marketGoalValue,
                    goalCombination: op.goalCombination,
                    marketGoal2: op.marketGoal2,
                    marketGoalValue2: op.marketGoalValue2,
                    numWeeks: op.numWeeks || 1,  // backward compatibility
                    daypartCosts: op.daypartCosts || [],
                    messages: op.messages || [],
                    warning: op.warning || "",
                    error: op.error || "",
                  }
                }
  
                docSchMkt.plan.forEach( stn => {
                  stn.dayparts.forEach( dps => {
                    scheduleMarket.plan.addSpots(stn.stnId, dps.dpId, dps.spots, dps.spotEntry);
                    scheduleMarket.plan.addCosts(stn.stnId, dps.dpId, dps.costs || 0, dps.costEntry);
                  })
                })

                // start evaluation on this scheduleMarket
                if ( scheduleMarket.plan.count )
                  requests.push( this.planningService.processAndEvaluate(mkt.market, this.run.targets, scheduleMarket ) );

              })
            }

            // ***** Single market document loader *****
            else {

              const mkt = this.marketsService.getFirst();  // expecting single market so CAN use getFirst()
              let scheduleMarket = schedule.addMarket(mkt.market.marketFilename);

              scheduleMarket.numWeeks = docSch.numWeeks;
              scheduleMarket.effectiveReachLevel = docSch.effectiveReachLevel || 3;  // default to 3 if old doc file
              scheduleMarket.planningPeriod = (docSch.PlanningWeekPeriod == "allweeks") ? PlanningPeriod.TotalWeeks : PlanningPeriod.SingleWeek;
              scheduleMarket.planningMethod = (docSch.planningMethod ==='bystation') ? PlanningMethod.ByStation : PlanningMethod.Generic;

              // grab stations
              scheduleMarket.stations = mkt.allStations.filter( stn=> docSch.stations.includes(stn.id) );
              this.run.market(mkt.market).stations = mkt.allStations.filter( stn=> docSch.stations.includes(stn.id) );
  
              // a few things stored by schedule but copied back to the campaign for now (future proofing)
              this.run.effectiveReachLevel = docSch.effectiveReachLevel;

              // schedule has some optimisation settings to use
              scheduleMarket.optimisation = null;
              if (docSch.optimisation && docSch.optimisation.inUse) {

                let op = docSch.optimisation;
                scheduleMarket.optimisation = {
                  rankStationBy: op.rankStationBy,
                  stationBuyingGoal: op.stationBuyingGoal,
                  stationBuyingGoalValue: op.stationBuyingGoalValue,
                  marketGoal: op.marketGoal,
                  marketGoalValue: op.marketGoalValue,
                  goalCombination: op.goalCombination,
                  marketGoal2: op.marketGoal2,
                  marketGoalValue2: op.marketGoalValue2,
                  numWeeks: op.numWeeks || 1,  // backward compatibility
                  daypartCosts: op.daypartCosts || [],
                  messages: op.messages || [],
                  warning: op.warning || "",
                  error: op.error || "",
                }
              }

              docSch.plan.forEach( stn => {
                stn.dayparts.forEach( dps => {
                  scheduleMarket.plan.addSpots(stn.stnId, dps.dpId, dps.spots, dps.spotEntry);
                  scheduleMarket.plan.addCosts(stn.stnId, dps.dpId, dps.costs || 0, dps.costEntry);
                })
              })

              if ( scheduleMarket.plan.count )
              requests.push( this.planningService.processAndEvaluate(mkt.market, this.run.targets, scheduleMarket ) );
            }

          }) //schedules foreach

          // wait for  requests (if any) then return true
          if (requests.length) {
              forkJoin(requests).subscribe( final => {
              observable.next(true);
              observable.complete();
            })
          }
          else {
            observable.next(true);
            observable.complete();
          }
        });
      }
    })
  }

  // campaign as JSON for writing to elasticsearch
  getCampaignDocument(): CampaignDocument {

    // initial document structure
    const cf = this.run.clientInfo;
    const firstMarket = this.run.markets[0]; // first market details written to campaign document header

    // build of JSON for returning.  specific arrays built below
    const doc: CampaignDocument = {
        id: this.run.documentId,
        title: cf.title,
        marketName: `${firstMarket.marketName} (${firstMarket.reportType}, ${firstMarket.geography}, ${firstMarket.periodNameLong})`,
        appName: environment.appName,

        header: {
            productVersion: AppPackage.version,// `${environment.version.major}.${environment.version.minor}.${environment.version.revision}`,
            fileVersion: DOCUMENT_VERSION,
            created: this.run.created,
            modified: new Date(),
            username: this.authService.user.username,
            emailAddress: this.authService.user.attributes.email || this.authService.user.customer
        },

        clientInfo: {
          author: cf.author,
          brand: cf.brand,
          client: cf.client,
          notes: cf.notes,
          projectNumber: cf.projectNumber,
          title: cf.title,
          version: cf.version,
        },

        planningDayparts: [],
        markets: [],
        targets: [],
        stations: [],
        schedules: [],
    }


    // planning dayparts
    doc.planningDayparts = this.run.dayparts.map( dp=> dp.id);

    // collect together markets
    const markets: DocumentMarket[] = []
    this.run.markets.forEach( mkt => {

      markets.push({
        filename: mkt.marketFilename,
        surveyId: mkt.surveyId,
        name: `${mkt.marketName} (${mkt.reportType}, ${mkt.geography}, ${mkt.periodNameLong})`,
        universe: mkt.universe,
        sample: mkt.sample,
      })

    })

    // build targets (base universe on first target as these are calculated later during schedule loops)
    let targets: DocumentTarget[] = [];
    this.run.targets.forEach( tgt => {

      const targetResults = this.marketsService.getTargetUniverse(firstMarket.marketFilename, tgt);

      let target: DocumentTarget = {
        id: tgt.id,
        coding: tgt.coding,
        name: tgt.name,
        ages: tgt.age.map( ages => ages.code),
        socioId: 0,
        socioChildIds: [],
        sample: targetResults.sample,
        universe: targetResults.universe,
      }

      if (tgt.socio) {
        target.socioId = tgt.socio.id;
        target.socioChildIds = tgt.socio.children.map( chd => chd.code);
      }
      targets.push(target);
    })

    // build stations (campaign level stations are no longer used since multi market)
    //const stations = this.run.market(mkt).stations.map( stn => { return { id: stn.id, name: stn.name }  });

    // build schedules.  This is multi market so has an internal markets loop for capturing market specific plans
    let schedules: DocumentSchedule[] = []
    this.run.schedules.forEach( sch=> {

      const documentSchedule: DocumentSchedule = {
        name: sch.name,
        numWeeks: sch.numWeeks,                  
        effectiveReachLevel: sch.effectiveReachLevel,
        planningMethod: sch.planningMethod, 
        PlanningWeekPeriod: this.run.planningPeriodAsString( sch.planningPeriod ), // stored by schedule
        planAcrossMarkets: sch.planAcrossMarkets,
        markets: [],
      }

      // for each market within each schedule
      this.run.markets.forEach(mkt => {

        const scheduleMarket = sch.market(mkt);

        const documentScheduleMarket: DocumentScheduleMarket = {
          marketFilename: mkt.marketFilename,
          stations: scheduleMarket.stations ? scheduleMarket.stations.map( s=> s.id ) : [],  // station numbers for this schedule station list
          optimisation: { inUse: scheduleMarket.hasOptimisation() },
          plan: []
        }

        // market schedule has optimisation criteria
        if (scheduleMarket.optimisation) {

          let op = documentScheduleMarket.optimisation;
          op.inUse = true;
          op.rankStationBy = scheduleMarket.optimisation.rankStationBy;
          op.stationBuyingGoal = scheduleMarket.optimisation.stationBuyingGoal;
          op.stationBuyingGoalValue = scheduleMarket.optimisation.stationBuyingGoalValue;
          op.marketGoal = scheduleMarket.optimisation.marketGoal;
          op.marketGoalValue = scheduleMarket.optimisation.marketGoalValue;
          op.goalCombination = scheduleMarket.optimisation.goalCombination;
          op.marketGoal2 = scheduleMarket.optimisation.marketGoal2;
          op.marketGoalValue2 = scheduleMarket.optimisation.marketGoalValue2;
          op.numWeeks = scheduleMarket.optimisation.numWeeks;
          op.daypartCosts = scheduleMarket.optimisation.daypartCosts;
          op.messages = scheduleMarket.optimisation.messages;
          op.warning = scheduleMarket.optimisation.warning;
          op.error = scheduleMarket.optimisation.error;
        }

        // loop through station numbers used in the actual plan
        let stns = Object.keys(scheduleMarket.plan.plan);
        stns.forEach( stn => {

          documentScheduleMarket.plan.push({
            stnId: parseInt(stn),
            dayparts: [],
          })

          // loop through dayparts within each station and write spots
          let plan = documentScheduleMarket.plan[documentScheduleMarket.plan.length-1];
          let dps = Object.keys(scheduleMarket.plan.plan[stn]);
          dps.forEach( dp => {
            plan.dayparts.push({
              dpId: parseInt(dp),
              spots: scheduleMarket.plan.plan[stn][dp].spots,
              costs: scheduleMarket.plan.plan[stn][dp].costs,
              costEntry: scheduleMarket.plan.plan[stn][dp].costEntry,
              spotEntry: scheduleMarket.plan.plan[stn][dp].spotEntry,
            })
          })
        })

        documentSchedule.markets.push(documentScheduleMarket);
        
      })
      schedules.push(documentSchedule);

    }) // schedule foreach

    doc.markets = markets;
    doc.targets = targets;
    doc.stations = [];   //depreciated 
    doc.schedules = schedules;

    return doc;
  }


  // Create a UserTarget json document for saving into an Elastic Search
  toUserTargetDocument(target: Target): UserTargetDocument {

    let userTarget: UserTargetDocument = {
      id: target.id || "",
      appName: environment.appName,
      marketFilename: this.run.markets[0].marketFilename,
      header: {
        productVersion: AppPackage.version, // `${environment.version.major}.${environment.version.minor}.${environment.version.revision}`,
        fileVersion: USERTARGET_VERSION,
        created: new Date(),
        modified: new Date(),
        username: this.authService.user.username,
        emailAddress: this.authService.user.customer,
      },
      target: {
        id: target.id || "",
        name: target.name,
        coding: target.coding,
        universe: target.universe,
        sample: target.sample,
        ages: target.age.map( ages => ages.code),
        socioId: 0,
        socioChildIds: [],
      },
    }

    if (target.socio) {
      userTarget.target.socioId = target.socio.id;
      userTarget.target.socioChildIds = target.socio.children.map( chd => chd.code);
    }
  
    return userTarget
  }

  toTarget( docTarget: DocumentTarget, mkt: MarketMetrics, documentId: string = ''): Target {

    let resultTarget: Target = {
        id: documentId,
        name: docTarget.name,
        coding: docTarget.coding,
        age: [],
        socio: null, 
        demographicIds: [],
        sample: 0, //docTarget.sample,  // recalculate against the given mkt a few lines down
        universe: 0 //docTarget.universe
      };

      resultTarget.age = mkt.demosAgeSexGroups.filter( age => docTarget.ages.includes(age.code) );
      
      if (docTarget.socioId) {

        const tree = JSON.parse(JSON.stringify(mkt.demographicTree));
        
        let soc = tree.filter( socio => socio.id == docTarget.socioId);
        if (soc.length) {
          resultTarget.socio = soc[0];
          resultTarget.socio.children = soc[0].children.filter( chd => docTarget.socioChildIds.includes(chd.code))
        }
      }

      // and finish off with some nmbers
      let res = this.marketsService.getTargetUniverse(mkt.market.marketFilename, resultTarget);
      resultTarget.universe = res.universe;
      resultTarget.sample = res.sample;
      resultTarget.demographicIds = res.demographicIds;

      return resultTarget;
  }

  selectTopNStations(n: number, market: MarketMetrics, target: Target, daypart?: Daypart) {

      // using the demo ids from the given target, 
      // rank the stations into decending order
      // station array: this.Stations
      // write N subset to this.planningStations

      // get market
      let mkt = market || this.marketsService.getFirst();
      const campMkt = this.run.market(mkt.market);

      // get daypart
      daypart = daypart || (this.run.dayparts.length ? this.run.dayparts[0] : null );
      if (!daypart || !mkt) throw new Error('invalid daypart or market in campaignService.configureAfterTargetSelect');

      // get all the results  
      let results = this.marketsService.getStationsData(mkt, campMkt.planningStations, [1], 1, daypart, target); // array of demoIds per target, but not yet

      // extract and flatten
      let sortList = [];
      campMkt.planningStations.forEach( stn => {
        sortList.push({
          id: stn.id,
          avgRating: results[stn.id]['measure1'] });
      });

      // sort so the highest ratings are at the top ready to select the top N
      sortList.sort( (x,y) => y.avgRating - x.avgRating);

      // build this.run.stations from the top N of sortList
      campMkt.stations = [];
      for (let i=0; i < Math.min(n, sortList.length) ; i++) {

        let stn = campMkt.planningStations.find( s => s.id === sortList[i].id);
        if (stn) campMkt.stations.push(stn);
        
      }
  }

  selectPreferredDayparts(): any {

    const mkt = this.marketsService.getFirst();
    let initialList: string[] = mkt.allPlanningDayparts.map( s=> s.description );
    let selectionList: string[] = this.run.dayparts.map( s=> s.description );

    const observable = new Observable( observer => {

      const title = "Daypart selection";
      const message = "Select your dayparts.";
      this.dialogService.openSelectionDialog( title, message, initialList, selectionList, { multiSelect: true } ).afterClosed().subscribe( ans => {
  
          if (ans) {
            this.run.dayparts = mkt.allPlanningDayparts.filter( s=> ans.selection.includes (s.description));
            this.run.prefs.user.preferredDayparts = this.run.dayparts.map( dp=> { if (ans.selection.includes (dp.description)) return dp.id });
          }
          observer.next(!!ans);
          observer.complete();
      })

    })
    return observable;
  }

  selectPreferredStations(): any {

    const mkt = this.marketsService.getFirst();
    const campMkt = this.run.market(mkt.market.marketFilename);

    let initialList: string[] = mkt.allStations.filter(s=> s.id !== campMkt.totalStation.id).map( s=> `${s.callLetters} (${s.frequency} ${s.band})` ); // strip total station
    let selectionList: string[] = campMkt.planningStations.map( s=> `${s.callLetters} (${s.frequency} ${s.band})` );

    const observable = new Observable( observer => {

      const title = "Station selection";
      const message = "Select your stations.";
      this.dialogService.openSelectionDialog( title, message, initialList, selectionList, { multiSelect: true } ).afterClosed().subscribe( ans => {
  
          if (ans) {
            campMkt.planningStations = mkt.allStations.filter( s => ans.selection.includes( `${s.callLetters} (${s.frequency} ${s.band})`) );
            campMkt.stations = campMkt.stations.filter( stn => campMkt.planningStations.find( s=> s.id == stn.id) ); // check none of the stations being planned on have been removed
          }
          observer.next(!!ans);
          observer.complete();
      })

    })
    return observable;
  }

  // handles the yes/no/cancel dialog and saves the document if required.  return true if ready to continue, else false
  checkDocumentDirty(): Observable<boolean> {

    return new Observable<boolean> ( observable => {

      // if unsaved campaign
      if (this.run.isDirty()) {  

        const buttons =  [
          { caption: "Yes", data: "YES" },
          { caption: "No", data: "NO" },
          { caption: "Cancel", data: "CANCEL" }
        ]

        this.dialogService.question(`Save changes to '${this.run.clientInfo.title}' first?`, 
                                    "Save Changes", { buttons: buttons }).afterClosed().subscribe( ans=> {
      
          // save changes first
          if (ans.data == "YES") {

            const doc = this.getCampaignDocument();
            this.documentService.createOrUpdateDocument( doc ).subscribe( data => {
              this.run.documentId = data.id;

              observable.next(true);
              observable.complete();
            })
          }

          if (ans.data == "NO") {
            observable.next(true);
            observable.complete();
          }

          if (ans.data == "CANCEL") {
            observable.next(false);
            observable.complete();
          }

        });  // save changes..

      }
      else {
        // not dirty
        observable.next(true);
        observable.complete();
      }

    }) // observ
  }

  }
