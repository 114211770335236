<client-area areatitle="Bug Reporter" subtitle="Submit feedback, feature requests, and bugs" icon="bug_report">

<div fxFill fxLayout="column">

    <div class="content glossary-parent" fxFlex fxLayoutAlign="start center">
        <div class="inner-wrapper bugreport" fxLayout="column" fxFlex>
            <div class="mat-typography summary-top-section">
                <h2>Feedback</h2>
                <span fxFlex fxLayoutAlign="center">&nbsp;</span>
                <span class="feedback-blurb">Would you like to provide feedback or report a bug? Let us know!</span>
            </div>
            <div fxFlex fxLayout="column">
                <form [formGroup]="bugForm" class="bug-form" (ngSubmit)="OnSubmit()" class="bug-form" fxLayout="column">
                    <mat-form-field>
                        <input matInput placeholder="Title" formControlName="subject" maxlength="200" />
                    </mat-form-field>
                    <mat-form-field>
                        <textarea matInput placeholder="Description" formControlName="content" rows="5"
                            maxlength="2000"></textarea>
                    </mat-form-field>
                    <button type="submit" mat-raised-button color="primary">Submit</button>
                </form>
            </div>
        </div>
    </div>

</div>