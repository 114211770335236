  <mat-icon>list_alt</mat-icon>
  <div>N-Tiles</div>

  <div class="table-container" >
    <mat-table [dataSource]="dataTable" dense>

      <!-- Generic column definition -->
      <ng-container *ngFor="let column of columns; let i = nTile" [cdkColumnDef]="column.columnDef">
        <mat-header-cell *cdkHeaderCellDef>{{ column.header }} </mat-header-cell>

        <div>
          <mat-cell *cdkCellDef="let row">{{ column.cell(row) | number: column.format }}
          </mat-cell>
        </div>

      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
  </div>

